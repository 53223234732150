import React from 'react';

/*
Component's purpose is to render income information to the dom. 
Initially used for displaying income next to FED bal/ref on the tag
return left side navigation
*/

const IncomeInformation = ({ incomeAmount, incomeInfo }) => {
  let color = '#00C44F'; // Green display text by default

  // Calc server will send negatives in parans
  if (incomeAmount) {
    if (incomeAmount.length > 1 && incomeAmount[1] === '(') {
      color = '#FF304A';
    }
    if (!incomeAmount.includes('$')) {
      incomeAmount = '$' + incomeAmount;
    }
  } else {
    incomeAmount = '$0';
  }

  const incomeInfoHeaderStyle = {
    color,
    fontSize: '21px',
    fontFamily: 'Roboto',
    lineHeight: '24px',
    marginLeft: '30px',
  };

  const incomeInfoFooterStyle = {
    color: '#354052',
    fontFamily: 'Roboto',
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: '300',
    marginLeft: '30px',
  };

  return (
    <div className="incomeInformation">
      <div style={incomeInfoHeaderStyle}>
        <span>{incomeAmount}</span>
      </div>
      <div style={incomeInfoFooterStyle}>
        <span>{incomeInfo}</span>
      </div>
    </div>
  );
};

export default IncomeInformation;
