// External imports
import React, { useEffect } from 'react';
// Internal imports
import SimpleDialog from '#/Common/SimpleDialog.jsx';
// Redux imports
import { useDispatch } from 'react-redux';
import { actions as maintenanceActions } from '~/app/redux/modules/maintenance';
// Styling imports
import './css/performanceMaintenanceMsg.css';

/**
 * This maintenance routine component is schedule automatically
 * weekly to show on every Friday
 *
 * @component PerformanceMaintenanceMsg
 * @category Login page + MaintenanceMode
 */
const PerformanceMaintenanceMsg = props => {
  const dispatch = useDispatch();
  useEffect(() => {
    // The login page has its own UI, this modal is not needed
    if (location.pathname === '/') {
      dispatch(maintenanceActions.toggleMaintenanceModeMessageModal(false));
    }
  }, [location.pathname]);

  if (props.open) {
    return (
      <div className="modal-container">
        <SimpleDialog
          open={props.open}
          onConfirm={() => dispatch(maintenanceActions.toggleMaintenanceModeMessageModal(false))}
          dialogTitle={props.heading}
          contentText={props.msg}
          contentTextTwo={
            location.pathname === '/tax-return' ? (
              <b>
                We recommend saving your tax return session and exiting before maintenance begins.
              </b>
            ) : (
              ''
            )
          }
          styled={true}
        />
      </div>
    );
  } else {
    return null;
  }
};

export default PerformanceMaintenanceMsg;
