export const styles = {
  root: {
    flexGrow: 1,
  },
  qrCodeTitle: {
    color: '#1A173B',
    fontSize: '1.1rem',
    paddingTop: '2.5rem',
  },
  qrCodeBody: {
    color: '#1A173B',
    fontSize: '1.1rem',
    paddingTop: '1rem',
  },
  qrCodeModal: {
    height: '41.25rem',
    width: '41.25rem',
    padding: '0',
    position: 'relative',
    margin: 'auto',
    textAlign: 'center',
  },
  formContainer: {
    marginLeft: '3vw',
    marginRight: '3vw',
  },
  resetCodeModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    height: '18.75rem',
    width: '61.1rem',
    padding: '0',
    textAlign: 'center',
  },
  buttonContainer: {
    textAlign: 'right',
    width: '100%',
    padding: '0',
    marginLeft: '0vw',
    marginRight: '3vw',
    marginTop: '2vh',
  },
  continueButtonStyle: {
    marginLeft: '1vw',
    backgroundColor: '#0077FF',
    color: '#FFFFFF',
    textTransform: 'capitalize',
    borderRadius: '4',
  },
  paperStyleMobile: {
    width: '25rem',
    height: 'auto',
    borderRadius: '.25rem',
    marginLeft: '.12rem',
    position: 'relative',
    marginTop: '1.56rem',
    marginBottom: '1.56rem',
    paddingBottom: '2rem',
  },
  paperStyleNonMobile: {
    width: '31.25rem',
    height: 'auto',
    borderRadius: '.25rem',
    margin: 'auto',
    position: 'relative',
    marginTop: '20vh',
    paddingBottom: '2rem',
  },
  mobileMessageContainer: {
    textAlign: 'center',
    marginTop: '2vh',
  },
  linkStatusText: {
    fontFamily: 'Roboto',
    fontSize: '1.1rem',
  },
  nonMobileFont: {
    color: '#1A173B',
    fontFamily: 'Roboto',
    fontSize: '1.3rem',
    paddingTop: '4vh',
    paddingBottom: '1vh',
    textAlign: 'left',
  },
  mobileFont: {
    color: '#1A173B',
    fontFamily: 'Roboto',
    fontSize: '1rem',
    paddingTop: '4vh',
    paddingBottom: '1vh',
    textAlign: 'left',
  },
  styleNonMobileOne: {
    textAlign: 'left',
    paddingTop: '2vh',
    paddingBottom: '1vh',
  },
  styleMobileOne: {
    textAlign: 'left',
    paddingTop: '2vh',
    paddingBottom: '1vh',
    fontSize: '.81rem',
  },
  styleNonMobileTwo: {
    textAlign: 'left',
    paddingTop: '1vh',
    paddingBottom: '3vh',
  },
  styleMobileTwo: {
    textAlign: 'left',
    paddingTop: '1vh',
    paddingBottom: '3vh',
    fontSize: '.81rem',
  },
};
