// External imports
import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  DialogContent,
  FormControl,
  Grid,
  Modal,
  Paper,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
// Internal imports
import AuthAPI from '~/app/api/authAPI.js';
import ErrorHelpers from '~/app/errorHelpers.js';
import RemoteSignPad from '~/app/Components/RemoteSign/RemoteSignaturePad.jsx';
import RemoteSignPreview from '~/app/Components/RemoteSign/RemoteSignaturePreview.jsx';
import PreviewSignature from '#/Common/PreviewSignature.jsx';
import { useSetState } from '~/app/Utility/customHooks';
import { statusOK } from '~/app/webHelpers';
import {
  HIERARCHY_TYPE,
  SIGNATURE_PAD_TYPES,
  SIGNEE_CATEGORY_TYPE,
  SIGNEE_TYPE,
  REMOTE_SIGN_MESSAGES,
} from '~/app/constants.js';
// Redux imports
import { useDispatch, useSelector } from 'react-redux';
import { actions as appActions } from '~/app/redux/modules/app.js';
// Style imports
import { styles } from '~/app/Components/RemoteSign/css/remoteSign.js';
import '~/app/Components/RemoteSign/css/remoteSign.css';

/**
 * Handles verifying a preparer that has recieved a Remote Signature Request from the Settings -> Preparer Page, and capturing their signature to be accessed.
 *
 * @component PreparerRemoteSign
 * @category Settings -> Preparer Setup
 **/
const PreparerRemoteSign = props => {
  const { classes } = props;
  const dispatch = useDispatch();
  const { isMaintenanceModeTime } = useSelector(state => ({
    isMaintenanceModeTime: state.maintenance.isMaintenanceModeTime,
  }));
  const [state, setState] = useSetState({
    form: {
      name: '',
      lastFourPTIN: '',
    },
    authorizationCode: '',
    requesteeType: SIGNEE_CATEGORY_TYPE.PREPARER, // we get this from our URL, it is prefixed to the auth_code hash
    currentStep: 0,
    initialVerify: true,
    isVerifying: false,
    isSubmitDisabled: true,
    inputErrors: {},
    isLoading: false,

    // prep info
    preparerSignatureString: '',
    hasDBPrepSignature: false,
    remoteSignature: '',
    remoteSignatureString: '',
    remoteSignatureRequestID: 0,
    createdBy: 0,

    // verify res
    requestStatus: 1,
    preparerID: 0,

    // Modals/popups
    isSignatureModalOpen: false,
    isSignaturePreviewOpen: false,
    isSignatureExistsModalOpen: true,
  });
  const [debouncedState, setDebouncedState] = useState({
    form: {
      name: '',
      lastFourPTIN: '',
    },
  });

  useEffect(() => {
    const paramCode = props.match.params?.code;

    setState({
      authorizationCode: paramCode.substring(1),
      requesteeType: parseInt(paramCode.substring(0, 1)),
    });
  }, []);

  // Handles setting timeout on form change
  const debounce = useCallback(
    _.debounce(_formVal => {
      setDebouncedState(_formVal);
      // send the server request here
    }, 2000),
    [],
  );

  // When debounce state changes after user stops typing for a set period of time, then verify return.
  useEffect(() => {
    if (state.initialVerify) {
      setState({ initialVerify: false });
    } else {
      setState({
        isSubmitDisabled: formHasErrors(state.form),
        isVerifying: false,
      });
    }
  }, [debouncedState]);

  /** Handles input change */
  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;

    setState({
      form: {
        ...state.form,
        [name]: value.toUpperCase(),
      },
      isSubmitDisabled: true, // require verify if form was completed then modified.
      isVerifying: true,
    });
    debounce({
      form: {
        ...state.form,
        [name]: value.toUpperCase(),
      },
    });

    // If an input does have an error, we want to reset the error and give the user a chance to fix it
    const tempErrors = state.inputErrors;
    if (tempErrors[name]) {
      delete tempErrors[name];
      setState({
        inputErrors: tempErrors,
      });
    }
  };

  /**
   * Checks each input to see if it is empty.
   *
   * @param {Object} fields input fields from the form
   * @returns {boolean} used to determine if there is an error and should return true
   */
  const formHasErrors = fields => {
    const errors = {};
    for (const field of Object.keys(fields)) {
      const value = fields[field];

      switch (field) {
        case 'name':
          if (!value) errors[field] = 'Name Required';
          break;
        case 'lastFourPTIN':
          if (!value || (value.length > 0 && value.length < 4))
            errors[field] = 'Four Digits Required';
          break;
        default:
      }
    }

    setState({ inputErrors: errors }); // set any errors to the local state
    return Object.keys(errors).length !== 0; // if even one error exists, prevent user from submitting
  };

  /** Handles submitting preparer info to be verified */
  const handleSubmitVerify = async () => {
    try {
      const res = await AuthAPI.VerifyPreparerRemoteSignature(
        state.form.name,
        state.form.lastFourPTIN,
        state.requesteeType,
        state.authorizationCode,
      );
      const data = res.data;
      const snackbarObj = {
        msg: '',
        alert: '',
      };

      // request_status -> 0 - Deactivated, 1 - Pending, 2 - Completed
      if (statusOK(res)) {
        // the DB handles determining whether or not the email is expired and includes in the response
        if (data.isExpired) {
          snackbarObj.msg = 'This email request has expired';
          snackbarObj.alert = 'error';
          setState({ isExpired: data.isExpired, currentStep: 1 });

          // If document status is Pending or Completed
        } else if ([1, 2].includes(data.requestStatus)) {
          snackbarObj.msg = 'Verification was successful';
          snackbarObj.alert = 'success';

          const isRMSCompleted = data.requestStatus === 2;

          // Now that were verified, check to see if the prep has an existing signature to decide the modal
          const hasDBPrepSignature = await checkForPreparerSignature(data.preparerID);

          setState({
            currentStep: 1,
            requestStatus: data.requestStatus,
            preparerID: data.preparerID,
            isSignatureModalOpen: isRMSCompleted ? false : !hasDBPrepSignature,
            isSignatureExistsModalOpen: isRMSCompleted ? false : hasDBPrepSignature,
            remoteSignatureRequestID: data.remoteSignatureRequestID,
            createdBy: data.createdBy,
          });
        } else {
          snackbarObj.msg =
            'The Information provided does not match our records or the email request has been deactivated. Please try again or contact your supervisor to send you another email request.';
          snackbarObj.alert = 'error';
        }
      }
      dispatch(
        appActions.showSnackbarMessage(snackbarObj.msg, snackbarObj.alert, 4500, {
          vertical: 'top',
          horizontal: 'center',
        }),
      );
    } catch (err) {
      dispatch(
        appActions.showSnackbarMessage('Bad Request while verifying information', 'error', 4000, {
          vertical: 'top',
          horizontal: 'center',
        }),
      );
      ErrorHelpers.handleError('Bad Request while verifying information: ', err);
    }
  };

  const checkForPreparerSignature = async preparerID => {
    let hasDBPrepSignature = false;

    try {
      const res = await AuthAPI.getSignatureForPreparer(preparerID);

      if (statusOK(res, false)) {
        hasDBPrepSignature = res.data.length > 0;

        setState({
          preparerSignatureString: res.data,
          hasDBPrepSignature,
        });
      }

      return hasDBPrepSignature;
    } catch (err) {
      dispatch(
        appActions.showSnackbarMessage(
          'Failed to get the existing preparer signature',
          'error',
          3500,
          {
            vertical: 'top',
            horizontal: 'center',
          },
        ),
      );
      ErrorHelpers.handleError('Failed to get preparer signature', err);
    }
  };

  const onSubmitSignature = async () => {
    try {
      setState({ isLoading: true });
      const res = await AuthAPI.postSignature(
        state.remoteSignature,
        state.preparerID,
        HIERARCHY_TYPE.PREPARER,
        SIGNEE_CATEGORY_TYPE.PREPARER,
        SIGNATURE_PAD_TYPES.ON_SCREEN,
        state.remoteSignatureRequestID,
        state.createdBy,
      );
      if (statusOK(res, false)) {
        setState({ isSignaturePreviewOpen: false, isLoading: false });
      }
    } catch (error) {
      dispatch(
        appActions.showSnackbarMessage('Failed to submit your signature', 'error', 3500, {
          vertical: 'top',
          horizontal: 'center',
        }),
      );
      ErrorHelpers.handleError('Failed to post remote signature data', error);
      setState({ isLoading: false });
    }
  };

  /** Handles budiling the results message ui */
  const renderResultsMessage = () => {
    const { expired, alreadyCompleted, isNotCapturing, successSigned } = REMOTE_SIGN_MESSAGES;
    const msgObjToUse = state.isExpired
      ? expired
      : state.requestStatus === 2
      ? alreadyCompleted
      : state.isNotCapturing
      ? isNotCapturing
      : successSigned;

    return (
      <>
        {msgObjToUse.map((msg, i) => (
          <Grid key={i} item xs={12}>
            <Typography variant="body1" align="center">
              {msg}
            </Typography>
          </Grid>
        ))}
      </>
    );
  };

  const labels = ['Please Verify using your Preparer Information', 'All Done'];

  return (
    <div className="remoteAuthenticationPage">
      <Paper className="request-sign-container">
        <div className="remote-sign-log-in">
          {!state.isExpired ? labels[state.currentStep] : 'Expired'}
        </div>
        <Grid container spacing={32}>
          <Grid item xs={12}>
            <hr className="remote-sign-horizontal-line" />
          </Grid>
          {state.currentStep === 0 ? (
            <>
              <Grid item xs={12}>
                <FormControl fullWidth required>
                  <TextField
                    id="txtPrepName"
                    name="name"
                    value={state.form.name}
                    label="Name"
                    placeholder="John Doe..."
                    onChange={handleChange}
                    inputProps={{ maxLength: 150 }}
                    variant="outlined"
                    autoComplete="off"
                    helperText={state.inputErrors?.name}
                    error={state.inputErrors?.name !== undefined}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl required>
                  <TextField
                    id="nfPTINLastFourDigit"
                    name="lastFourPTIN"
                    value={state.form.lastFourPTIN}
                    label="Last 4 Digits of PTIN"
                    onChange={handleChange}
                    variant="outlined"
                    autoComplete="off"
                    placeholder="1111..."
                    inputProps={{ maxLength: 4 }}
                    helperText={state.inputErrors?.lastFourPTIN}
                    error={state.inputErrors?.lastFourPTIN !== undefined}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Button
                    id="btnVerifyInfo"
                    onClick={handleSubmitVerify}
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={state.isSubmitDisabled || isMaintenanceModeTime}
                  >
                    {state.isVerifying ? (
                      <>
                        <CircularProgress
                          size="1rem"
                          color="inherit"
                          className={classes.smallSpinner}
                        />
                        {'Verifying...'}
                      </>
                    ) : (
                      'Verify'
                    )}
                  </Button>
                </FormControl>
              </Grid>
            </>
          ) : state.currentStep === 1 ? (
            renderResultsMessage()
          ) : null}
        </Grid>
      </Paper>

      {/* MODALS/POPUPS */}
      <Modal
        id="mdlRemoteSignatureModal"
        aria-labelledby="simple-modal-remote-signature"
        aria-describedby="simple-modal-remote-signature-description"
        open={state.isSignatureModalOpen}
        onClose={() => setState({ isSignatureModalOpen: false })}
        disableAutoFocus
        disableBackdropClick
      >
        <DialogContent>
          <RemoteSignPad
            handlePreviewSignature={() =>
              setState({ isSignaturePreviewOpen: true, isSignatureModalOpen: false })
            }
            currentSig={SIGNEE_CATEGORY_TYPE.PREPARER}
            showBankAgreement={false}
            setRemoteSignature={remoteSignature => setState({ remoteSignature })}
            setRemoteSignatureString={remoteSignatureString => {
              setState({ remoteSignatureString });
            }}
          />
        </DialogContent>
      </Modal>
      <Modal
        id="mdlRemoteSignaturePreviewModal"
        aria-labelledby="simple-modal-remote-siganture-preview"
        aria-describedby="simple-modal-remote-signature-preview"
        open={state.isSignaturePreviewOpen}
        disableAutoFocus
        disableBackdropClick
      >
        <DialogContent>
          <RemoteSignPreview
            handleCloseSignatureModal={() => null}
            handleRecapture={() =>
              setState({
                isSignaturePreviewOpen: false,
                isSignatureModalOpen: true,
              })
            }
            postSignature={onSubmitSignature}
            remoteSignature={state.remoteSignature}
            isLoading={state.isLoading}
          />
        </DialogContent>
      </Modal>
      <Modal
        id="mdlAddSignatureMenuOnScreen"
        aria-labelledby="simple-modal-title-signature-menu-onscreen"
        aria-describedby="simple-modal-description-signature-menu-onscreen"
        open={state.isSignatureExistsModalOpen && state.hasDBPrepSignature}
        disableAutoFocus
        disableBackdropClick
      >
        <PreviewSignature
          prepSignature={state.preparerSignatureString}
          handleNewClose={() =>
            setState({
              isSignatureExistsModalOpen: false,
              isNotCapturing: true,
            })
          }
          signatureRecapture={() =>
            setState({
              isSignatureExistsModalOpen: false,
              isSignatureModalOpen: true,
              isNotCapturing: false,
            })
          }
          signeeType={SIGNEE_TYPE.PREPARER}
        />
      </Modal>
    </div>
  );
};

export default withStyles(styles)(PreparerRemoteSign);
