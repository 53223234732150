// External imports
import React, { useEffect } from 'react';
import { FormControl, Input, InputLabel, Paper, withStyles } from '@material-ui/core';
import CheckMark from '@material-ui/icons/Done';
import WarningSign from '@material-ui/icons/ReportProblemTwoTone';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
// Styling imports
import { styles } from '~/app/Components/Common/css/genericPasswordFIeldStyles.js';

const GenericPasswordField = props => {
  const [state, setState] = useSetState({
    password: '',
    eightCharacters: false,
    containsUppercase: false,
    containsLowercase: false,
    containsSpecialCharacter: false, // The special characters allowed are !@#$&*?
    passwordFieldOnFocus: false,
    haveValidPassword: false,
    passwordMatch: false,
  });

  useEffect(() => {
    if (props.firstvalue && props.retype) {
      if (state.password === props.firstvalue) {
        setState({
          passwordMatch: true,
        });
        props.handlePasswordCheck(state.password, true);
      } else {
        setState({
          passwordMatch: false,
        });
        props.handlePasswordCheck(state.password, false);
      }
    }
  }, [props.firstvalue, state.password]);

  const handleChangePassword = e => {
    const name = e.target.name;
    const value = e.target.value;

    if (props.retype) {
      setState({
        password: value,
      });

      if (value === props.firstvalue) {
        setState({
          passwordMatch: true,
        });
        props.handlePasswordCheck(value, true);
      } else {
        setState({
          passwordMatch: false,
        });
        props.handlePasswordCheck(value, false);
      }
    }

    setState({ [name]: value });
    validatePasswordField(name, value);
  };

  // Function that will be called for checking validation after onChange
  const validatePasswordField = (field, value) => {
    let eightCharactersFlag = state.eightCharacters;
    let containsUppercaseFlag = state.containsUppercase;
    let containsLowercaseFlag = state.containsLowercase;
    let containsSpecialCharacterFlag = state.containsSpecialCharacter;
    const upperCasePassword = value.toUpperCase();
    const lowerCasePassword = value.toLowerCase();
    let haveValidPasswordFlag = state.haveValidPassword;

    if (field === 'password') {
      // Check if the password contains at least eight characters
      if (value.length > 7) {
        eightCharactersFlag = true;
      } else {
        eightCharactersFlag = false;
      }
      // Check if the password contains an uppercase letter
      if (lowerCasePassword !== value) {
        containsUppercaseFlag = true;
      } else {
        containsUppercaseFlag = false;
      }
      // Check if the password contains a lowercase letter
      if (upperCasePassword !== value) {
        containsLowercaseFlag = true;
      } else {
        containsLowercaseFlag = false;
      }
      // Check if the password has a special character !@#$&*?
      if (
        // This should be refactored to us regex
        value.includes('!') ||
        value.includes('@') ||
        value.includes('#') ||
        value.includes('$') ||
        value.includes('&') ||
        value.includes('*') ||
        value.includes('?')
      ) {
        containsSpecialCharacterFlag = true;
      } else {
        containsSpecialCharacterFlag = false;
      }
      if (
        eightCharactersFlag &&
        containsUppercaseFlag &&
        containsLowercaseFlag &&
        containsSpecialCharacterFlag
      ) {
        haveValidPasswordFlag = true;
      } else {
        haveValidPasswordFlag = false;
      }

      props.handlePasswordCheck(value, haveValidPasswordFlag);
    }

    setState({
      eightCharacters: eightCharactersFlag,
      containsUppercase: containsUppercaseFlag,
      containsLowercase: containsLowercaseFlag,
      containsSpecialCharacter: containsSpecialCharacterFlag,
      haveValidPassword: haveValidPasswordFlag,
    });
  };

  // Only show password requirements when onFocus
  const passwordOnFocus = () => {
    setState({
      passwordFieldOnFocus: true,
    });
  };

  const passwordOnBlur = () => {
    setState({
      passwordFieldOnFocus: false,
    });
  };

  let stylePasswordField;
  if (state.haveValidPassword || state.passwordMatch) {
    stylePasswordField = false;
  } else {
    stylePasswordField = true;
  }
  const styleText = props.checkIfMobile ? styles.textStyleMobile : styles.textStyle;
  const styleCheckmarkImage = props.checkIfMobile
    ? styles.mobileCheckmark
    : styles.nonMobileCheckmark;
  const styleWarningSignImage = props.checkIfMobile
    ? styles.mobileWarningSign
    : styles.nonMobileWarningSign;

  const floatingWindow = reqs => {
    let xOffset = 33; // em
    let yOffset = 5; // em
    if (props.verticalOffset) yOffset += props.verticalOffset;
    if (props.horizontalOffset) xOffset += props.horizontalOffset;
    return props.checkIfMobile ? (
      <Paper
        style={{
          paddingLeft: '2px',
          marginBottom: '5px',
          position: 'relative',
          width: '10.5em',
          left: `${xOffset}em`,
          bottom: `${yOffset}em`,
        }}
      >
        {reqs}
      </Paper>
    ) : (
      <Paper
        style={{
          paddingLeft: '10px',
          marginBottom: '5px',
          position: 'relative',
          width: '21em',
          left: `${xOffset}em`,
          bottom: `${yOffset}em`,
        }}
      >
        {reqs}
      </Paper>
    );
  };

  const reTypeReqs = state.passwordFieldOnFocus && (
    <div style={styles.floatingBoxDivStyle}>
      {state.passwordMatch ? (
        <span>
          <div style={styles.styleCheckmark}>
            <CheckMark style={styles.styleCheckmarkImage} />
          </div>
          <div style={styleText}>
            <del>Matches previous input</del>
          </div>
        </span>
      ) : (
        <span>
          <div style={styles.styleCheckmark}>
            <WarningSign style={styleWarningSignImage} />
          </div>
          <div style={styleText}>Matches previous input</div>
        </span>
      )}
    </div>
  );

  const initialEntryReqs = state.passwordFieldOnFocus && (
    <div style={styles.floatingBoxDivStyle}>
      {state.eightCharacters ? (
        <span>
          <div style={styles.styleCheckmark}>
            <CheckMark style={styleCheckmarkImage} />
          </div>
          <div style={styleText}>
            <del>At least 8 characters long.</del>
          </div>
        </span>
      ) : (
        <span>
          <div style={styles.styleCheckmark}>
            <WarningSign style={styleWarningSignImage} />
          </div>
          <div style={styleText}>At least 8 characters long.</div>
        </span>
      )}
      <br />
      {state.containsUppercase ? (
        <span>
          <div style={styles.styleCheckmark}>
            <CheckMark style={styleCheckmarkImage} />
          </div>
          <div style={styleText}>
            <del>Contains uppercase letters.</del>
          </div>
        </span>
      ) : (
        <span>
          <div style={styles.styleCheckmark}>
            <WarningSign style={styleWarningSignImage} />
          </div>
          <div style={styleText}>Contains uppercase letters.</div>
        </span>
      )}
      <br />
      {state.containsLowercase ? (
        <span>
          <div style={styles.styleCheckmark}>
            <CheckMark style={styleCheckmarkImage} />
          </div>
          <div style={styleText}>
            <del>Contains lowercase letters.</del>
          </div>
        </span>
      ) : (
        <span>
          <div style={styles.styleCheckmark}>
            <WarningSign style={styleWarningSignImage} />
          </div>
          <div style={styleText}>Contains lowercase letters.</div>
        </span>
      )}
      <br />
      {state.containsSpecialCharacter ? (
        <span>
          <div style={styles.styleCheckmark}>
            <CheckMark style={styleCheckmarkImage} />
          </div>
          <div style={styleText}>
            <del>Contains special characters !@#$?&*.</del>
          </div>
        </span>
      ) : (
        <span>
          <div style={styles.styleCheckmark}>
            <WarningSign style={styleWarningSignImage} />
          </div>
          <div style={styleText}>Contains special characters !@#$?&*</div>
        </span>
      )}
    </div>
  );

  return (
    <div style={{ height: '4em' }}>
      <FormControl fullWidth>
        <InputLabel htmlFor={props.id} shrink>
          {props.label}
        </InputLabel>
        <Input
          id={props.id}
          name={props.name}
          type="password"
          onChange={handleChangePassword}
          onFocus={passwordOnFocus}
          onBlur={passwordOnBlur}
          disableUnderline
          error={stylePasswordField}
          autoComplete="new-password"
          disabled={props.disabled}
        />
      </FormControl>
      {floatingWindow(props.retype ? reTypeReqs : initialEntryReqs)}
    </div>
  );
};

export default withStyles(styles)(GenericPasswordField);
