// External imports
import React, { useEffect } from 'react';
import {
  Button,
  Grid,
  Divider,
  Modal,
  Paper,
  TextField,
  FormControl,
  MenuItem,
  withStyles,
  InputAdornment,
} from '@material-ui/core';
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons';
// Internal imports
import MultiOfficeManagement from '~/app/Pages/Setup/ManageLicencing/components/MultiOfficeManagement.jsx';
import PagedTable from '#/Common/PagedTable.jsx';
import {
  setupLicenseDataForPagedTable,
  getRemoveText,
  INDIVIDUAL,
  BUSINESS,
} from '~/app/Pages/Setup/ManageLicencing/manageLicenseHelper.js';
import { useSetState } from '~/app/Utility/customHooks';
import AddLicenseModal from './components/AddLicenseModal.jsx';
import AssignLicenseModal from './components/AssignLicenseModal.jsx';
import LicenseTable from './components/LicenseTable.jsx';
import { EARLIEST_ACTIVE_SEASON, HIERARCHY_TYPE, LATEST_SEASON } from '~/app/constants.js';
import XlinkAPI from '~/app/api/xlinkAPI';
import ErrorHelpers from '~/app/errorHelpers.js';
import WebHelpers, { uuid4, statusOK } from '~/app/webHelpers.js';
import SimpleDialog from '#/Common/SimpleDialog.jsx';
// Styling imports
import { styles } from '~/app/Pages/Setup/ManageLicencing/css/manageLicencing.js';
import { styles as pagedTableStyles } from '~/app/Components/Common/css/pagedTableStyles.jsx';

const ManageLicencing = props => {
  const payload = WebHelpers.getJWTPayload();
  const [state, setState] = useSetState({
    isManageMultiOfficeLicenseModalOpen: false,
    isRemoveModalOpen: false,
    selectedKey: 0,
    editingLicenseFlag: false,
    addProductLicenseFlag: false,
    isMultiOfficeManagementOpen: false,
    showBusinessLicenseManagement: false,
    licensingSetup1040: props.licensingSetup1040 || {},
    licensingSetupBusiness: props.licensingSetupBusiness || {},
    offices: [],
    moLicensingSetup1040: null,
    selectedMultiOffice: null,
    topMostParentRole: props.topMostParentRole,
    isAddLicenseModalOpen: false,
    isAssignLicenseModalOpen: false,
    selectedLicenseType: INDIVIDUAL,
    selectedSeason: payload.season, // default selected season should be the season the user is currently in
  });

  const canAddBusinessLicense = state.selectedSeason > 2023;

  useEffect(() => {
    getAccountLicenses(false, state.selectedSeason);
    getAccountOffices(state.selectedSeason);
  }, []);

  const getAccountLicenses = async (addLicenseFlag = false, selectedSeason) => {
    try {
      const licenseData = await XlinkAPI.getAccountLicenseInfoByLoginID(selectedSeason);
      if (statusOK(licenseData)) {
        setState({
          licensingSetup1040: licenseData.data.licenseInfo1040,
          licensingSetupBusiness: licenseData.data.licenseInfoBusiness,
        });
      }

      if (addLicenseFlag) {
        setState({ addProductLicenseFlag: false });
      }
    } catch (error) {
      ErrorHelpers.handleError('Unable to call get account license info: ', error);
    }

    props.isLoading(false);
  };

  const getAccountOffices = async selectedSeason => {
    try {
      const officesData = await XlinkAPI.getAccountOfficesByLoginID(selectedSeason);

      if (statusOK(officesData)) {
        await setState({
          offices: officesData?.data?.offices,
        });
      }
    } catch (error) {
      ErrorHelpers.handleError('Unable to call get MO accounts license info: ', error);
    }
  };

  const getMOManagedLicenses = async selectedSeason => {
    try {
      const moLicenseData = await XlinkAPI.getMOManagedLicenseInfoByLoginID(selectedSeason);

      if (statusOK(moLicenseData)) {
        await setState({
          moLicensingSetup1040: moLicenseData?.data?.multiOffices1040,
          moLicensingSetupBusiness: moLicenseData?.data?.multiOfficesBusiness,
        });
      }
    } catch (error) {
      ErrorHelpers.handleError('Unable to call get MO accounts license info: ', error);
    }
  };

  // getLicenseStatusText
  const getLicenseStatusText = officeID => {
    if (officeID) return <span style={styles.activeBubbleText}>Active</span>;

    return <span style={styles.unUsedBubbleText}>Unused</span>;
  };

  const openLicenseModal = (licenseInfo, selectedIndex, licenseType) => {
    const modalFlag = licenseInfo[selectedIndex]?.officeID === 0;
    // Depending on whether they clicked a row with an applied license or not open the correct modal
    setState({
      isAssignLicenseModalOpen: modalFlag,
      isRemoveModalOpen: !modalFlag,
      selectedKey: selectedIndex,
      selectedLicenseType: licenseType,
    });
  };

  // The remove license modal is just a SimpleDialog Component. While a license removal is being processed,
  // we should lock actions and shouldn't allow the user to close the modal.
  const closeRemoveLicenseModal = () => {
    if (!state.editingLicenseFlag) {
      setState({
        isRemoveModalOpen: false,
        selectedKey: 0,
      });
    }
  };

  const closeAssignOfficeModal = () => {
    setState({
      editingLicenseFlag: false,
      isAssignLicenseModalOpen: false,
    });
  };

  const assignLicenseToOffice = async officeID => {
    setState({
      editingLicenseFlag: true,
    });

    const licensingSetup =
      state.selectedLicenseType === INDIVIDUAL
        ? state.licensingSetup1040
        : state.licensingSetupBusiness;

    try {
      await XlinkAPI.applyOfficeLicense(
        officeID,
        licensingSetup.licenseInfo[state.selectedKey].licenseID,
        state.selectedSeason,
      );

      closeAssignOfficeModal();
      getAccountLicenses(false, state.selectedSeason);
      getAccountOffices(state.selectedSeason); // we need to get the updated office list that shows whether they have a business license or not
    } catch (error) {
      setState({
        editingLicenseFlag: false,
      });
      ErrorHelpers.handleError('Failed to assign license', error);
    }
  };

  const removeLicense = async () => {
    setState({
      editingLicenseFlag: true,
    });

    const licensingSetup =
      state.selectedLicenseType === INDIVIDUAL
        ? state.licensingSetup1040
        : state.licensingSetupBusiness;

    const appliedLicense = licensingSetup.licenseInfo[state.selectedKey];

    try {
      const res = await XlinkAPI.unapplyOfficeLicense(
        appliedLicense.officeID,
        appliedLicense.licenseID,
        appliedLicense.productCode,
        state.selectedSeason,
      );

      if (statusOK(res) && res?.data?.result) {
        getAccountLicenses(false, state.selectedSeason);
        getAccountOffices(state.selectedSeason); // we need to get the updated office list that shows whether they have a business license or not
      }
    } catch (error) {
      ErrorHelpers.handleError('Failed to remove license', error);
    }

    // Whether successful or err, close out
    setState({
      editingLicenseFlag: false,
      isRemoveModalOpen: false,
      selectedKey: 0,
    });
  };

  /**
   * Add a product license and if successful then we need to fetch
   * the product license again to get the latest for the login.
   */
  const addProductLicense = async (licenseProductCode = 'XLCO') => {
    if (!state.addProductLicenseFlag) {
      setState({ addProductLicenseFlag: true });

      try {
        const res = await XlinkAPI.addProductLicense(
          uuid4(),
          licenseProductCode,
          state.selectedSeason,
        );

        if (statusOK(res)) {
          // Fetch the list of licenses again to get latest updates after
          // adding a product license.
          getAccountLicenses(true, state.selectedSeason);
        }
      } catch (error) {
        ErrorHelpers.handleError('Unable to perform action', error);
      }
    }
    setState({ addProductLicenseFlag: false });
  };

  const setMORows = () => {
    let moSetup = state.moLicensingSetup1040;
    if (state.showBusinessLicenseManagement) {
      moSetup = state.moLicensingSetupBusiness;
    }
    return setupLicenseDataForPagedTable(moSetup);
  };

  const onSeasonChange = e => {
    const updatedSeason = e.target.value;

    if (state.selectedSeason !== updatedSeason) {
      setState({
        selectedSeason: updatedSeason,
      });

      handleFetchLicenseOrOfficeList(!state.isMultiOfficeManagementOpen, updatedSeason);
    }
  };

  const toggleList = () => {
    handleFetchLicenseOrOfficeList(state.isMultiOfficeManagementOpen, state.selectedSeason);

    setState({
      isMultiOfficeManagementOpen: !state.isMultiOfficeManagementOpen,
    });
  };

  const handleFetchLicenseOrOfficeList = (onMOManagement, updatedSeason) => {
    // If they are currently on multie office manangement, then fetch the opposite data and toggle the page
    if (onMOManagement) {
      getAccountLicenses(false, updatedSeason);
      getAccountOffices(updatedSeason);
    } else {
      getMOManagedLicenses(updatedSeason);
    }
  };

  const generateAllSeasons = (initialSeason, currentSeason) => {
    const seasonOptions = [];

    // only restrict seasons if they are on mo management for business licenses
    for (let i = initialSeason; i <= currentSeason; i++) {
      if (
        !state.showBusinessLicenseManagement ||
        !state.isMultiOfficeManagementOpen ||
        (state.showBusinessLicenseManagement && i >= 2024)
      ) {
        seasonOptions.unshift(
          <MenuItem key={i} value={i}>
            {i}
          </MenuItem>,
        );
      }
    }

    return seasonOptions;
  };

  const MO_LICENSE_COLUMNS = [
    'Company Name',
    'Licenses Able To Generate',
    'Licenses Available to Generate',
    'Licenses Generated',
    'Licenses Applied',
    ' ',
  ];

  return (
    <div className="container-fixed" style={{ marginTop: '2.5rem' }}>
      <Paper>
        <div style={{ marginLeft: '3vw', marginRight: '3vw', marginTop: '1vh' }}>
          <div
            style={{
              textAlign: 'left',
              paddingTop: '3vh',
              paddingBottom: '1vh',
            }}
          />

          <div className="btn-license-header">
            <span>
              <Button onClick={toggleList}>
                {!state.isMultiOfficeManagementOpen
                  ? 'Toggle Multi-Office License Management'
                  : 'Toggle Office License Management'}
              </Button>

              <FormControl fullWidth>
                <TextField
                  select
                  name="selectedSeason"
                  id="selectedSeasonDropdown"
                  value={state.selectedSeason}
                  onChange={onSeasonChange}
                  label="Selected Season"
                  variant="outlined"
                  SelectProps={{
                    IconComponent: () => null,
                    style: {
                      width: '11rem',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <ArrowDropDownIcon sx={{ width: '24px' }} />
                      </InputAdornment>
                    ),
                  }}
                >
                  {generateAllSeasons(EARLIEST_ACTIVE_SEASON, LATEST_SEASON)}
                </TextField>
              </FormControl>
            </span>

            {state.topMostParentRole !== HIERARCHY_TYPE.FRANCHISE &&
            !state.isMultiOfficeManagementOpen ? (
              <div className="manage-licence-create-license-btn-wrapper">
                <Button
                  onClick={() =>
                    canAddBusinessLicense
                      ? setState({ isAddLicenseModalOpen: true })
                      : addProductLicense('XLCO')
                  }
                  disabled={state.addProductLicenseFlag}
                >
                  Add a License
                </Button>
              </div>
            ) : state.selectedSeason > 2023 ? (
              <div className="manage-licence-create-license-btn-wrapper">
                <Button
                  onClick={() =>
                    setState({
                      showBusinessLicenseManagement: !state.showBusinessLicenseManagement,
                    })
                  }
                  disabled={state.addProductLicenseFlag}
                >
                  {!state.showBusinessLicenseManagement
                    ? 'Manage Business Licenses'
                    : 'Manage 1040 Licenses'}
                </Button>
              </div>
            ) : null}
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid container style={styles.parentGridContainer}>
              {!state.isMultiOfficeManagementOpen && (
                <>
                  <LicenseTable
                    tableName="1040 Office Licenses"
                    licensingSetup={state.licensingSetup1040}
                    getLicenseStatusText={getLicenseStatusText}
                    openLicenseModal={openLicenseModal}
                    licenseType={INDIVIDUAL}
                  />
                  {state.selectedSeason > 2023 && (
                    <LicenseTable
                      tableName="Business Office Licenses"
                      licensingSetup={state.licensingSetupBusiness}
                      getLicenseStatusText={getLicenseStatusText}
                      openLicenseModal={openLicenseModal}
                      licenseType={BUSINESS}
                    />
                  )}
                </>
              )}
              {state.moLicensingSetup1040 && state.isMultiOfficeManagementOpen && (
                <>
                  <h2 className="setup-section-heading multi-office-manage-license-title">
                    Multi-Office Managed Licenses
                    {state.showBusinessLicenseManagement ? ' Business' : ' 1040'}
                  </h2>
                  <PagedTable
                    columns={MO_LICENSE_COLUMNS}
                    rows={setMORows()}
                    actionText="Manage"
                    onActionClick={data => {
                      if (state.showBusinessLicenseManagement) {
                        setState({
                          isManageMultiOfficeLicenseModalOpen: true,
                          selectedMultiOffice: state.moLicensingSetupBusiness[data.id],
                        });
                      } else {
                        setState({
                          isManageMultiOfficeLicenseModalOpen: true,
                          selectedMultiOffice: state.moLicensingSetup1040[data.id],
                        });
                      }
                    }}
                  />
                </>
              )}
              {/* Bottom Previous and Next */}
              <Grid item xs={12}>
                <Grid container style={styles.footerGridContainer}>
                  <Grid item xs={12} style={styles.footerDivider}>
                    <Divider style={styles.divider} />
                  </Grid>
                  <Grid item xs={10}>
                    {/** Just a spacer to push the buttons right */}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
        {state.isRemoveModalOpen && (
          <SimpleDialog
            open={state.isRemoveModalOpen}
            onConfirm={removeLicense}
            dialogTitle="Unassign License"
            contentText={getRemoveText(
              state.selectedLicenseType === INDIVIDUAL
                ? state.licensingSetup1040
                : state.licensingSetupBusiness,
              state.selectedKey,
            )}
            onClose={closeRemoveLicenseModal}
            confirmLoader={state.editingLicenseFlag}
            styled={true}
          />
        )}
        {AssignLicenseModal && (
          <AssignLicenseModal
            isAssignLicenseModalOpen={state.isAssignLicenseModalOpen}
            closeModal={closeAssignOfficeModal}
            licenseType={state.selectedLicenseType}
            assignLicenseToOffice={assignLicenseToOffice}
            closeAssignOfficeModal={closeAssignOfficeModal}
            getLicenseStatusText={getLicenseStatusText}
            offices={state.offices}
            selectedKey={state.selectedKey}
            licensingSetup={{
              licensingSetup1040: state.licensingSetup1040,
              licensingSetupBusiness: state.licensingSetupBusiness,
            }}
            selectedSeason={state.selectedSeason}
          />
        )}
        {state.isAddLicenseModalOpen && (
          <AddLicenseModal
            season={state.selectedSeason}
            isOpen={state.isAddLicenseModalOpen}
            onClose={() => setState({ isAddLicenseModalOpen: false })}
            addProductLicense={licenseProductCode => addProductLicense(licenseProductCode)}
            drillDownLoginRoleID={props.drillDownHistory[props.drillDownHistory.length - 1].role}
          />
        )}
        {state.isManageMultiOfficeLicenseModalOpen && (
          <Modal
            open={state.isManageMultiOfficeLicenseModalOpen}
            onClose={() => {
              setState({ isManageMultiOfficeLicenseModalOpen: false });
            }}
            disableBackdropClick={true}
          >
            <MultiOfficeManagement
              multiOffice={state.selectedMultiOffice}
              closeModal={() => setState({ isManageMultiOfficeLicenseModalOpen: false })}
              reloadLicensing={() => getMOManagedLicenses(state.selectedSeason)}
              isBusinessManagement={state.showBusinessLicenseManagement}
              selectedSeason={state.selectedSeason}
            />
          </Modal>
        )}
      </Paper>
    </div>
  );
};

export default withStyles({ ...styles, ...pagedTableStyles })(ManageLicencing);
