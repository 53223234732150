const boxShadow = 'rgb(0 0 0 / 25%) 0px 0px 20px 0px';

export const styles = {
  helpTypography: {
    height: '20px',
    opacity: '0.75',
    color: '#637280',
    fontSize: '15px',
    fontWeight: '500',
    letterSpacing: '0.1px',
    lineHeight: '20px',
    marginLeft: '0px',
    marginRight: '36px',
    alignSelf: 'center',
    cursor: 'help',
  },
  helpMenu: {
    top: '4rem',
    right: '0.6rem',
    left: 'auto',
    bottom: 'auto',
    border: '1px solid rgb(204, 204, 204)',
    boxShadow: boxShadow,
    padding: '0',
    maxHeight: '45vh',
    overflow: 'hidden',
  },
  account: {
    top: '4rem',
    right: '0.6rem',
    left: 'auto',
    bottom: 'auto',
    border: '1px solid rgb(204, 204, 204)',
    boxShadow: boxShadow,
    padding: '0',
    zIndex: '999999',
  },
  avatarPicker: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    height: '290px',
    width: '400px',
    padding: '0',
    boxShadow: boxShadow,
  },
  switch: {
    top: '25%',
    left: '25%',
    right: 'auto',
    bottom: 'auto',
    padding: '0',
    border: '1px solid rgb(204, 204, 204)',
    boxShadow: boxShadow,
  },
  backDrop: {
    background: 'rgba(255,255,255, 0.2)',
  },
  dialogContent: {
    padding: '0',
  },
  settingsModals: {
    width: '28em',
    padding: '0',
    position: 'relative',
    margin: 'auto',
    top: '20%',
  },
  versionsModal: {
    position: 'relative',
    margin: 'auto',
    top: '10%',
    width: '45rem',
  },
};
