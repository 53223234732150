// External imports
import React, { useEffect } from 'react';
import {
  Button,
  FormControlLabel,
  Input,
  NativeSelect,
  Paper,
  Radio,
  RadioGroup,
  Checkbox,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
import { SIGNEE } from '~/app/constants.js';
import { findIndex } from '~/app/Pages/Returns/pages/Signatures/signaturesHelper.js';
import { formatPhoneNumber, validateEmail } from '~/app/Utility/general.js';
import handSigningIcon from '~/images/icons/handSigningIcon.png';
// Redux imports
import { useDispatch } from 'react-redux';
import { actions } from '~/app/redux/remoteSign/duck';
import { actions as formViewerActions } from '~/app/redux/modules/formViewer.js';
// Styling imports
import { styles } from '~/app/Pages/Returns/pages/Signatures/components/css/sendSignature.js';
import '~/app/Pages/Returns/pages/Signatures/components/css/sendSignature.css';

const collectedSignatures = [];

/**
 * Component that handles iniating an in-office signature request or a remote signature request as well as sending out
 * the remote signature requests via email(Mandril) or text message(TextLink Plus).
 *
 * @component
 * @category Returns
 * @subcategory Signatures
 */
const SendSignature = ({
  handleNextSignatureNeeded,
  updateModalContent,
  addSignatureRequestData,
  signatureRequestData,
  updateSignaturesCollected,
  allSignaturesCollected,
  signeeData,
  documentType,
  sendRequest,
  classes,
  isLoading,
  returnPrintQueryLoading,
  canUseCellTP,
  canUseCellSP,
  taxpayerElectronicDisclosure,
  spouseElectronicDisclosure,
  businessElectronicDisclosure,
}) => {
  const [state, setState] = useSetState({
    signingMethod: 'Capture Signature',
    contactMethod: '',
    newEmail: '',
  });

  const dispatch = useDispatch();

  useEffect(() => {
    handleModalContent(signeeData.signeeType);
  }, [signeeData.signeeType, signeeData.isAllSignaturesCollected]);

  /**
   * Handles the event changes from the user selections.
   *
   * @param {Object} event
   */
  const handleChange = event => {
    if (event.target.name === 'newEmail' && event.target.value.length > 0) {
      setState({
        [event.target.name]: event.target.value,
        contactMethod: 'input',
      });
    } else if (event.target.value === 'Capture Signature') {
      setState({
        [event.target.name]: event.target.value,
        contactMethod: '',
        newEmail: '',
      });
    } else {
      setState({
        [event.target.name]: event.target.value,
        newEmail: '',
      });
    }
  };

  /**
   * Handles the actions taken on the modal.
   *
   * @param {string} signeeType The person who needs to sign the document.
   */
  const handleModalContent = signeeType => {
    updateSignaturesCollected(signeeType, true);
    !collectedSignatures.includes(signeeType) && collectedSignatures.push(signeeType);
    allSignaturesCollected();
    updateSelectedMethods();
  };

  /** Sets the previous choices the user selected if they clicked the next/back button. */
  const updateSelectedMethods = () => {
    const { signeeType } = signeeData;
    const index = findIndex(signatureRequestData, signeeType);
    const { contactMethod, signingMethod, newEmail } = {
      ...signatureRequestData[index],
    };

    signatureRequestData.some(el => el.signee === signeeType) &&
      setState({
        contactMethod,
        signingMethod,
        newEmail,
      });
  };

  /**  Resets the content if the user clicks the cancel or send button */
  const resetModalContent = () => {
    collectedSignatures.map(val => updateSignaturesCollected(val, false));
    collectedSignatures.splice(0, collectedSignatures.length);
    dispatch(actions.sendSignatureModalSet(false));
  };

  /** Handles when the user clicks the Back button on the modal. */
  const handleBackButton = () => {
    // We need to reset RMS if they hit back. If they click continue with a RMS it well re-set to 'true'
    dispatch(formViewerActions.setRMSflag(false));
    // Save the choice the user selected
    addSignatureRequestData(
      Object.assign(state, { signee: signeeData.signeeType }),
      signeeData.signeeType,
    );
    // Remove the collected signature
    updateSignaturesCollected(collectedSignatures.slice(-1)[0], false);
    collectedSignatures.pop();
    updateModalContent(collectedSignatures.slice(-1)[0]);
  };

  /**
   * Handles when the user clicks the Next/Sign button on the modal.
   * Sends the signature request(s) once we have all the methods
   * of how the user wants to send it.
   */
  const handleNextButton = async () => {
    if (signeeData.isAllSignaturesCollected) {
      // Save the choice the user selected
      addSignatureRequestData(
        Object.assign(state, { signee: signeeData.signeeType }),
        signeeData.signeeType,
      );
      sendRequest();
      resetModalContent();
    } else {
      addSignatureRequestData(
        Object.assign(state, { signee: signeeData.signeeType }),
        signeeData.signeeType,
      );
      setState({
        signingMethod: 'Capture Signature',
        newEmail: '',
        contactMethod: '',
      });
      signeeData.isAllSignaturesCollected
        ? dispatch(actions.sendSignatureModalSet(false))
        : handleNextSignatureNeeded();
    }
  };

  /**  Handles disabling the contact method. */
  const disableContactMethod = () => {
    return (
      state.signingMethod === 'Capture Signature' ||
      (['N', ''].includes(taxpayerElectronicDisclosure) && signeeData.signeeType === 'taxpayer') ||
      (['N', ''].includes(spouseElectronicDisclosure) && signeeData.signeeType === 'spouse') ||
      (['N', ''].includes(businessElectronicDisclosure) && signeeData.signeeType === 'officer')
    );
  };

  /**  Handles disabling the Send/Next button if the needed information hasn't been collected. */
  const disableSendAndNextButton = () => {
    return (
      isLoading ||
      returnPrintQueryLoading ||
      (state.contactMethod.length === 0 && state.signingMethod === 'Remote Signature') ||
      (state.contactMethod === 'input' && state.newEmail.length === 0) ||
      (state.contactMethod === 'input' && !validateEmail(state.newEmail))
    );
  };

  // determine which message we should display. Prioritize the overall check of electronic disclosure first and then checking cellphone fields.
  const buildContactMethodInfoMsg =
    (['N', ''].includes(taxpayerElectronicDisclosure) && signeeData.signeeType === 'taxpayer') ||
    (['N', ''].includes(spouseElectronicDisclosure) && signeeData.signeeType === 'spouse') ||
    (['N', ''].includes(businessElectronicDisclosure) && signeeData.signeeType === 'officer')
      ? 'To enable the remote signature signing method, we need to update their electronic consent to receive and sign documents, which can be done through the Client Data form.'
      : (!canUseCellTP && signeeData.signeeType === 'taxpayer') ||
        (!canUseCellSP && signeeData.signeeType === 'spouse')
      ? 'To utilize the cellphone option, it is necessary to provide your cell number, carrier information, domain, and consent to the terms of use for receiving text messages. These fields can be found on the Client Data form.'
      : '';

  return (
    <Paper elevation={5} className="send-signature-paper">
      <div className="send-signature-modal-container">
        <div className="modal-header-bar">
          <img className="send-signature-image" src={handSigningIcon} />
          <span className="send-signature-title">Capture Signature</span>
        </div>
        <div className="send-signature-content">
          <div className="send-signature-content-title">{`Document: ${documentType}`}</div>
          <div className="send-signature-signee-title">Signee</div>
          <div className="send-signature-signee">{signeeData.signee}</div>
          <div className="send-signature-contact-title">Signing Method</div>
          <NativeSelect
            id="sendSigSigningMethod"
            value={state.signingMethod}
            name="signingMethod"
            className={classes.nativeSelect}
            onChange={handleChange}
            disableUnderline
          >
            return (
            <option key={1} value="Capture Signature">
              Capture Signature
            </option>
            <option key={2} value="Remote Signature">
              Remote Signature
            </option>
            );
          </NativeSelect>
          <div className="send-signature-contact-title">
            Contact Method
            {buildContactMethodInfoMsg !== '' && (
              <Tooltip title={buildContactMethodInfoMsg}>
                <InfoIcon fontSize="small" />
              </Tooltip>
            )}
          </div>
          <RadioGroup name="contactMethod" value={state.contactMethod} onChange={handleChange}>
            {signeeData.email && (
              <FormControlLabel
                value={signeeData.email}
                control={<Radio id="sendSigRadioEmail" disabled={disableContactMethod()} />}
                label={signeeData.email}
              />
            )}
            {signeeData.cell && (
              <FormControlLabel
                value={signeeData.cell}
                control={
                  <Radio
                    id="sendSigRadioCell"
                    disabled={
                      disableContactMethod() ||
                      (!canUseCellTP && signeeData.signeeType === 'taxpayer') ||
                      (!canUseCellSP && signeeData.signeeType === 'spouse')
                    }
                  />
                }
                label={formatPhoneNumber(signeeData.cell)}
              />
            )}
            <FormControlLabel
              value="input"
              control={<Radio id="sendSigRadioInput" disabled={disableContactMethod()} />}
              label={
                <div className="send-signature-label">
                  <span>
                    <Input
                      id="sendSigContactMethodInput"
                      name="newEmail"
                      placeholder="New Email"
                      className={classes.input}
                      onChange={handleChange}
                      value={state.newEmail}
                      type="email"
                      inputProps={{
                        maxLength: 50,
                      }}
                      disableUnderline
                      disabled={disableContactMethod()}
                    />
                  </span>
                </div>
              }
            />
          </RadioGroup>
          {signeeData.signeeType === SIGNEE.SPOUSE ||
          signeeData.signeeType === SIGNEE.SPOUSE_SECOND_SIGNATURE ? null : (
            <FormControlLabel
              value="input"
              control={
                <Checkbox
                  color="primary"
                  id="prepCopyCheckbox"
                  disabled={disableContactMethod()}
                  onChange={e => dispatch(formViewerActions.togglePrepRMSCopy(e.target.checked))}
                />
              }
              label={
                <div className="send-signature-label">
                  <span>
                    <div className="send-signature-preparer-email">
                      {"Send PDF Copy to Preparer's Email?"}
                    </div>
                  </span>
                </div>
              }
            />
          )}
        </div>
        <div className="send-signature-modal-button-block">
          <Button
            id="sendSigCancelBtn"
            onClick={() => {
              resetModalContent();
            }}
          >
            Cancel
          </Button>
          {collectedSignatures.length > 1 && (
            <Button id="sendSigBackBtn" className={classes.button} onClick={handleBackButton}>
              Back
            </Button>
          )}

          <Button
            id="sendSigNextSendBtn"
            className={classes.button}
            onClick={handleNextButton}
            disabled={disableSendAndNextButton()}
          >
            {signeeData.isAllSignaturesCollected ? 'Send' : 'Next'}
          </Button>
        </div>
      </div>
    </Paper>
  );
};

export default withStyles(styles)(SendSignature);
