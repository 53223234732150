// External imports
import React, { useEffect } from 'react';
import moment from 'moment';
// Internal imports
import { DEFAULT_MAINTENANCE_MODE_TITLE, DEFAULT_MAINTENANCE_MODE_BODY } from '~/app/constants.js';
import PerformanceMaintenanceMsg from '#/Common/MaintenanceRoutineMsg/PerformanceMaintenanceMsg.jsx';
import { checkStartEndDate, isCurrentTimePriorToGivenTime } from '~/app/Utility/general.js';
import { publicRoutes } from '~/app/Router/Routes.js';
import ErrorHelpers from '~/app/errorHelpers.js';
import XlinkAPI from '~/app/api/xlinkAPI.js';
import { statusOK } from '~/app/webHelpers.js';
// Redux imports
import { useDispatch, useSelector } from 'react-redux';
import { actions as appActions } from '~/app/redux/modules/app';
import { actions as maintenanceActions } from '~/app/redux/modules/maintenance';
import { actions as returnActions } from '~/app/redux/returnProfile/duck';

/**
 * Appwide maintenance mode component
 * It handles determining whether the application is performing maintenance and alerts the user ahead of time before disabling the application
 *
 * @component MaintenanceMode
 * @category App.js
 */
const MaintenanceMode = () => {
  const dispatch = useDispatch();
  const {
    isMaintenanceModeOn,
    isMaintenanceModeMessageOpen,
    maintenanceModeTitle,
    maintenanceModeBody,
    maintenanceModeStartDate,
    maintenanceModeEndDate,
  } = useSelector(state => ({
    isMaintenanceModeOn: state.maintenance.isMaintenanceModeOn,
    isMaintenanceModeMessageOpen: state.maintenance.isMaintenanceModeMessageOpen,
    maintenanceModeTitle: state.maintenance.maintenanceModeTitle,
    maintenanceModeBody: state.maintenance.maintenanceModeBody,
    maintenanceModeStartDate: state.maintenance.maintenanceModeStartDate,
    maintenanceModeEndDate: state.maintenance.maintenanceModeEndDate,
  }));

  useEffect(() => {
    // initial poll for the maintenance mode data
    pollForMaintenanceMode();

    // Poll xlinkcloud for the maintenance flag alert and also the dates of the maintenance - this does not trigger initially until the alotted time.
    const timer = setInterval(() => {
      pollForMaintenanceMode();
    }, 300000); // this is 5 minutes

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    isMaintenanceModeTimeCheck(
      maintenanceModeStartDate,
      maintenanceModeEndDate,
      isMaintenanceModeOn,
    );
  }, [maintenanceModeStartDate, maintenanceModeEndDate]);

  const pollForMaintenanceMode = async () => {
    try {
      const res = await XlinkAPI.pollForMaintenanceMode();
      if (statusOK(res)) {
        let maintenanceModeStartDate = '';
        let maintenanceModeEndDate = '';

        if (res.data.maintenanceModeStartDate && res.data.maintenanceModeEndDate) {
          maintenanceModeStartDate = moment(res.data.maintenanceModeStartDate).local();
          maintenanceModeEndDate = moment(res.data.maintenanceModeEndDate).local();
        }
        const isMaintenanceModeTime = checkStartEndDate(
          maintenanceModeStartDate,
          maintenanceModeEndDate,
        );

        dispatch(maintenanceActions.setIsMaintenanceModeOn(res.data.isMaintenanceModeOn));
        dispatch(
          maintenanceActions.setMaintenanceModeDates(
            maintenanceModeStartDate,
            maintenanceModeEndDate,
          ),
        );
        dispatch(maintenanceActions.setIsMaintenanceModeTime(isMaintenanceModeTime));

        // prioritize title+body coming from db, otherwise, use default
        const title = res.data.maintenanceModeTitle
          ? res.data.maintenanceModeTitle
          : DEFAULT_MAINTENANCE_MODE_TITLE;
        const body = res.data.maintenanceModeBody
          ? res.data.maintenanceModeBody
          : DEFAULT_MAINTENANCE_MODE_BODY;

        // We need to let the customer know 15 minutes ahead of time, and then use the start/end date check to actually prevent session
        // due to this we need to use isMaintenanceModeOn rather than isMaintenanceModeTime. However, it doesn't make sense to show messages after the end date.
        const isFifteenMinutesPrior = isCurrentTimePriorToGivenTime(
          res.data.maintenanceModeStartDate,
          15,
        );

        const shouldDisplayMessage =
          !checkStartEndDate('', maintenanceModeEndDate) ||
          !isFifteenMinutesPrior ||
          (location.pathname === '/' && isMaintenanceModeTime)
            ? false
            : res.data.isMaintenanceModeOn;

        dispatch(maintenanceActions.toggleMaintenanceModeMessageModal(shouldDisplayMessage));
        dispatch(maintenanceActions.setMaintenanceModeMessage(title, body));

        // can be used if needed
        return isMaintenanceModeTime;
      }
    } catch (error) {
      console.log('error: ', error);
      ErrorHelpers.handleError('Error polling for maintenance mode', error);
    }
  };

  // This check determines if the application should be disabled or not.
  const isMaintenanceModeTimeCheck = (
    maintenanceModeStartDate,
    maintenanceModeEndDate,
    isMaintenanceModeOn,
  ) => {
    let isMaintenanceModeTime = false;

    // we only care about start and enddate if the isMaintenanceModeOn flag is on. This is a safety catch to prevent needless processes from running.
    if (isMaintenanceModeOn) {
      if (maintenanceModeStartDate && maintenanceModeEndDate) {
        isMaintenanceModeTime = checkStartEndDate(maintenanceModeStartDate, maintenanceModeEndDate);

        if (isMaintenanceModeTime) {
          // this snackbar is permanetly displayed until it is maintenance time is done - does not display for Login page
          dispatch(
            appActions.showSnackbarMessage(
              'Scheduled Maintenance - The Application is disabled',
              'info',
              1, // only display while it is maintenance mode time. This prevents the issue of the snackbar displaying for the login page when maintenance mode is turned off
              {
                vertical: 'top',
                horizontal: 'center',
              },
            ),
          );
          handleKickUser(isMaintenanceModeTime);
        }
      }
    }

    dispatch(maintenanceActions.setIsMaintenanceModeTime(isMaintenanceModeTime));
  };

  const handleKickUser = isMaintenanceModeTime => {
    if (isMaintenanceModeTime) {
      // each second index of a public routes pathname is unique - this avoids the issue of using regex to compare codes in pathnames `:code`
      const currentLocation = location.pathname?.split('/')?.[1]; // '/remotesign/1awdawdwa' - > 'remotesign'

      // only kick users on auth pages (requires they log in)
      const isAPublicRoute = publicRoutes.find(route => {
        const routeBasePathname = route.path?.split('/')?.[1];
        return currentLocation === routeBasePathname;
      });

      if (!isAPublicRoute) {
        if (location?.pathname === '/tax-return') {
          dispatch(returnActions.closeActiveReturn());
        }
        ErrorHelpers.forceLogout('Scheduled Maintenance is currently in progress');
      }
    }
  };

  return (
    isMaintenanceModeOn && (
      <PerformanceMaintenanceMsg
        open={isMaintenanceModeMessageOpen}
        heading={maintenanceModeTitle}
        msg={maintenanceModeBody}
      />
    )
  );
};

export default MaintenanceMode;
