import React, { Fragment, useEffect } from 'react';
// Internal imports
import documentForm from '~/images/icons/icons8-google-form.png';
import attachIcon from '~/images/icons/icons8-attach.png';
import instructionsIcon from '~/images/icons/icons8-user_manual.png';
import attachIconWhite from '~/images/icons/icons8-attach-2x.png';
import AddDocumentModal from '../ArchivedDocuments/Modals/AddDocumentModal.jsx';
import { statusOK } from '~/app/webHelpers.js';
import XlinkAPI from '~/app/api/xlinkAPI';
import { useSetState } from '~/app/Utility/customHooks';
import { actions as formViewerActions } from '~/app/redux/modules/formViewer';
// External imports
import { useDispatch } from 'react-redux';
import {
  Divider,
  Input,
  NativeSelect,
  FormControl,
  FormHelperText,
  withStyles,
  FormLabel,
  Button,
  Typography,
  Grid,
} from '@material-ui/core';
// Styling imports
import '~/app/Pages/Returns/components/Document/AttachDocumentModal/css/AttachDocumentModal.css';
import { styles } from '~/app/Pages/Returns/components/Document/AttachDocumentModal/css/AttachDocumentModal.js';

const instructions = `To add a new document, click > Add New Documents. Next,
  click > Browse > double click on your document > enter a
  document description > choose a type > click Add. Lastly,
  click > Select Existing Document > choose document > enter
  attachment description > click Attach.`;

/**
 * This component renders the AttachDocumentModal that lets the user attach documents to a link in "Binary Attachments" within FormViewer
 *
 * @component AttachDocumentModal
 * @category FormViewer
 **/
const AttachDocumentModal = props => {
  const [state, setState] = useSetState({
    userDescription: '',
    documentID: 0,
    image: null,
    imageName: '',
    imageDescription: '',
    imageType: 1,
    importDocumentModalOpen: false,
    attachmentType: '',
    selectedAddedDocument: -1,
    isAddDocumentModalOpen: false,
    selectedBinaryAttachment: props.documentsToBeAttached?.[0],
    binaryAttachments: props.documentsToBeAttached,
    buttonID: props.buttonID,
    disableDescription: false,
  });

  const { classes } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    // specific button hit, we must set an unchanging description to prevent reject
    if (props.buttonID === 'usrc42') {
      setState({
        userDescription: '8332 Signature Document',
        disableDescription: true,
      });
    }
  }, [props.buttonID]);

  /**
   * For determining which document was selected in the dropdown, and then setting that documents data to state.
   *
   * @param {number} e e.target.value this id is used to find the specific document within the props.attachments
   */
  const handleDocumentSelection = e => {
    const {
      document_id: documentID,
      document_name: imageName,
      document_description: imageDescription,
      document_media_type: attachmentType,
    } = props.attachments.find(doc => doc.document_id === e.target.value);

    setState({
      documentID: documentID,
      selectedAddedDocument: e.target.value,
      imageName: imageName,
      imageDescription: imageDescription,
      attachmentType: attachmentType,
    });
  };

  const handleBinaryAttachmentSelection = e => {
    const binaryAttachment = state.binaryAttachments.find(
      attachment => attachment.dispName === e.target.value,
    );

    setState({
      selectedBinaryAttachment: binaryAttachment,
    });
  };

  /**
   * For creating the dropdown form and generating options using props.attachments
   * props.attachments - uploaded documents from archive documents
   *
   * @returns {jsx} renders dropdown
   */
  const getAddedDocumentList = () => {
    return (
      <FormControl classes={{ root: classes.styledFormControl }}>
        <NativeSelect
          disableUnderline
          classes={{ root: classes.docUploadInp }}
          value={state.selectedAddedDocument}
          onChange={handleDocumentSelection}
        >
          <option value={-1} disabled>
            Select Existing Document
          </option>
          {props.attachments.map(doc => {
            if (doc.document_media_type.includes('/pdf;')) {
              return (
                <option key={doc.document_id.toString()} value={doc.document_id}>
                  {doc.document_name}
                </option>
              );
            } else {
              return null;
            }
          })}
        </NativeSelect>
      </FormControl>
    );
  };

  /**
   * For passing document state up to parent FormViewer where addOrAttachDocument makes the req to upload document
   *
   * @param {event} e used as domEvent in async req addOrAttachDocument
   * @returns {undefined} blank return
   */
  const handleDocumentUpload = async e => {
    if (state.userDescription === '' || state.selectedAddedDocument === -1) {
      props.showSnackBarErr(
        'An attachment description must be entered and a document must be selected',
      );
      return;
    } // No Document Selected or Attach description
    let image = '';
    try {
      const res = await XlinkAPI.getReturnAttachmentByRIDDocId(0, state.documentID);
      if (statusOK(res)) {
        image = res.data.split(',')[1];
      }
    } catch (err) {
      dispatch(
        formViewerActions.showErrorDialog(
          'Unable to attach the document to the return.',
          'Attach Document Error',
        ),
      );
    }

    await props.addOrAttachDocument(
      e,
      state.buttonID,
      state.selectedBinaryAttachment?.efcode,
      state.userDescription.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
      image,
      state.imageDescription,
      state.imageName,
      state.imageType,
      state.attachmentType,
      state.selectedAddedDocument,
    );
    props.handleCloseAttachDocumentModal();
  };

  /**
   * For handling change to description input
   *
   * @param {event} e for sending target value of user description
   */
  const handleDescriptionChange = e => {
    setState({ userDescription: e.target.value });
  };

  /** Handles opening/closing the add document modal */
  const toggleAddDocumentModal = () => {
    setState({ isAddDocumentModalOpen: !state.isAddDocumentModalOpen });
  };

  /**
   * renders form section displaying form type selected, and renders description input
   *
   * @returns {jsx} renders UI and Input
   */
  const renderToFormSection = () => {
    return (
      <Fragment>
        <div className="render-form-section-div-style">
          <FormControl classes={{ root: classes.styledFormControl }}>
            <NativeSelect
              disableUnderline
              classes={{ root: classes.docUploadInp }}
              value={state.selectedBinaryAttachment?.dispName}
              onChange={handleBinaryAttachmentSelection}
            >
              <option value={-1} disabled>
                Select Binary Attachment
              </option>
              {state.binaryAttachments?.map((attachment, i) => {
                return (
                  <option key={i} value={attachment.dispName}>
                    {attachment.dispName}
                  </option>
                );
              })}
            </NativeSelect>
          </FormControl>
          <div className="attach-doc-secttion-image-container">
            <img
              className="render-form-section-image-style"
              id="clientNameInfo"
              src={documentForm}
            />
          </div>
          <div className="render-form-section-div-two">
            <FormLabel classes={{ root: classes.styledFormLabelOne }}>
              {state.selectedBinaryAttachment?.dispName}
            </FormLabel>
            <FormLabel classes={{ root: classes.styledFormLabelTwo }}>
              {state.selectedBinaryAttachment?.hint}
            </FormLabel>
          </div>
        </div>
        <Grid container>
          <Grid item xs={12} className={classes.styledFlexGrid}>
            <Grid container justify="flex-start">
              <FormLabel classes={{ root: classes.styledFormLabelThree }}>
                Attachment Description{' '}
              </FormLabel>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.styledFlexGrid}>
            <Grid container justify="center">
              <Input
                id="userDescription"
                disableUnderline
                value={state.userDescription}
                onChange={handleDescriptionChange}
                classes={{ root: classes.styledInput }}
                disabled={state.disableDescription}
              />
            </Grid>
          </Grid>
        </Grid>
      </Fragment>
    );
  };

  return (
    <div>
      <AddDocumentModal
        handleCloseDocumentModalOk={props.addOrAttachDocument}
        showSnackBarErr={props.showSnackBarErr}
        isAddDocumentModalOpen={state.isAddDocumentModalOpen}
        toggleAddDocumentModal={toggleAddDocumentModal}
      />
      <Typography
        variant="body2"
        id="simple-modal-title"
        classes={{ root: classes.styledTypography }}
      >
        <img id="paperClipIcon" className="attach-doc-img-one" src={attachIconWhite} />
        <span className="attach-doc-header">Attach Document</span>
      </Typography>
      <div className="attach-doc-div-one">
        <div className="attach-doc-div-two">
          <Grid container>
            <Grid item xs={9} classes={{ item: classes.instructionsContainer }}>
              <div className="attach-doc-instructions-img-container">
                <img
                  id="instructionsIcon"
                  className="attach-doc-instructions-img"
                  src={instructionsIcon}
                />
              </div>
              <div className="attach-doc-instructions-text-container">
                <Typography
                  classes={{ root: classes.instructionsTitle }}
                  variant="title"
                  gutterBottom
                >
                  Instructions:
                </Typography>
                <Typography classes={{ root: classes.instructions }} variant="body1">
                  {instructions}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={9} className={classes.styledFlexGrid}>
              <Typography classes={{ root: classes.docTitle }} variant="title" gutterBottom>
                Attach Document
              </Typography>
            </Grid>
            <Grid item xs={3} className={classes.styledFlexGrid}>
              <Grid container justify="flex-end">
                <Button
                  id="btnOpenAttachModalDocArchive"
                  size="medium"
                  color="primary"
                  classes={{ root: classes.addDocumentBtn }}
                  onClick={toggleAddDocumentModal}
                >
                  Add New Documents
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.styledGridWithPadding}>
              <Grid container justify="center">
                <FormControl
                  fullWidth={true}
                  classes={{ root: classes.styledFormControlMarginBottom }}
                >
                  {getAddedDocumentList()}
                  <FormHelperText>IRS only allows attachments of PDF file</FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              item
              xs={5}
              classes={{
                item: classes.styledFlexGridDivider,
              }}
            >
              <Grid container justify="flex-start">
                <Divider
                  classes={{
                    root: classes.documentDivider,
                  }}
                  variant="fullWidth"
                  light={true}
                />
              </Grid>
            </Grid>
            <Grid item xs={2} classes={{ item: classes.styledFlexGridImage }}>
              <Grid container justify="center">
                <img id="clientNameInfo" className="noselect" src={attachIcon} />
              </Grid>
            </Grid>
            <Grid
              item
              xs={5}
              classes={{
                item: classes.styledFlexGridDivider,
              }}
            >
              <Divider
                classes={{ root: classes.documentDivider }}
                variant="fullWidth"
                light={true}
              />
            </Grid>
            <Grid item xs={12}>
              <div className="attach-doc-div-five">
                <Typography variant="title" gutterBottom classes={{ root: classes.docTitle }}>
                  To Form
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12}>
              {state.buttonID != null && renderToFormSection()}
            </Grid>
            <Grid item xs={12} classes={{ item: classes.styledGridWithMargin }}>
              <Grid container justify="flex-end">
                <div className="attach-doc-div-four">
                  <Button
                    classes={{ root: classes.styleButtonMarginRight }}
                    size="small"
                    color="default"
                    onClick={props.handleCloseAttachDocumentModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    id="btnAttachAddDocModal"
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={e => handleDocumentUpload(e)}
                  >
                    Attach
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default withStyles(styles)(AttachDocumentModal);
