// exports
export const PUBLIC_BUCKET = {
  baseUrl: 'https://s3-us-west-2.amazonaws.com/xlinkonline',
  docs: '/docs',
  file: {
    republicDisclosureConsent: '/RepublicElectronicConsent.pdf',
  },
};

export const LOGIN_SETTINGS = {
  SHOW_IRS_MESSAGE: 'showIRSMessage',
  SHOW_TERMS_AND_CONDITIONS: 'showTermsAndConditions',
  SHOW_ON_SCREEN: 'onScreen',
  SHOW_SCRIPTEL: 'scriptel',
  SHOW_TOPAZ: 'topaz',
  SHOW_INTERVIEW_MODE: 'startInterview',
};

export const SIDE_TABS = {
  DASHBOARD_MAIN: 0,
  PREPARERS: 1,
  ACCOUNTS: 2,
  OFFICES: 3,
  TAX_RETURNS: 4,
  SERVER: 5,
  PACKAGES: 6,
  CALENDAR: 7,
  MESSAGES: 8,
  USER_LOOKUP: 9,
  CHECKS: 10,
  RFD_CALC: 11,
  REPORTING: 12,
  PAYMENTS: 13,
  MOVE_RETURN: 14,
  TECH_LOGINS: 15,
  USER_PROFILE: 16,
};

// Handles validating Role Name input in 'Access Levels'
export const ROLE_NAME_VALIDATION = /^[A-z0-9 ]*$/;

export const SIDEBAR_BUTTON_COUNT = 5;

// TODO: rename these, the tab content changes based on context
export const DASHBOARD_TITLE_TABS = {
  OVERVIEW: 0,
  LIST_VIEW: 1,
  WIDGETS: 2,
  ACTIVE_RETURN: 3,
  BACK: 4,
};

// the latest tax season
// NOTE: this must be updated each year
export const LATEST_SEASON = 2025;

// the latest tax pass season
// NOTE: CALC has only implemented s23 and up
export const LATEST_TAXPASS_SEASON = 2023;

// the earliest supported tax season for calc server. We keep track of this in the API under `supportedYears`
// NOTE: this must be updated any time `supportedYears` is updated in the API
export const EARLIEST_ACTIVE_SEASON = 2022;

// List of Tax Years with Guided Estimator Available
export const WIZARD_SEASONS = [2022, 2023];

export const BUSINESS_ENTITY_TYPES = [
  {
    ENTITY: '1065',
    INDEX: 0,
    ENTITYCODE: 'P',
  },
  {
    ENTITY: '1120S',
    INDEX: 1,
    ENTITYCODE: 'S',
  },
  {
    ENTITY: '1120',
    INDEX: 2,
    ENTITYCODE: 'C',
  },
  {
    ENTITY: '1041',
    INDEX: 3,
    ENTITYCODE: 'F',
  },
  {
    ENTITY: '990',
    INDEX: 4,
    ENTITYCODE: 'X',
  },
];

export const INDIVIDUAL_ENTITY_TYPE = {
  ENTITY: '1040',
  ENTITYCODE: 'I',
};

export const TITLE_TAB_NAMES = {
  TAX_RETURNS: 'Tax Returns',
};

export const CUSTOMER_TITLE_TABS = {
  CUSTOMERS: 0,
};

export const CALENDAR_TITLE_TABS = {
  CALENDAR: 0,
};

export const OV_NOTEBOOK_TABS = {
  TAX_RETURNS: 0,
  ACCEPTED: 1,
  REJECTS: 2,
  EXTENSIONS: 3,
};

export const OV_NOTEBOOK_ITEMS = {
  EF_RETURN: 0,
  ACKNOWLEDGED: 1,
  REJECTED_REFUSED: 2,
  EXTENSIONS_ACK: 3,
  PAPER_RETURN: 4,
  CHECK_READY: 5,
  STATE_REJECTED: 6,
  EXTENSIONS_REJECTED: 7,
};

export const OV_NOTEBOOK_ROWS = {
  ROW_ONE: 0,
  ROW_TWO: 1,
};

//  make sure these fields match up with the order of the allFilters array in initial state in returnList.js
export const TAX_RETURN_LIST_FILTERS = {
  ALL: 0,
  RETURN_ID: 1,
  DATE: 2,
  EFIN: 3,
  NAME: 4,
  STATE: 5,
  STATUS: 6,
  SSNEIN: 7,
  PEND_SIGN: 8,
  COMPLETE_SIGN: 9,
  DELETED_RETURN_STATUS: 10,
  RAC_STATUS: 11, // This option is not accessible in the dropdown list, this filter can only be applied through a widget clickthrough.
};

export const ACCOUNT_LIST_FILTERS = {
  ALL: 0,
  NAME: 1,
};

export const OFFICE_LIST_FILTERS = {
  ALL: 0,
  NAME: 1,
};

export const PREPARER_LIST_FILTERS = {
  ALL: 0,
  NAME: 1,
};

export const CUSTOMER_LIST_FILTERS = {
  ALL: 0,
  NAME: 1,
};

export const TABLE_PAGE_SIZE = 14;

export const AUTH_STAGE = {
  LOGGED_OUT: 0,
  AWAIT_CREDENTIALS: 1,
  AWAIT_SECOND_FACTOR: 2,
  LOGGED_IN: 3,
  FORGOT_PASSWORD: 4,
  FINISH_SECOND_FACTOR: 5,
  AWAIT_PICK_OFFICE: 6,
  AWAIT_TOC_MESSAGE: 7,
  AWAIT_IRS_MESSAGE: 8,
  FORGOT_USERNAME: 9,
  AWAIT_REVIEW_DEVICE: 10,
};

/**
 * TECH_SUPPORT_SBA is a type that represents our customer CTPA21.
 * users that are employed by CTPA21 will be logging into our admin views
 * they should only be able to reteive data for accounts under CTPA21
 * see: https://crosslinktax.atlassian.net/browse/CLO-8162
 *
 */

// defined by auth.GetHierarchyTypeIDByLoginID
// The ACCESS TYPE for the user loggin in.
export const HIERARCHY_TYPE = {
  ADMIN: 1,
  SSB: 10,
  SB: 20,
  FRANCHISE: 30,
  EFIN: 40,
  PREPARER: 41,
  CLERK: 42, // is it needed?
  TECH_SUPPORT: 50,
  TECH_SUPPORT_SBA: 60, // This is in support of our customer CTPA21
};

export const ROLE_TYPE = {
  SUPERUSER: 1,
  RESELLER: 10,
  MULTIOFFICE: 30,
  EFIN: 40,
  PREPARER: 41,
  TECH_SUPPORT: 50,
  TECH_SUPPORT_SBA: 60, // This is in support of our customer CTPA21
};

// The ROLES for the user logging in.
export const ACCOUNT_TYPES = {
  ANALYST: 1,
  EFIN: 4,
  FRANCHISE: 5,
  MANAGER: 6,
  OFFICCE_SUPP: 7,
  PREPARER: 8,
  RECEPTION: 9,
  SB_ADMIN: 10, // Used to create Reseller accounts
  SSB_ADMIN: 11,
  SB_TECH: 12,
  SSB_TECH: 13,
  RESELLER: 14,
};

export const BILLING_FORM_TYPES = {
  WORKSHEET: 'W',
  LINEITEM: 'L',
  FORM: 'F',
  EFIN: 'E',
};

export const BILLING_ITEM_TYPE = {
  FormWkxLineItem: 1,
  CUSTOM: 2,
  DISCOUNT: 3,
};

export const DASHBOARD_DATA = {
  PREVIEWTABLESIZE: 7,
  DASHBOARDLISTSIZE: 14,
};
export const USERDB = {
  ZIPCODE: 0,
  EFIN: 1,
  EMPLOYER: 2,
  CAREPROVIDER: 3,
  REFERRALS: 4,
  DONEE: 5,
  BANKRTN: 6,
  OCCUPATION: 7,
  SITEIDENTIFIERS: 8,
  USERSTATUSCODES: 9,
  EDUINST: 10,
};

export const FINGERPRINT_OPTIONS = {
  excludes: {
    colorDepth: true,
    hardwareConcurrency: true,
    screenResolution: true,
    availableScreenResolution: true,
    timezoneOffset: true,
    timezone: true,
    addBehavior: true,
    openDatabase: true,
    doNotTrack: true,
    canvas: true,
    adBlock: true,
    plugins: true,
    hasLiedLanguages: true,
    hasLiedResolution: true,
    hasLiedOs: true,
    hasLiedBrowser: true,
    touchSupport: true,
    fonts: true,
    fontsFlash: true,
    audio: true,
    enumerateDevices: true,
  },
};

export const SB_REQUIRED_SETUP_PAGES = {
  SETUP: {
    LOGINS: {
      INDEX: 0,
      NAME: 'Logins',
    },
    ANCILLARY: {
      INDEX: 1,
      NAME: 'Ancillary Products',
    },
    PREPARER: {
      INDEX: 2,
      NAME: 'Preparer',
    },
    COBRANDING: {
      INDEX: 3,
      NAME: 'Cobranding',
    },
  },
};

/* Use this to determine what page Save&Next goes to */
export const SB_REQUIRED_SETUP_PAGES_NEXT = {
  LOGINS: {
    NEXT: 'Ancillary Products',
    PREV: 'None',
  },
  ANCILLARY: {
    NEXT: 'Manage Licensing',
    PREV: 'Logins',
  },
  MANAGELICENCING: {
    NEXT: 'None',
    PREV: 'Ancillary Products',
  },
};

/* Use this to determine what page Save&Next goes to */
export const SB_REQUIRED_SETUP_PAGES_NEXT_PLUS_COBRAND = {
  LOGINS: {
    NEXT: 'Ancillary Products',
    PREV: 'None',
  },
  ANCILLARY: {
    NEXT: 'Cobranding',
    PREV: 'Logins',
  },
  COBRANDING: {
    NEXT: 'Manage Licensing',
    PREV: 'Ancillary Products',
  },
  MANAGELICENCING: {
    NEXT: 'None',
    PREV: 'Cobranding',
  },
};

/* Use this to determine what page Save&Next goes to */
export const REQUIRED_SETUP_PAGES_NEXT = {
  OFFICE: {
    NEXT: 'ERO',
    PREV: 'None',
  },
  ERO: {
    NEXT: 'Preparer',
    PREV: 'Office',
  },
  PREPARER: {
    NEXT: 'Billing',
    PREV: 'ERO',
  },
  BILLING: {
    NEXT: 'Licensing',
    PREV: 'Preparer',
  },
  LICENCING: {
    NEXT: 'EFiling',
    PREV: 'Billing',
  },
  EFILING: {
    NEXT: 'Printing',
    PREV: 'Licensing',
  },
  PRINTING: {
    NEXT: 'Checks',
    PREV: 'EFiling',
  },
  CHECKS: {
    NEXT: 'Logins',
    PREV: 'Printing',
  },
  LOGINS: {
    NEXT: 'Defaults',
    PREV: 'Checks',
  },
  DEFAULTS: {
    NEXT: 'Audit',
    PREV: 'Logins',
  },
  AUDIT: {
    NEXT: 'Client Letters',
    PREV: 'Defaults',
  },
  CLIENT_LETTERS: {
    NEXT: 'PayJunction',
    PREV: 'Audit',
  },
  PAYMENTS: {
    NEXT: 'None',
    PREV: 'Client Letters',
  },
};

export const MAX_NUM_IMAGES_IN_PREVIEW = 4;

export const REQUIRED_SETUP_PAGES = {
  OFFICE: {
    INDEX: 1,
    NAME: 'Office',
  },
  ERO: {
    INDEX: 1,
    NAME: 'ERO',
  },
  PREPARER: {
    INDEX: 2,
    NAME: 'Preparer',
  },
  BILLING: {
    INDEX: 3,
    NAME: 'Billing',
  },
  LICENCING: {
    INDEX: 4,
    NAME: 'Licensing',
  },
};
export const BUSINESS_SETUP_PAGES = {
  CLIENT_LETTERS: {
    INDEX: 1,
    NAME: 'Business Client Letters',
  },
  BILLING: {
    INDEX: 2,
    NAME: 'Business Billing',
  },
};

export const EXTENDED_SETUP_PAGES = {
  EFILING: {
    INDEX: 0,
    NAME: 'EFiling',
  },
  PRINTING: {
    INDEX: 1,
    NAME: 'Printing',
  },
  CHECKS: {
    INDEX: 2,
    NAME: 'Checks',
  },
  LOGINS: {
    INDEX: 3,
    NAME: 'Logins',
  },
  DEFAULTS: {
    INDEX: 4,
    NAME: 'Defaults',
  },
  AUDIT: {
    INDEX: 5,
    NAME: 'Audit',
  },
  CLIENT_LETTERS: {
    INDEX: 6,
    NAME: 'Client Letters',
  },
  PAYMENTS: {
    INDEX: 7,
    NAME: 'PayJunction',
  },
  MOBILE: {
    INDEX: 8,
    NAME: 'Mobile Site',
  },
  RESTRICTED_FIELDS: {
    INDEX: 9,
    NAME: 'Restricted Fields',
  },
};

export const COMMUNICATIONS_SETUP_PAGES = {
  TEXT: {
    INDEX: 0,
    NAME: 'Text',
  },
};

export const SETTINGS_SHORTCUTS = {
  OFFICE: 1,
  EFO: 2,
  PREPARER: 3,
  BILLING: 4,
  LOGINS: 5,
};
export const CHECK_PRINTING = {
  CHECK_READY_TO_PRINT: 'P',
  CHECK_PRINTED: 'S',
};

export const SPINNER_DIALOGS = {
  PROCESS_REQUEST: 'Processing Request...',
  CHANGING_PASSWORD: 'Changing Password...',
  FETCHING_QR: 'Fetching QR Code...',
  FETCHING_RETURN: 'Fetching Return...',
  CHECK_DEVICE: 'Checking device...',
  REMOTE_SIGNATURE: 'Processing Signature...',
  LOADING_PDF: 'Loading PDF...',
  LOADING: 'Loading...',
};

export const CALC_TYPE = {
  FIELD_CALC: '1',
  ASSET_CALC: '2',
  OVERRIDE_CLEAR: '3',
};

export const FILING_STATUS = {
  1: 'Single',
  2: 'Married Filing Jointly',
  3: 'Married Filing Separately',
  4: 'Head of Household',
  5: 'Widow(er)',
};

export const FILING_STATUS_NUMBERS = {
  SING: 1,
  MFJ: 2,
  MFS: 3,
  HOH: 4,
  WID: 5,
};
export const SIGNATURE_PAD_TYPES = {
  INITIAL_VALUE: 0,
  ON_SCREEN: 1,
  SCRIPTEL: 2,
  TOPAZ: 3,
};

export const MENU_TYPE = {
  MENU_ITEM: '1',
  DIVIDER: '2',
  LINE_BREAK: '3',
};

export const MESSAGE_SENDER_TYPE = {
  TECH_SUPPORT: 1,
  SSB: 2,
  SB: 3,
  MO: 4,
  PREPARER: 5,
  OFFICE: 6,
  TEXTLINK: 7,
};

export const MESSAGE_TYPE = {
  SENT: 1,
  RECEIVED: 2,
};

export const MESSAGE_TYPE_ID = {
  TECH_SUPPORT: 1,
  INDIVIDUAL: 5,
  OFFICE: 6,
  TEXTLINK: 7,
};

// Commonly used form names
export const FORM_NAMES = {
  CDS: '000A00',
  RFD_CALC_START: 'ZZ0101',
  EST: 'USTE01',
};

// Forms That can not be Manually Deleted by user
export const IRREMOVABLE_FORMS = ['US0A01', 'US0B01', 'US0C01'];

// Fields that should always require double entry regardless office setting
export const PERMANENT_DOUBLE_ENTRY_FIELDS = ['bdan00', 'PTAN00'];

export const SortOrder = Object.freeze({
  DEFAULT: Symbol('nosort'),
  ASC: Symbol('ascsort'),
  DESC: Symbol('descsort'),
});

export const SIGNEE_TYPE = {
  TAXPAYER: '1',
  SPOUSE: '2',
  PREPARER: '3',
  ERO: '4',
  TAXPAYER_INITIAL: '5',
  SPOUSE_INITIAL: '6',
  OFFICER: '7',
  TAXPAYER_SECOND_SIGNATURE: '8',
  SPOUSE_SECOND_SIGNATURE: '9',
  AMENDED_PAID_PREPARER_SIGNATURE: 'A',
};

export const SIGNEE_CATEGORY_TYPE = {
  TAXPAYER: 1,
  SPOUSE: 2,
  PREPARER: 3,
  ERO: 4,
  TAXPAYER_INITIAL: 5,
  SPOUSE_INITIAL: 6,
  OFFICER: 7,
  TAXPAYER_SECOND_SIGNATURE: 8,
  SPOUSE_SECOND_SIGNATURE: 9,
  AMENDED_PAID_PREPARER_SIGNATURE: 10,
};

export const SIGNEE_TOOLBAR_TITLE = {
  TAXPAYER: 'Capture Taxpayer Signature',
  SPOUSE: 'Capture Spouse Signature',
  PREPARER: 'Capture Paid Preparer Signature',
  ERO: 'Capture ERO Signature',
  TAXPAYER_INITIAL: 'Capture Taxpayer Initial',
  SPOUSE_INITIAL: 'Capture Spouse Initial',
  OFFICER: 'Capture Officer Signature',
  TAXPAYER_SECOND_SIGNATURE: 'Capture Taxpayer Bank Document Signature',
  SPOUSE_SECOND_SIGNATURE: 'Capture Spouse Bank Document Signature',
  AMENDED_PAID_PREPARER_SIGNATURE: 'Capture Amended Paid Preparer Signature',
  DEFAULT: 'Capture Signature',
};

export const PRINT_ACTION_TYPES = {
  PRINT_ACTION: '1',
  SIGN_DOC_ACTION: '2',
  REMOTE_SIGN_ACTION: '3',
};

export const NOTIFICATION_TYPE = {
  DEBUG: 0,
  SUCCESS: 1,
  WARNING: 2,
  CRITICAL: 3,
  MESSAGE: 4,
};

export const TITLEBAR_TAB_ADMIN = {
  OVERVIEW: 0,
  TAX_RETURNS: 1,
  TAX_RETURN_PROFILE: 1,
  TAX_RETURN: 1,
  OFFICES: 2,
};

export const TITLEBAR_TAB_SSB = {
  OVERVIEW: 0,
  ACCOUNTS: 1,
  OFFICES: 2,
  TAX_RETURNS: 3,
  TAX_RETURN_PROFILE: 3,
  TAX_RETURN: 3,
  CALENDAR: 0,
  SECURE_MESSAGES: 0,
};

export const TITLEBAR_TAB_SB = {
  OVERVIEW: 0,
  ACCOUNTS: 1,
  OFFICES: 2,
  TAX_RETURNS: 3,
  TAX_RETURN_PROFILE: 3,
  TAX_RETURN: 3,
  CALENDAR: 0,
  SECURE_MESSAGES: 0,
};

export const TITLEBAR_TAB_FRANCHISE = {
  OVERVIEW: 0,
  OFFICES: 1,
  TAX_RETURNS: 2,
  TAX_RETURN_PROFILE: 2,
  TAX_RETURN: 2,
};

export const TITLEBAR_TAB_EFIN = {
  OVERVIEW: 0,
  PREPARERS: 1,
  TAX_RETURNS: 2,
  TAX_RETURN_PROFILE: 2,
  TAX_RETURN: 2,
  READY_TO_PRINT: 0,
  CHECK_REGISTER: 1,
  CUSTOM_REPORTS: 1,
  PAYMENTS: 0,
};

export const SNACKBAR_SEVERITY = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
};
export const NO_MULTI_OFFICE_ACCESS = 18;

export const LICENSE_ACCESS_ROLES = ['Reseller Admin', 'Reseller Tech', 'Franchise Main'];
export const BUSINESS_VIEW_ROLES = ['Office Owner']; // for now only office owner is allowed to create/view business returns/settings

export const TITLEBAR_TAB_PREPARER = {
  OVERVIEW: 0,
  TAX_RETURNS: 1,
  TAX_RETURN_PROFILE: 1,
  TAX_RETURN: 1,
  CALENDAR: 0,
  SECURE_MESSAGES: 0,
  READY_TO_PRINT: 0,
  CHECK_REGISTER: 1,
  CHECKS: 0,
  REFUND_CALCULATOR: 0,
  PAYMENTS: 0,
};

export const SIDEBAR_TAB = {
  DASHBOARD: 0,
  PREPARERS: 1,
  ACCOUNTS: 2,
  OFFICES: 3,
  TAX_RETURNS: 4,
  SERVER: 5,
  PACKAGES: 6,
  CALENDAR: 7,
  SECURE_MESSAGES: 8,
  USER_LOOKUP: 9,
  CHECKS: 10,
  REFUND_CALCULATOR: 11,
  REPORTING: 12,
  PAYMENTS: 13,
  MOVE_RETURN: 14,
  TECH_LOGINS: 15,
  USER_PROFILE: 16,
};

export const SIDEBAR_TAB_SUB_MENU = {
  10: ['Ready to Print', 'Checks Register'],
  12: ['Quick Reports', 'Custom Reports'],
};

export const SELECTED_PATHNAME = {
  '/dashboard': 0,
  '/preparers': 1,
  '/accounts': 2,
  '/offices': 3,
  '/tax-returns': 4,
  '/tax-return-profile': 4,
  '/server': 5,
  '/state-packages': 6,
  '/calendar': 7,
  '/secure-messages': 8,
  '/user-lookup': 9,
  '/checks/ready-to-print': 10,
  '/checks/checks-register': 10,
  '/refund-calculator': 11,
  '/reporting/quick-reports': 12,
  '/reporting/custom-reports': 12,
  '/payments': 13,
  '/move-return': 14,
  '/tech-logins': 15,
};

export const SIDEBAR_PATHNAME = {
  0: '/dashboard',
  1: '/preparers',
  2: '/accounts',
  3: '/offices',
  4: '/tax-returns',
  5: '/server',
  6: '/state-packages',
  7: '/calendar',
  8: '/secure-messages',
  9: '/user-lookup',
  10: '/checks/ready-to-print',
  11: '/refund-calculator',
  12: '/reporting/quick-reports',
  13: '/payments',
  14: '/move-return',
  15: '/tech-logins',
};

export const SIDEBAR_PATHNAME_SUB_MENU = {
  'Ready to Print': '/checks/ready-to-print',
  'Checks Register': '/checks/checks-register',
  'Quick Reports': '/reporting/quick-reports',
  'Custom Reports': '/reporting/custom-reports',
};

export const DD_TOKEN = 'DDTOKEN';
export const ACCESS_TOKEN = 'access_token';
export const REFRESH_TOKEN = 'refresh_token';

export const STATE_LIST_ADDRESS = [
  'AL',
  'AK',
  'AR',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
];

export const STATE_LIST = [
  'AL',
  'AK',
  'AR',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
];

export const CELL_PHONE_CARRIERS = [
  'ACS ALASKA',
  'ACS WIRELESS',
  'AIO WIRELESS',
  'ALLTEL',
  'APPALACHIAN WIRELESS',
  'AT&T',
  'BELLSOUTH',
  'BELLSOUTH MOBILITY',
  'BOOST MOBILE',
  'CAROLINA WEST',
  'CBEYOND',
  'CELLULAR ONE',
  'CELLULAR ONE TEXAS',
  'CELLULAR SOUTH',
  'CINCINNATI BELL',
  'CLEARTALK',
  'CRICKET',
  'CSPIRE',
  'EDGE',
  'GCI',
  'GOOGLE VOICE',
  'GO SMART MOBILE',
  'H2O WIRELESS',
  'INLAND',
  'LYCAMOBILE',
  'METRO PCS',
  'MINT MOBILE',
  'MOBILEONE',
  'MOBILE NATION',
  'MOBI PCS',
  'NEXTEL',
  'PAGE PLUS CELLULAR',
  'POCKET',
  'GOOGLE FI',
  'QWEST MOBILE',
  'REPUBLIC WIRELESS',
  'SIMPLE MOBILE',
  'SPRINT PCS',
  'STRAIGHTTALK',
  'SUREWEST COMM',
  'T-MOBILE',
  'THUMB',
  'ULTRA MOBILE',
  'US CELLULAR',
  'VERIZON',
  'VIRGIN MOBILE',
  'WEST CENTRAL WIRELESS',
  'XFINITY',
];

export const CALC_RESPONSE_CODE = {
  RETURN_INIT: 'return_init',
  FORM_LOAD: 'form_load',
  UPDATE_FORM: 'update_form',
  RETURN_BUTTONPRESS: 'return_buttonpress',
};

export const NEW_PREPARER_TYPE = [
  'CERTIFIED PUBLIC ACCOUNTANT',
  'ENROLLED AGENT',
  'TAX ATTORNEY',
  'REGISTERED TAX RETURN PREPARER',
];

export const LOGO_TYPE = {
  ACCOUNT: 'Account',
  OFFICE: 'Office',
  MOBILE: 'Mobile Site',
};

export const MAX_IMAGE_FILE_SIZE_MB = 1;

export const VERIFY_MODAL_TABS = {
  IMPORT: 0,
  VERIFY: 1,
  REJECTS: 2,
  BANK_REJECTS: 3,
  ALERTS: 4,
};

// Email validation taken from https://www.w3schools.com/tags/att_input_pattern.asp
export const VALID_EMAIL_REGEX = /^[A-Za-z0-9._%!#$%&'*+-/=?^_`{}|~()]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
export const ASSET_FORM_REG_EXP = /^[A]{1}[0-9]{5}/;
export const TABLE_REG_EXP = /^[A-Za-z]{2}[0-9]{2}/; // check if field is part of a table
export const VALID_PHONE_NUMBER_REGEX = /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s-]?[\0-9]{3}[\s-]?[0-9]{4}$/;
export const VALID_LOGINID_REGEX = /^[a-zA-Z0-9]+$/;
export const RESTRICTED_FIELD_REG_EXP = /^[a-zA-Z0]{0,2}[0-9aA]{0,2}[a-zA-Z0-9]{0,4}$/;

// Using constants to pass correct validation "objKey" on GenBillingSetup.jsx handleObjectChange function
export const ADDRESS_DATA_STRINGS = {
  FIRM_NAME_STRING: 'firmName',
  FIRM_ADDRESS_STRING: 'firmAddress',
  FIRM_EFIN: 'firmEfin',
  SUITE_NUM_STRING: 'suiteNum',
  CITY_STRING: 'city',
  OVERRIDE_CITY_STRING: 'overridecity',
  STATE_STRING: 'state',
  ZIP_STRING: 'zip',
  PHONE_STRING: 'phone',
  LOGIN_ID_STRING: 'loginID',
  PREPARER_SHORTCUT_ID_STRING: 'preparerShortcutID',
  LOGIN_TYPE_STRING: 'loginType',
  STATE_SELECTED_STRING: 'stateSelected',
  LOGIN_STATUS_STRING: 'loginStatus',
};
export const RAC_BANK = {
  0: { code: '', name: 'N/A' },
  1: { code: 'R', name: 'Republic Bank' },
  2: { code: 'S', name: 'SBTPG' },
  3: { code: 'F', name: 'Refundo' },
  4: { code: 'V', name: 'Refund Advantage' },
  5: { code: 'W', name: 'SBTPG World Finance' },
  6: { code: 'X', name: 'Non-BP' },
  NOT_APPLICABLE: 0,
  REPUBLIC_BANK: 1,
  SBTPG: 2,
  REFUNDO: 3,
  REFUND_ADVANTAGE: 4,
  SBTPG_WORLD_FINANCE: 5,
  NON_BP: 6,
  A: { key: 'A', name: 'Approved' },
  B: { key: 'B', name: 'Bank Print' },
  C: { key: 'C', name: 'Cancelled' },
  D: { key: 'D', name: 'Declined' },
  R: { key: 'R', name: 'Reject' },
  P: { key: 'P', name: 'Pending' },
  T: { key: 'T', name: 'Total Returns' },
};
export const DOWNLOAD_DRIVER_LINKS = {
  SCRIPTEL_32_OLD:
    'https://portal-static-file-drive.s3.us-west-2.amazonaws.com/software/omniscript-windows-x32-1.0.7456.exe',
  SCRIPTEL_32_NEW: 'https://services.scriptel.com/releases/omniscript/omniscript-windows-x32',
  SCRIPTEL_64:
    'https://portal-static-file-drive.s3.us-west-2.amazonaws.com/software/omniscript-windows-x64-1.0.7456.exe',
  SCRIPTEL_LINUX:
    'https://portal-static-file-drive.s3.us-west-2.amazonaws.com/software/omniscript-debian-linux-amd64-1.0.7456.deb',
  TOPAZ: 'https://portal-static-file-drive.s3.us-west-2.amazonaws.com/software/sigweb.exe',
};

// URLS that lead to external sites
export const EXTERNAL_URLS = {
  PRIVACY_POLICY:
    '//www.crosslinktax.com/customer-resources/documents/Privacy-Policy-Updated-September-2020.pdf',
};

export const STEP_LOOKUP_TYPE = {
  NORMAL: 1,
  DYNAMIC: 2,
  PAGE_TRANSITION: 3,
  DONE: 4,
  START: 5,
  SKIP: 6,
};

export const STEP_TRIGGER_TYPE = {
  NEXT: 1,
  CLICK: 2,
  BLUR: 3,
  NORMAL_FLOW: 4,
  DONE: 5,
};

// NAME - Friendly Return Status Description
// STATUS - Return Status Lookkup ID
export const DOUGHNUT_RETURN_STATUS = {
  Accepted: { STATUS: '3', NAME: 'Accepted' },
  PendingAtIRS: { STATUS: '22', NAME: 'Pending - At IRS' },
  PendingReceived: { STATUS: '20', NAME: 'Pending - Received' },
  Rejected: { STATUS: '15', NAME: 'Rejected - Fed' },
  StReject: { STATUS: '10', NAME: 'Rejected - State' },
  Refused: { STATUS: '7', NAME: 'Refused' },
  Queued: { STATUS: '14', NAME: 'Queued' },
};

export const EPHEMERAL_LIST_FILTERS = {
  9: 'rac_status',
};

export const ACCESS_LEVEL = {
  ACCESS_REPORTS: 'access_reports',
  ACCESS_SECURE_MESSAGES: 'access_secure_messages',
  ACCESS_TRAINING_RETURNS: 'access_training_returns',
  ACCESS_WALLET: 'access_wallet',
  ADD_CLIENT_PROFILE: 'add_client_profile',
  ADD_EDIT_BILLING: 'add/edit_billing',
  ADD_EDIT_PREPARER: 'add/edit_preparer',
  ADD_EFIN: 'add_efin',
  ADD_FRANCHISE: 'add_franchise',
  ADD_OFFICE: 'add_office',
  ADD_REFUND_CALCULATOR: 'add_refund_calculator',
  ADD_RETURN: 'add_return',
  CREATE_BUSINESS_RETURN: 'create_business_return',
  CHECK_ACTIVATION: 'check_activation',
  COPY_RETURN: 'copy_return',
  CREATE_EFIN: 'create_efin',
  CREATE_EXTENDED_REPORTS: 'create_extended_reports',
  CREATE_LOGIN: 'create_login',
  CREATE_RESELLER: 'create_reseller',
  CREATE_SB: 'create_sb',
  CREATE_RESTRICTED_FIELDS: 'create/edit_restricted_fields',
  DELETE_FROM_DOCUMENT_ARCHIVE: 'delete_document_from_archive',
  DELETE_RETURN: 'delete_return',
  EDIT_CLIENT_LETTER: 'edit_client_letter',
  EDIT_DATABASES: 'edit_databases',
  EDIT_DEFAULTS_CONFIG: 'edit_defaults_config',
  EDIT_DUE_DILIGENCE: 'edit_due_diligence',
  EDIT_ESTIMATOR: 'edit_estimator',
  EDIT_LOGIN: 'edit_login',
  EDIT_OFFICE_SETUP: 'edit_office_setup',
  EDIT_PAYJUNCTION_API_CREDENTIALS: 'edit_payjunction_api_credentials',
  EDIT_PRINT_CONFIG: 'edit_print_config',
  EDIT_RESELLER_DETAILS: 'edit_reseller_details',
  EDIT_SB_DETAILS: 'edit_sb_details',
  FETCH_RETURN: 'fetch_return',
  IMPERSONATE_LOGIN: 'impersonate_login',
  MANAGE_SB: 'manage_sb',
  MANAGE_SSB: 'manage_ssb',
  MANAGE_TECH_TEMP_LOGIN: 'manage_tech_temp_login',
  MOBILE_SITE_RETRIEVAL: 'mobile_site_retrieval',
  MODIFY_INVOICE: 'modify_invoice',
  MODIFY_PREPARER: 'modify_preparer',
  MODIFY_PRIMARY_SSN: 'modify_primary_ssn',
  MOVE_EFIN: 'move_efin',
  MOVE_RETURN: 'move_return',
  OPEN_ANY_1040_RETURN: 'open_any_1040_return',
  PASS_THROUGH_WITH_LOGIN: 'pass_through_with_login',
  PAYMENT_TRACKING: 'payment_tracking',
  PREVIEW_RETURN: 'preview_return',
  PRINT_BANK_DISCLOSURE: 'print_bank_disclosure',
  PRINT_CHECKS: 'print_checks',
  PRINT_ESTIMATOR: 'print_estimator',
  PRINT_RALRAC_FORM: 'print_ral/rac_form',
  PRINT_REPORTS: 'print_reports',
  PRINT_RETURN: 'print_return',
  PRINT_RETURN_COMPONENTS: 'print_return_components',
  RETURN_LIST: 'get_return_list',
  SEND_PASSWORD_RESET: 'send_password_reset',
  SEND_TEXT_MESSAGE: 'send_text_message',
  TRANSFER_RETURN: 'transfer_return',
  TRANSMIT_RETURN: 'transmit_return',
  UNLOCK_ACCOUNT: 'unlock_account',
  UNLOCK_LOGIN: 'unlock_login',
  USER_LIST: 'user_list',
  VIEW_BILLING: 'view_billing',
  VIEW_BUSINESS_RETURN: 'view_business_return',
  VIEW_CHAT: 'access_chat',
  VIEW_CHECKS: 'view_checks',
  VIEW_CLIENT_PROFILE: 'view_client_profile',
  VIEW_CREATE_APPOINTMENTS: 'view/create_appointments',
  VIEW_DATABASES: 'view_databases',
  VIEW_EFIN_MOVE_HISTORY: 'view_efin_move_history',
  VIEW_OFFICE_SETUP: 'view_office_setup',
  VIEW_REPORTS: 'view_reports',
  VIEW_WIDGETS: 'view_widgets',
  WIDGET_MANAGEMENT_CLIENT_PROFILE: 'widget_management_client_profile',
  WIDGET_MANAGEMENT_EFIN: 'widget_management_efin',
  WIDGET_MANAGEMENT_LOGIN: 'widget_management_login',
  WIDGET_MANAGEMENT_SB: 'widget_management_sb',
  WIDGET_MANAGEMENT_SSB: 'widget_management_ssb',
};

export const PRINT_TYPES = {
  CURRENT_FORM: '01',
  RETURN_COMPONENTS: '02',
  FINAL_RETURN: '03',
  BANK_DOCUMENTS: '04',
  DISCLOSURE: '05',
  ORGANIZER: '06',
  EF_DOCUMENTS: '07',
  STMT199A: '08',
  ACK_LETTER: '09',
  STATE_ACK_LETTER: '10',
  CLIENT_LETTER: '11',
  PAYMENT_VOUCHER: '12',
  EXT_REQUEST: '13',
  EXT_ACK_LETTER: '14',
  REF_COUPONS: '15',
  ATTACHED_FORMS: '16',
  REMOTE_SIG: '17',
  AMENDED_RETURN: '18',
  PREVIEW_RETURN: '19',
  PAPER_FILE_RT: '20',
  PREVIEW_1040: '21',
  AMENDED_ACK_LETTER: '22',
  CORP_SCHEDULE_K1S: '23',
  RRB_1042S_SIGN_DOC: '24',
  FED_CLIENT_LETTER: 'FEDERAL',
  STATE_CLIENT_LETTER: 'STATE',
};

export const FORM_TYPE_VAR = {
  INTERVIEW_MODE: 'retn37',
  VERIFY_MODAL: 'retn04',
};

export const PRINT_TYPES_MAP = {
  '01': 'Current Form',
  '02': 'Return Components',
  '03': 'Final Return',
  // eslint-disable-next-line no-dupe-keys
  '03': 'Final Tax Return', // We need this duplicate key
  '04': 'Bank Documents',
  '05': 'Disclosure',
  '06': 'Organizer',
  '07': 'EF Documents',
  '08': 'STMT 199A',
  '09': 'Ack Letter',
  10: 'State Ack Letter',
  11: 'Client Letter',
  12: 'Payment Voucher',
  13: 'Extension Request',
  14: 'Extension Ack Letter',
  15: 'Referral Coupons',
  16: 'Attached Forms List',
  17: 'Remote Signature',
  18: 'Amended Return',
  19: 'Return Preview',
  20: 'Paper File RT Return',
  21: 'Preview Form 1040',
  22: 'Amended Ack Letter',
  23: 'Schedule K-1s',
  FEDERAL: 'Federal Client Letter',
  STATE: 'State Client Letter',
};

export const PRINT_PREFS = {
  // Print Final Preferences also used for Print Preview
  FINAL: [
    'INVD',
    'IDUE',
    'WLOK',
    'EXPF',
    'CLTR',
    'FPDT',
    'CDS1',
    'BDPO',
    'LPAB',
    'LSRV',
    'LPA2',
    'LPCO',
    'LPA3',
    'INV2',
    'INVL',
    'ITMZ',
    'LPTA',
    'LPSA',
    'LPAC',
    'LPBC',
    'LPA1',
    'LPC1',
    'LTCA',
    'LTSA',
    'LBE1',
    'LBF1',
    'LPA0',
    'LPB0',
    'LPA5',
    'LPA6',
    'LPA4',
    'LPA7',
    'LPE7',
    'LPSC',
    'MITS',
    'LPBB',
    'LPCP',
    'LPRB',
    'LPB3',
    'LPTB',
    'LPSB',
    'LPB1',
    'LPD1',
    'LTCB',
    'LTSB',
    'LBE2',
    'LBF2',
    'LPB5',
    'LPWB',
    'LPB6',
    'LPB4',
    'LPB7',
    'LPF7',
    'WFIN',
    'LPC7',
    'LPD7',
    'LPBA',
  ],
  BANK: 'LBAB',
  DISCLOSURE: 'PRVX',
  ORGANIZER: 'LSRQ',
  EF: ['INEF', 'INV2', /* "INVL", */ 'ITMZ'], // TODO: MISING INVL (Display Invoice Short Form)
  CORP_FINAL: [
    'CCD1',
    'CPAC',
    'CPBC',
    'CPA1',
    'CPB1',
    'CPC1',
    'CPD1',
    'CPK1',
    'CPK2',
    'CPA2',
    'CPA3',
    'CPB3',
    'CPA4',
    'CPB4',
    'CPA5',
    'CPB5',
    'CPA6',
    'CPB6',
    'CPA7',
    'CPB7',
    'CPC7',
    'CPD7',
    'CPA8',
    'CPB8',
    'CPC8',
    'CPD8',
    'CMDA',
    'CMDB',
    'CPTA',
    'CPTB',
    'CPWB',
    'CPA9',
    'CPB9',
    'CPC9',
    'CPD9',
    'CPE9',
    'CPBA',
    'CPCA',
    'CPDA',
    'CPEA',
    'CPAM',
    'CPBM',
    'CPCM',
    'CPDM',
    'CPEM',
  ],
};

export const ENCRYPTED_PDF_ERROR_MESSAGES = {
  MISSING_TAXPAYER_INFO_EMAIL:
    'Make Sure the Taxpayer Email Address and/or Cell Phone Number and Carrier was Entered Correctly on the Client Data Screen',
  MISSING_SPOUSE_INFO_EMAIL:
    'Make Sure the Spouse Email Address and/or Cell Phone Number and Carrier was Entered Correctly on the Client Data Screen',
};

export const TECH_SUPPORT_PHONE_NUMBER = '(833) 783-4253';

export const TEXTLINK_ERROR_MESSAGE = `If this error continues to occur, please contact Technical Support at ${TECH_SUPPORT_PHONE_NUMBER}.`;

export const SIGNATURE_LIST_TABLE_COLUMNS = [
  'DOCUMENT',
  'REQUESTED TIME',
  'SIGNEE',
  'STATUS',
  'ACTION',
];

// Access levels that require a Preparer be Linked to the Login with the Access Role using one of these access levels.
export const REQUIRED_LINKED_PREP_ACCESS_LEVELS = [
  36, // Edit Return
  41, // Delete Return
  53, // Create Return
];

export const SIGNEE = {
  TAXPAYER: 'taxpayer',
  SPOUSE: 'spouse',
  TAXPAYER_SECOND: 'taxpayer second',
  SPOUSE_SECOND: 'spouse second',
  PREPARER: 'preparer',
  AMENDED_PREPARER: 'amended preparer',
  OFFICER: 'officer',
};

export const SIGNATURE_CATEGORY = {
  TAXPAYER: '1',
  SPOUSE: '2',
  PREPARER: '3',
  OFFICER: '7',
  AMENDED_PREPARER: '9',
};

// Used for remote document request types.
export const REMOTE_DOCUMENT_REQUESTEE_TYPE = {
  TAXPAYER: 1,
  SPOUSE: 2,
  BUSINESS: 3,
};

// Use the value generated above to find friendly name
export const REMOTE_DOCUMENT_REQUESTEE_NAME = ['Taxpayer', 'Spouse', 'Business'];

export const DOCUMENT_TYPES = {
  B64JPEG: 'data:image/jpeg;base64',
  B64JPG: 'data:image/jpg;base64',
  B64PDF: 'data:application/pdf;base64',
  B64PNG: 'data:image/png;base64',
  GIF: 'image/gif',
  JPEG: 'image/jpeg',
  JPG: 'image/jpg',
  PDF: 'application/pdf',
  PNG: 'image/png',
};

export const DOCUMENT_TYPE_VALUES = [
  'data:image/jpeg;base64',
  'data:image/jpg;base64',
  'data:application/pdf;base64',
  'data:image/png;base64',
  'image/gif',
  'image/jpeg',
  'image/jpg',
  'application/pdf',
  'image/png',
];

export const REQUEST_PDF_MSG =
  'If the intent of generating this PDF is for use with a third party remote signature service, only your software provider’s remote signature service is allowed and use of any other would be a violation of the user terms and conditions. Generated PDFs are logged and tracked by your software provider.';

export const EXCLUDE_FLEX = ['/tax-return', '/create-new-account', '/wizard-mode'];

// User login ID's that are given top level bypassing
export const TOP_ACCESS_LOGINS = [5144, 6455, 17717];

// FormViewer fields that are preset ie - Telephone, SSN, EIN, Zip, Dates - become these when trimmed
export const PRESET_FORMATTING_TRIMMED = ['-   -', '/  /', '-    -', '-  -', '-', '/', ':'];

export const PRESET_PERCENT_RATIO = ['   .', '  .', '.'];

// Field types that include decimals
export const DECIMAL_FIELDS = ['F', 'R', 'P', 'N', 'L'];

// Field types in which special characters are removed when editting field on formviewer
export const REMOVE_FORMATTING_FIELDS = ['D', 'E', 'M', 'S', 'T', 'Y', 'Z'];

// these account wish to hide the crosslink KB URL
// TODO: Look into adding custom KB URLs
export const CUSTOM_KB_ACCOUNTS = [
  'RIVEDG',
  'WILMON00',
  'RIVEDG27',
  'RIVEDG29',
  'RIVEDG42',
  'RIVEDG62',
  'RIVEDG85',
];

export const WIDGET_FEES_TOOLTIP_ACCOUNT = 'Display all fees paid to this EFIN belonging to you';
export const WIDGET_FEES_TOOLTIP_ACCOUNT_ALL =
  "Display all fees paid to the EFIN's belonging to you";
export const WIDGET_FEES_TOOLTIP_EFIN = 'Display all fees paid to this EFIN';
export const WIDGET_FEES_TOOLTIP_PREP = 'Display all fees paid to you';
export const WIDGET_FEES_DEFAULT_ACCOUNT_ALL = "All Accounts and Office's Collected Fees";

// https://fmx.cpa.texas.gov/fm/pubs/payment/payee_setup/index.php?s=validation&p=validation
export const INVALID_EIN_PREFIX = [
  '07',
  '08',
  '09',
  '17',
  '18',
  '19',
  '28',
  '29',
  '49',
  '69',
  '70',
  '78',
  '79',
  '89',
  '96',
  '97',
];

// Test for only number inputs
export const REGEX_NUMBERS_ONLY = /^\d+$/;

// Entity Types for business
export const EIN_ENTITY_TYPES = {
  1: '1065',
  2: '1120S',
  3: '1120',
  4: '1041',
  5: '990',
};

export const INDIVIDUAL_RETURN_TAB = 0; // initial
export const BUSINESS_RETURN_TAB = 1;

export const AVAILABLE_BUSINESS_ENTITIES = ['1065', '1120', '1120S', '1041', '990'];

// Billing Schemas
export const BILLING_SCHEMA = {
  INDIVIDUAL: 1,
  BUSINESS: 2,
};

export const COMMON_PAGINATION_PROPS = {
  initialPage: 0,
  marginPagesDisplayed: 2,
  pageRangeDisplayed: 3,
  previousLabel: '<',
  nextLabel: '>',
  breakLabel: '...',
  breakClassName: 'paginate-break',
  pageClassName: 'paginate-page',
  activeClassName: 'paginate-activepage',
  previousClassName: 'paginate-prev',
  nextClassName: 'paginate-next',
  disabledClassName: 'paginate-disabled',
  containerClassName: 'pagination',
  subContainerClassName: 'pages pagination',
};

// message objects to map out
export const REMOTE_SIGN_MESSAGES = {
  expired: [
    'This email request has expired.',
    'Please contact your supervisor to re-send the request.',
    'Please close this window.',
  ],
  alreadyCompleted: [
    'This remote signature request has already been completed.',
    'Please re-send the email if you would like to sign again.',
    'Please close this window.',
  ],
  isNotCapturing: [
    'Thank you for validating.',
    'Your existing signature will remain as is.',
    'Please close this window.',
  ],
  successSigned: [
    'Thank you for signing',
    'Your associated offices now have access to your signature.',
    'Please close this window.',
  ],
};

export const PAYJUNCTION_TERMINAL_STATUSES = {
  NO_CONNECTION: 'NO_CONNECTION',
  CONNECTING: 'CONNECTING',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETE: 'COMPLETE',
  DECLINED: 'DECLINED',
  BUSY: 'BUSY',
};

export const PAYMENT_HISTORY_COL_LABELS = [
  'Received',
  'Method',
  'Amount',
  'Received From',
  'Reference #',
  'Received By',
];

export const ERROR_IN_DOCUMENT_CANCEL_MESSAGE =
  'If you believe there are errors in the tax return preview, please contact your tax preparer.';
export const ELECTRONIC_DISCLOSURE_CANCEL_MESSAGE =
  'If you would like to sign the paper copies of these documents, please contact your tax preparer.';
export const ELECTRONIC_DISCLOSURE_TITLE = 'Disclosure Regarding Signing Documents Electronically';
export const ELECTRONIC_DISCLOSURE =
  'Please read the following information, by proceeding forward and signing these documents you are agreeing that you have reviewed the following disclosure information and consent to utilize electronic signatures in lieu of using paper documents.';
export const PAPER_COPIES =
  'You are not required to sign documents electronically. If you prefer not to do so, you may request to sign paper copies and withdraw your consent.';
export const SCOPE_OF_CONSENT =
  'You agree to receive electronic documents and sign these documents electronically.';

export const DFLT = 'EMPLOYER FLAT';
export const DFL2 = 'SINGLE FILER FLAT';

export const TEN_FOURTY_CONVERSION_PREFIX = 500000000000;
export const CORP_CONVERSION_PREFIX = 510000000000;

export const ACCOUNTSEARCH = 'Search by Account Name';
export const OFFICESEARCH = 'Search by Office Name';
export const PREPARERSEARCH = 'Search by Preparer Name';
export const RETURNSEARCH = 'Search by Last Name or Last 4 of SSN';
export const CORPRETURNSEARCH = 'Search by Business or Last 4 of EIN';
export const EFINSEARCH = 'Search by EFIN';

export const FINAL_TAX_RETURN_TYPES = {
  'Remote Signature': 'Pending Remote Signature',
  'Complete Remote Signature': 'Completed Remote Signature',
  'E-Signature': 'In-Office Signed Final Tax Return',
  'Copy of Printed PDF': 'Copy of Printed Final Tax Return',
  'Copy of Emailed Encrypted PDF': 'Copy of Emailed Encrypted Final Tax Return',
};

// Array containing list order characters for occurances past 99 to 9@, 9A, 9B, ect
// Static order, DO NOT CHANGE
export const OCCURANCE_CHART = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '@',
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  '`',
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
];

// List of 1040 prefs that apply to print return components
export const PRINT_COMPONENT_PREFS_1040 = [
  'LPSP',
  'LPA2',
  'LPA3',
  'LPA1',
  'LPC1',
  'LTCA',
  'LTSA',
  'LPA0',
  'LBAA',
  'LBE1',
  'LBF1',
  'LPA5',
  'LPA6',
  'LPC2',
  'LPA4',
  'LPA7',
  'LPE7',
  'LPTA',
  'LPCO',
];

// List of corp prefs that apply to print return components
export const PRINT_COMPONENT_PREFS_CORP = [
  'CPSP', // Print Header
  'CPA2', // Diagnostics
  'CPA3', // Client Invoice
  'CPA1', // Fed Client Letter
  'CPC1', // State CLient Letter
  'CPA0', // 8879 Forms
  'CPA5', // Federal Returns
  'CPA6', // State Return(s)
  'CPC2', // City Return(s)
  'CPA7', // Asset Lists
  'CPTA', // Privacy Letter
];

// using an object incase we want to expand upon the values and for easier access to a specific type
export const PAYMENT_TYPES = {
  CHECK: 'CHECK',
  CASH: 'CASH',
  CREDIT_CARD: 'CREDIT CARD',
  DEBIT_CARD: 'DEBIT CARD',
  CASH_APP: 'CASH APP',
  OTHER: 'OTHER',
};

export const DEFAULT_MAINTENANCE_MODE_TITLE = 'Scheduled Maintenance';
export const DEFAULT_MAINTENANCE_MODE_BODY =
  'A release is scheduled for 9pm PST and is expected to last until 10:30pm PST. The application will be disabled during this time.';
