// External imports
import React from 'react';
import { withStyles } from '@material-ui/core';
// Internal imports
import { ACCOUNT_TYPES } from '~/app/constants.js';
import AccountTypeCard from './cards/AccountTypeCard.jsx';
import multiOffice from '~/images/multiOffice.png';
import resellerAccount from '~/images/resellerAccount.png';
// Redux imports
import { useDispatch, useSelector } from 'react-redux';
import { actions as accountCreationActions } from '../../../redux/modules/accountCreation';
// Styles imports
import { styles } from '../css/accountTypeSelectionStyles';

/**
 * This Component containers step one of creating an account, depending on the current account type
 * -of the user, this will display different ui cards for the user to be able to select
 * -which Account Type to create.
 *
 * @component AccountTypeSelection
 * @category CreateAccount
 **/
const AccountTypeSelection = props => {
  const { classes } = props;
  const dispatch = useDispatch();
  const { createAccountState } = useSelector(state => ({
    createAccountState: state.accountCreation,
  }));

  /**
   * This handles dispatching redux action to set the account type.
   *
   * @param {number} accountType This number represents the users Account Type
   */
  const setAccountType = accountType => {
    dispatch(accountCreationActions.setCreateAccountType(accountType));
  };

  return (
    <div className="body-content">
      <div className={classes.cardContainer}>
        {/* Displays if acccountType is reseller+  */}
        <AccountTypeCard
          accessLevel="add_franchise"
          setAccountType={setAccountType}
          accountTitle="Multi-Office Account"
          selectedOption={createAccountState.type}
          accountType={ACCOUNT_TYPES.FRANCHISE}
          image={multiOffice}
          authorities={['Add Offices', 'Add Tax Preparers', 'File Taxes', 'Add Managers']}
        />
        {/* Displays if accountType is reseller+  */}
        <AccountTypeCard
          accessLevel="create_reseller"
          setAccountType={setAccountType}
          accountTitle="Reseller Account"
          selectedOption={createAccountState.type}
          accountType={ACCOUNT_TYPES.RESELLER}
          image={resellerAccount}
          authorities={['Add Multi-Office Accounts', 'Add Offices']}
        />
      </div>
    </div>
  );
};

export default withStyles(styles)(AccountTypeSelection);
