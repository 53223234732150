import React, { Component, Fragment } from 'react';
// Internal imports
import SimpleDialog from '#/Common/SimpleDialog.jsx';
import AuthAPI from '~/app/api/authAPI.js';
import ErrorHelpers from '~/app/errorHelpers.js';
import QRModal from '#/Auth/QRModal.jsx';
import GenericPasswordField from '#/Common/GenericPasswordField.jsx';
// External imports
import {
  withStyles,
  Modal,
  Typography,
  Paper,
  FormControl,
  Button,
  AppBar,
  Toolbar,
} from '@material-ui/core';
// Redux imports
import { connect } from 'react-redux';
import { actions as appActions } from '~/app/redux/modules/app';
// Style imports
import { styles } from '~/app/Components/NewAccount/css/activateAccStyles.js';

const mapDispatchToProps = {
  ...appActions,
};

const mapStateToProps = state => {
  return {
    alert: state.app.alert,
    isMaintenanceModeTime: state.maintenance.isMaintenanceModeTime,
  };
};

class ActivateAcc extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.state = {
      password: '',
      confirmNewPassword: '',
      verifyCode: '',
      openQRCodeModal: false, // Need to style QR modal? change this to true temporarily
      qrBase64: '',
      logoFromDB: '',
      openResetLinkModal: false,
      openCheckYourEmailModal: false,
      expired: 0,
      isEmailCodeUpdated: false,
      showUserName: '',
      passwordMeetsRequirements: false,
      retypeRequirementsMet: false,
      windowHeight: '',
      windowWidth: '',
    };
  }

  componentDidMount = () => {
    this.setState({ verifyCode: this.props.match.params.code });
    // Get username
    this.getUsername(this.props.match.params.code);
    // Check if link is expired, if yes, call this.handleRegisterAccount(domEvent, this.state.verifyCode, this.state.password, this.state.confirmNewPassword)}
    this.getLinkExpired(this.props.match.params.code);
    // this.setState({openResetLinkModal:true}) if expired
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  };

  // Adding function that will handle resizing
  handleResize = () => {
    this.setState({
      windowHeight: window.innerHeight - 200,
      windowWidth: window.innerWidth * 0.59,
    });
  };

  getLinkExpired = verifyCode => {
    AuthAPI.checkiflinkexpired(verifyCode)
      .then(res => {
        if (res.data == null) {
          res.data = '';
        }
        const data = res.data;
        if (data === 0) {
          this.setState({ openResetLinkModal: true });
        }
        if (data === 1) {
          this.setState({ openResetLinkModal: false });
        }
      })
      .catch(err => {
        ErrorHelpers.handleError('Error getting link expiration', err);
      });
  };

  getUsername = async verifyCode => {
    try {
      // if the verifycode does not work for username, then that means it has been updated and won't work for tying it to a user
      const res = await AuthAPI.getUsernameFromLink(verifyCode);
      if (res.data !== '') {
        this.setState({ showUserName: res.data });
      } else {
        this.setState({ isEmailCodeUpdated: true });
      }
    } catch (error) {
      this.setState({ isEmailCodeUpdated: true });
      ErrorHelpers.handleError('Error getting username', error);
    }
  };

  // Handle onChange without validation fon input fields
  handleInputChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  closeQRCodeModal = () => {
    this.setState({ openQRCodeModal: false });
  };

  closeResetLinkModal = () => {
    this.setState({ openResetLinkModal: false });
  };

  closeCheckYourEmailModal = () => {
    this.setState({ openCheckYourEmailModal: false });
  };

  setPasswordState = (password, passwordMeetsRequirements) => {
    this.setState({
      password,
      passwordMeetsRequirements,
    });
  };

  setRetypeState = (pw, flag) => {
    this.setState({
      confirmNewPassword: pw,
      retypeRequirementsMet: flag,
    });
  };

  // This handler is disabled if 'getUsername' does not successfully fetch data using the verifyCode onmount
  handleUpdateActivationLink = async verifyCode => {
    // Generate call to api that updates an activation code
    // updateAccountActivationlink
    try {
      const res = await AuthAPI.updateAccountActivationlink(verifyCode);
      if (res.data == null) {
        res.data = '';
      }
      this.setState({
        userEmail: res.data,
        openResetLinkModal: false,
        openCheckYourEmailModal: true,
      });
    } catch (error) {
      ErrorHelpers.handleError('Error generating new activation link', error);
    }
  };

  // Go Back Home. Should go to Login Page since there isn't an actively logged in account.
  // TODO -- Should we log them in after showing them QR code? or should we force them to login using QR code?
  handleContinueToLogin = () => {
    this.closeQRCodeModal();
    this.props.history.push('/'); // Route back to the login page.
  };

  handleContinueMobile = () => {
    this.closeQRCodeModal();
    // Show message stating that the activation process has been completed
    this.props.history.push('/mobactivationdone'); // Route back to close page message.
  };

  handleRegisterAccount = async (domEvent, verifyCode, password, confirmNewPassword) => {
    domEvent.preventDefault();
    // Validate passwords are equals
    if (password !== confirmNewPassword) {
      this.props.showError({
        title: 'Account Registration Error',
        body: 'Passwords Do Not Match',
      });
      return;
    }

    try {
      await AuthAPI.registerAccountPassword(verifyCode, password, confirmNewPassword);

      try {
        const res = await AuthAPI.getQRCode(verifyCode);

        if (res && res.data) {
          await this.setState({
            qrBase64: res.data.qrcode,
            openQRCodeModal: true,
          });
        }
      } catch (error) {
        ErrorHelpers.handleError('Account Registration Error', error);
      }
    } catch (error) {
      ErrorHelpers.handleError('Account Registration Error', error);
    }
  };

  resetActivationLinkModal = verifyCode => {
    const { classes } = this.props;
    const toolbarColor = { backgroundColor: '#444A59' };
    const height = this.state.windowHeight;
    const width = this.state.windowWidth;
    const isMobile = width <= 500; // We have a mobile device
    const orientationPortrait = width < height; // We have a mobile device on portrait mode
    if (isMobile && orientationPortrait) {
      return (
        <div style={styles.mobileMessageContainer}>
          <b>Please Rotate Your Device to Proceed</b>
        </div>
      );
    } else {
      return (
        <div>
          <AppBar position="static">
            <Toolbar style={toolbarColor}>
              <span style={styles.linkStatusText}>
                {this.state.isEmailCodeUpdated
                  ? 'Activation Link Updated'
                  : 'Activation Link Expired'}
              </span>
            </Toolbar>
          </AppBar>
          {this.state.isEmailCodeUpdated ? (
            <>
              <Typography classes={{ root: classes.qrCodeTitle }} variant="title" gutterBottom>
                This Activation Email link has been disabled and may have been updated.
              </Typography>
              <Typography variant="title" gutterBottom classes={{ root: classes.qrCodeBody }}>
                Please verify that you are accessing the correct email, or contact your supervisor
                to verify what email the new Activation Email was sent to.
              </Typography>
            </>
          ) : (
            <>
              <Typography classes={{ root: classes.qrCodeTitle }} variant="title" gutterBottom>
                Your account activation link has expired
              </Typography>
              <Typography variant="title" gutterBottom classes={{ root: classes.qrCodeBody }}>
                Please click on the button below to get a new activation link emailed
              </Typography>
            </>
          )}
          <br />
          <Button
            id="btnhandleUpdateActivationLink"
            variant="contained"
            color="primary"
            onClick={() => this.handleUpdateActivationLink(verifyCode)}
            disabled={this.state.isEmailCodeUpdated || this.props.isMaintenanceModeTime}
          >
            Get New Activation Email
          </Button>
        </div>
      );
    }
  };

  checkYourEmailModal = () => {
    const { classes } = this.props;
    const toolbarColor = { backgroundColor: '#444A59' };
    const height = this.state.windowHeight;
    const width = this.state.windowWidth;
    const isMobile = width <= 500; // We have a mobile device
    const orientationPortrait = width < height; // We have a mobile device on portrait mode
    if (isMobile && orientationPortrait) {
      return (
        <div style={styles.mobileMessageContainer}>
          <b>Please Rotate Your Device to Proceed</b>
        </div>
      );
    } else {
      return (
        <div>
          <AppBar position="static">
            <Toolbar style={toolbarColor}>
              <span style={styles.linkStatusText}>New Activation Link Sent</span>
            </Toolbar>
          </AppBar>
          <Typography classes={{ root: classes.qrCodeTitle }} variant="title" gutterBottom>
            Your account activation link was emailed successfully.
          </Typography>
          <Typography variant="title" gutterBottom classes={{ root: classes.qrCodeBody }}>
            Please check your email for new activation link and close this window.
          </Typography>
          <Typography variant="title" gutterBottom classes={{ root: classes.qrCodeBody }}>
            Thank you!
          </Typography>
          <br />
        </div>
      );
    }
  };

  render() {
    const { classes } = this.props;
    const height = this.state.windowHeight;
    const width = this.state.windowWidth;
    const isMobile = width <= 500; // We have a mobile device
    const orientationPortrait = width < height; // We have a mobile device on portrait mode
    const orientationLandscape = width > height; // We have a mobile device on ladscape mode

    const paperStyle =
      isMobile && orientationLandscape ? styles.paperStyleMobile : styles.paperStyleNonMobile;
    const fontStyle = isMobile && orientationLandscape ? styles.mobileFont : styles.nonMobileFont;
    const fontStyleFirst =
      isMobile && orientationLandscape ? styles.styleMobileOne : styles.styleNonMobileOne;
    const fontStyleSecond =
      isMobile && orientationLandscape ? styles.styleMobileTwo : styles.styleNonMobileTwo;

    if (isMobile && orientationPortrait) {
      return (
        <div style={styles.mobileMessageContainer}>
          <b>Please Rotate Your Device to Proceed</b>
        </div>
      );
    } else {
      return (
        <div className="container-fluid" id="loginContainer">
          <Paper style={paperStyle}>
            <div className="container" style={{ paddingTop: '1em' }}>
              {/* <img src={logoCrosslink} className="logoCrosslink" /> */}
              {/* <img src={finalLogo ? finalLogo : logoplaceholder} className="logoCrosslink" /> */}
            </div>
            <div>
              <form style={styles.formContainer}>
                <div style={fontStyle}>
                  <b>Create a password to activate your account</b>
                </div>
                {this.state.openResetLinkModal === false ? (
                  <div style={fontStyleFirst}>
                    Please note that your <b>username</b> is: <b>{this.state.showUserName}</b>
                  </div>
                ) : (
                  <Fragment />
                )}
                {this.state.openResetLinkModal === false ? (
                  <div style={fontStyleSecond}>
                    You will need your <b>username</b> and <b>password</b> the next time you log
                    into your account.
                  </div>
                ) : (
                  <Fragment />
                )}
                <GenericPasswordField
                  label="New Password"
                  id="txtNewPasswordActivateAcc"
                  name="password"
                  type="password"
                  verticalOffset={isMobile && orientationLandscape ? 0 : 1.5}
                  horizontalOffset={isMobile && orientationLandscape ? -8.5 : -3}
                  checkIfMobile={isMobile}
                  handlePasswordCheck={(password, passwordMeetsRequirements) =>
                    this.setPasswordState(password, passwordMeetsRequirements)
                  }
                />
                <GenericPasswordField
                  label="Confirm New Password"
                  id="txtConfirmNewPasswordActivateAcc"
                  name="confirmNewPassword"
                  type="password"
                  firstvalue={this.state.password}
                  retype={true}
                  verticalOffset={isMobile && orientationLandscape ? -2.3 : -2}
                  horizontalOffset={isMobile && orientationLandscape ? -8.5 : -3}
                  checkIfMobile={isMobile}
                  handlePasswordCheck={(password, passwordMeetsRequirements) =>
                    this.setRetypeState(password, passwordMeetsRequirements)
                  }
                />
                <FormControl fullWidth>
                  <Button
                    disabled={
                      !this.state.passwordMeetsRequirements ||
                      !this.state.retypeRequirementsMet ||
                      this.props.isMaintenanceModeTime
                    }
                    id="btnRegisterAccountActivateAcc"
                    onClick={(domEvent, verifyCode, password, confirmNewPassword) =>
                      this.handleRegisterAccount(
                        domEvent,
                        this.state.verifyCode,
                        this.state.password,
                        this.state.confirmNewPassword,
                      )
                    }
                    variant="contained"
                    color="primary"
                  >
                    Activate Account
                  </Button>
                </FormControl>
              </form>
            </div>
          </Paper>
          <QRModal
            closeQRModal={this.handleContinueToLogin}
            qrcode={this.state.qrBase64}
            openQRCodeModal={this.state.openQRCodeModal}
            isMobileDevice={this.state.windowWidth <= 500}
            orientationLandscapeSelected={this.state.windowWidth > this.state.windowHeight}
            closeQRModalMobile={this.handleContinueMobile}
          />

          <Modal
            id="linkResetModal"
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.openResetLinkModal}
            onClose={this.closeResetLinkModal}
            disableBackdropClick={true}
          >
            <Paper elevation={5} classes={{ root: classes.resetCodeModal }}>
              {this.resetActivationLinkModal(this.state.verifyCode)}
            </Paper>
          </Modal>
          <Modal
            id="closeWindowAndCheckEmail"
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={this.state.openCheckYourEmailModal}
            onClose={this.closeCheckYourEmailModal}
          >
            <Paper elevation={5} classes={{ root: classes.resetCodeModal }}>
              {this.checkYourEmailModal()}
            </Paper>
          </Modal>
          <SimpleDialog
            open={this.props.alert.show}
            onConfirm={() => this.props.hideError()}
            dialogTitle={
              typeof this.props.alert.title === 'object'
                ? JSON.stringify(this.props.alert.title)
                : this.props.alert.title
            }
            contentText={this.props.alert.body}
            confirmText="OK"
            styled={true}
            errorMessage={true}
          />
        </div>
      );
    }
  }
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ActivateAcc));
