// External imports
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { useSetState } from '~/app/Utility/customHooks';
import { Button, Slide } from '@material-ui/core';
import { Line } from 'rc-progress';
// Internal imports
import { HIERARCHY_TYPE, LATEST_SEASON, ROLE_TYPE } from '~/app/constants.js';
import AccessControl from '~/app/Components/Auth/AccessControl.jsx';
import Control from '#/Dashboard/Widgets/Control.jsx';
import ErrorHelpers from '~/app/errorHelpers.js';
import { getBodyComponent } from './loadableComponentHelper.js';
import { isTechOrAdminLogin, isHierarchySuperOrTech } from '~/app/Utility/general.js';
import MaintenanceRoutineMsg from '#/Common/MaintenanceRoutineMsg/MaintenanceRoutineMsg.jsx';
import Notifications from '~/app/Components/Common/Notifications/Notifications.jsx';
import UtilityAPI from '~/app/api/utilityAPI';
import WidgetGrid from '#/Dashboard/Widgets/WidgetGrid.jsx';
import WebHelpers, { handleSuddenCloseWindow, statusOK } from '~/app/webHelpers.js';
import XlinkAPI from '~/app/api/xlinkAPI.js';
import CustomerSupportFab from '#/Common/CustomerSupportFab/CustomerSupportFab.jsx';
import { checkAppVersion } from '~/app/Pages/Login/loginHelper.js';
// Redux imports
import { actions as appActions } from '~/app/redux/modules/app';
import { actions as cobrandActions } from '~/app/redux/cobrand/duck';
import { actions as defaultsActions } from '~/app/redux/setupPages/defaultsPage/duck';
import { actions as drilldownActions } from '~/app/redux/drilldown/duck';
import { actions as officeProfileActions } from '~/app/redux/officeProfile/duck';
import { actions as returnListActions } from '~/app/redux/returnList/duck';
import { actions as setupPageActions } from '~/app/redux/setupPages/duck';
import { actions as widgetDashBoardActions } from '~/app/redux/widgets/duck';
import { actions as loginSetupActions } from '~/app/redux/loginSetup/duck.js';
import { selectors as loginSelector } from '~/app/redux/loginSetup/selectors';
// Styling imports
import '~/app/Components/Dashboard/Overview/css/genericOverview.css';

const GenericOverview = props => {
  const dispatch = useDispatch();
  const history = useHistory();
  const payload = WebHelpers.getJWTPayload();
  const {
    loginID,
    tables,
    layout,
    widgetList,
    widgetData,
    locked,
    widgetControlOpen,
    currentView,
    drilldownHistory,
    isTrainingMode,
    activeAccessLevels,
    cobrand,
    isSearchBarVisible,
    showNewSeasonReminder,
    isFeederOffice,
    isMaintenanceModeTime,
  } = useSelector(state => ({
    loginID:
      state.drilldown?.drilldownHistory[state.drilldown?.drilldownHistory?.length - 1]?.loginID,
    tables: state.widgetDashboard.tables,
    layout: state.widgetDashboard.layout,
    widgetList: state.widgetDashboard.widgetList,
    widgetData: state.widgetDashboard.data,
    locked: state.widgetDashboard.locked,
    widgetControlOpen: state.widgetDashboard.widgetControlOpen,
    currentView: state.drilldown.drilldownHistory[state.drilldown.drilldownHistory.length - 1],
    drilldownHistory: state.drilldown.drilldownHistory,
    isTrainingMode: state.loginSetup.isTrainingMode,
    activeAccessLevels: loginSelector.getActiveAccessLevels(state),
    cobrand: state.cobrand,
    isSearchBarVisible: state.app.isSearchBarVisible,
    showNewSeasonReminder: state.loginSetup.showNewSeasonReminder,
    isFeederOffice: state.officeProfile.is_feeder_office,
    isMaintenanceModeTime: state.maintenance.isMaintenanceModeTime,
  }));
  const [state, setState] = useSetState({
    widgetControlOpen: false,
    isAdmin: true,
    widgetStore: [],
    // Adding variables to hold the screen size values
    windowHeight: undefined,
    windowWidth: undefined,
    windowWidthTwo: undefined,
    windowWidthThree: undefined,
    snackbar: true,
    vertical: 'bottom',
    horizontal: 'center',
  });

  const cobrandRef = useRef(cobrand);

  /*
  ADMIN, TECH_SUPPORT and TECH_SUPPORT_SBA are all considered admin in this scope
  data/access will be filtered later if logged in user is TECH_SUPPORT or TECH_SUPPORT_SBA
  */

  useEffect(() => {
    const isAdmin =
      drilldownHistory.length === 1 &&
      [HIERARCHY_TYPE.ADMIN, HIERARCHY_TYPE.TECH_SUPPORT, HIERARCHY_TYPE.TECH_SUPPORT_SBA].includes(
        currentView?.role,
      );
    setState({
      isAdmin,
    });
  }, [drilldownHistory, currentView]);

  useEffect(() => {
    if (currentView?.role === ROLE_TYPE.PREPARER && !activeAccessLevels?.add_return) {
      history.push({
        pathname: `/tax-returns`,
      });
    }
  }, [activeAccessLevels, currentView?.role]);

  useEffect(() => {
    // Do not allow user to close window abruptly
    window.addEventListener('beforeunload', handleSuddenCloseWindow);
    handleResize();
    window.addEventListener('resize', handleResize);

    // When a preparer logs in, we need to check if they have provided a signature
    // If they have not provided a signature we need to ask for one: do this before even showing the tour modal
    // Call API to see if there ia record for signature on db
    // Change the state of initialSignatureModalOpen
    // Get Widget Config
    if (activeAccessLevels?.view_widgets) {
      dispatch(widgetDashBoardActions.loadWidgetConfiguration());
    }

    checkAppVersion();
    dispatch(returnListActions.clearReturnsList());

    // Get user default configuration from setup if an EFIN and below
    if (
      currentView?.role === HIERARCHY_TYPE.PREPARER ||
      currentView?.role === HIERARCHY_TYPE.EFIN
    ) {
      dispatch(defaultsActions.fetchDefaultsConfig(loginID, payload.season));
      dispatch(officeProfileActions.fetchOfficeProfile());
    }

    // Check if the logged in user is an EFIN owner
    if (currentView?.role === HIERARCHY_TYPE.EFIN) {
      XlinkAPI.getEfinOwner()
        .then(res => {
          dispatch(appActions.updateEfinOwnerValue(res.data));
        })
        .catch(error => {
          ErrorHelpers.handleError('Error fetching EFIN owner', error);
        });
    }
    // Only offices and managers (type EFIN) should see the capture signature option
    if (currentView?.role === HIERARCHY_TYPE.EFIN) {
      XlinkAPI.getAccountLinkedToPreparer()
        .then(res => {
          dispatch(appActions.updateLinkToPreparerValue(res.data));
        })
        .catch(error => {
          ErrorHelpers.handleError('Error fetching Login Linked to Preparer', error);
        });
    }

    setBasicInfo();

    // If they login at office level and do not own a license for the latest season, send them a reminder
    if (showNewSeasonReminder) {
      dispatch(
        appActions.showSnackbarMessage(
          `The latest season ${LATEST_SEASON} is available for purchase`,
          'info',
          3500,
          {
            vertical: 'top',
            horizontal: 'center',
          },
        ),
      );
      dispatch(loginSetupActions.setShowNewSeasonReminder(false));
    }

    return () => {
      window.removeEventListener('beforeunload', handleSuddenCloseWindow);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (currentView?.role) {
      const isEfinPreparer = [HIERARCHY_TYPE.PREPARER, HIERARCHY_TYPE.EFIN].includes(
        currentView?.role,
      );

      // Obtain access to cobranding
      getHasAccessToAddCobranding();
      // We drilled down so lets grab the drilled down accounts config
      if (
        isEfinPreparer ||
        currentView?.role === HIERARCHY_TYPE.FRANCHISE ||
        currentView?.role === HIERARCHY_TYPE.SSB // Nested reseller
      ) {
        dispatch(defaultsActions.fetchDefaultsConfig(loginID, payload.season));
        dispatch(officeProfileActions.fetchOfficeProfile());
        setBasicInfo();
      }
      // If search is open, ensure we close out of dialog
      if (isSearchBarVisible) {
        dispatch(appActions.toggleSearchBar(false));
      }
      // Fetch office progress setup
      if (canGetSetupProgress(isEfinPreparer, payload?.hierarchy_type_id)) {
        checkSetupProgress();
      }
    }
  }, [currentView]);

  useEffect(() => {
    if (cobrand?.canCobrand !== cobrandRef.current?.canCobrand) {
      cobrandRef.current = cobrand;
    }
  }, [cobrand?.canCobrand]);

  useEffect(() => {
    if (activeAccessLevels?.view_widgets) {
      dispatch(widgetDashBoardActions.loadWidgetConfiguration());
    }
  }, [payload?.season]);

  useEffect(() => {
    // Edge case: If the user hasn't received maintenance data yet and it's maintenance time, a page refresh resets the Redux store and the fingerprint logic re-logs them in. We must force logout the user.
    // This scenario only affects active user sessions and occurs if maintenance mode is set immediately without prior notice.
    if (isMaintenanceModeTime) {
      ErrorHelpers.forceLogout('Scheduled Maintenance is currently in progress');
    }
  }, [isMaintenanceModeTime]);

  const checkSetupProgress = async () => {
    try {
      const res = await XlinkAPI.getSetupProgressIndicators(isFeederOffice);
      if (statusOK(res)) {
        const data = Object.values(res.data);
        const numTrue = data.reduce((n, setupStep) => n + (setupStep === true), 0);
        const percentage = (numTrue / data.length) * 100;
        if (typeof percentage !== 'undefined') {
          dispatch(drilldownActions.setSetupProgress(percentage));
        }
      }
    } catch (err) {
      ErrorHelpers.handleError('Error retrieving setup progress', err);
      dispatch(
        appActions.showSnackbarMessage('Error retrieving setup progress', 'error', 3500, {
          vertical: 'top',
          horizontal: 'center',
        }),
      );
    }
  };

  /** Set basic user info */
  const setBasicInfo = async () => {
    try {
      const basicInfo = await UtilityAPI.getBasicOfficeInfo(drilldownHistory);
      if (typeof basicInfo !== 'undefined') {
        dispatch(appActions.setLoggedInBasicInfo(basicInfo));
      }
    } catch (err) {
      ErrorHelpers.handleError('Error setting basic info', err);
      dispatch(
        appActions.showSnackbarMessage('Error setting basic info', 'error', 3500, {
          vertical: 'top',
          horizontal: 'center',
        }),
      );
    }
  };

  // Adding function that will handle resizing
  const handleResize = () => {
    setState({
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      windowWidthTwo: window.innerWidth * 0.3,
      windowWidthThree: window.innerWidth * 0.15,
    });
  };

  const getHasAccessToAddCobranding = async () => {
    try {
      const res = await XlinkAPI.getHasAccessToAddCobranding();

      if (statusOK(res)) {
        const { canTogglePurchase, canToggleCobrand, canCobrand, purchasedCobrand } = res.data;

        dispatch(
          cobrandActions.hasAccessToAddCobrandingSet(
            canToggleCobrand,
            canTogglePurchase,
            canCobrand,
            purchasedCobrand,
          ),
        );
      } else {
        ErrorHelpers.handleError('Cobranding Error', 'Unable to retrieve co-branding for account.');
      }
    } catch (error) {
      ErrorHelpers.handleError('Cobranding Error', error);
    }
  };

  const openAddWidgetControl = () => {
    XlinkAPI.getWidgetStore()
      .then(res => {
        setState({ widgetStore: res.data });
        document.body.style.overflow = 'hidden'; // Not Sure if this is an ideal solution....
        dispatch(widgetDashBoardActions.openCloseWidgetControl());
      })
      .catch(err => {
        ErrorHelpers.handleError('Failed to open widget control', err);
      });
  };

  const closeAddWidgetControl = () => {
    document.body.style.overflow = ''; // Not Sure if this is an ideal solution....
    dispatch(widgetDashBoardActions.openCloseWidgetControl());
  };

  const onRemoveWidget = keyToPass => {
    dispatch(widgetDashBoardActions.RemoveWidget(keyToPass));
  };

  const onClickSetup = () => {
    dispatch(setupPageActions.toggleSetupAccount());
    history.push({
      pathname: `/account-settings`,
      state: {
        fromPreparer: false,
      },
    });
  };

  const altWelcomeText = (
    <div className="welcome-heading-text-centered">
      Welcome! This office hasn&apos;t finished the required setup for filing. Please contact your
      administrator
    </div>
  );

  // Returns true if Efin is preparer, the top login is not super, and if office has not been skipped via searchbar
  const canGetSetupProgress = (isPreparer, hierarchyID) => {
    return (
      isPreparer &&
      !isHierarchySuperOrTech(hierarchyID) &&
      !(
        [HIERARCHY_TYPE.PREPARER].includes(currentView?.role) &&
        currentView?.loginID !== drilldownHistory[drilldownHistory.length - 2]?.loginID
      )
    );
  };

  const finishSettingUpOffice = () => {
    if (currentView?.role === HIERARCHY_TYPE.EFIN && currentView?.isOfficeSetupComplete === false) {
      return (
        <div className="row ovNotebookTwo">
          <AccessControl
            requiredAction="write"
            accessLevel="view_office_setup"
            alternateView={altWelcomeText}
          >
            <div className="welcome-heading-text">
              Welcome! Please complete the required info for filing.
            </div>
            <div className="welcome-linear-progress-container">
              <Line
                className="welcome-linear-progress-content"
                percent={currentView?.setupProgress}
                strokeWidth="3"
                strokeColor="#00C44F"
              />
            </div>
            <Button
              className="welcome-setup-link"
              color="primary"
              variant="contained"
              onClick={onClickSetup}
            >
              Complete Setup
            </Button>
          </AccessControl>
        </div>
      );
    } else {
      return <></>;
    }
  };

  /**
   * The check for hierarchy_type_id of admin or tech is for
   * initial login of tech or admin logins landing on the dashboard page
   * where a widget is not necessary.
   */
  const setupWidget = () => {
    if (
      payload.hierarchy_type_id !== HIERARCHY_TYPE.ADMIN ||
      payload.hierarchy_type_id !== HIERARCHY_TYPE.TECH_SUPPORT
    ) {
      let addWidgetHandler = '';
      if (!isTechOrAdminLogin(payload)) {
        // Only show the widget drag button when
        // it is an actual customer login and not drilled down
        // from tech login.
        addWidgetHandler = (
          <AccessControl requiredAction="write" accessLevel="view_widgets">
            <div className="add-new-widget-text-align-center">
              <hr className="horizontal-rule" />

              <Button className="btn-addMetric add-new-widget-btn" onClick={openAddWidgetControl}>
                Add New Widget
              </Button>

              <hr className="horizontal-rule" />
            </div>
          </AccessControl>
        );
      }

      return (
        <div>
          <WidgetGrid
            className="row ovNotebook"
            tables={tables}
            widgetList={widgetList}
            layout={layout}
            locked={locked}
            onRemoveWidget={keyToPass => onRemoveWidget(keyToPass)}
            accountType={currentView?.role}
            widgetData={widgetData}
            toggleWidgetStaticByKey={() => dispatch(widgetDashBoardActions.toggleWidgetStatic())}
          />
          {addWidgetHandler}
          <Slide direction="up" in={widgetControlOpen} mountOnEnter unmountOnExit>
            <Control
              onClose={closeAddWidgetControl}
              widgets={state.widgetStore}
              isInTrainingMode={isTrainingMode}
              autoFocus
            />
          </Slide>
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div className="body-container">
      <div className="body-main-block">
        <div className="body-content">
          <MaintenanceRoutineMsg />
          <div className="container-fixed ovContainer">{finishSettingUpOffice()}</div>
          <Notifications />
          <div className="conatiner-fixed ovContainer">
            {setupWidget()}
            <div>{getBodyComponent(loginID, currentView?.role)}</div>
          </div>
          <CustomerSupportFab cobrand={cobrandRef.current} />
        </div>
      </div>
    </div>
  );
};

export default withRouter(GenericOverview);
