// External Imports
import React, { useEffect, Fragment } from 'react';
import {
  Button,
  Collapse,
  ListItemText,
  ListItemSecondaryAction,
  Menu,
  MenuItem,
  MenuList,
  Typography,
  IconButton,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
// Internal Imports
import RefundInformation from '#/Common/RefundInformation.jsx';
import IncomeInformation from '#/Common/IncomeInformation.jsx';
import { useSetState } from '~/app/Utility/customHooks';
import { handleMenuItemHighlight, getFedFormFromProps } from './sidebarFormsHelpers.js';
import { shouldDisableSideBarItem, shouldDisableAddReturn } from '~/app/Utility/general.js';
// Styling Imports
import Checkmark from '~/images/icons/icons8-checkmark-48.png';
import menuVertical from '~/images/icons/menu_vertical.png';
import expandArrow from '~/images/icons/icons8-double_right_filled.png';
import './css/SideBarForms.css';
import {
  IconStyle,
  IconStyleNotAttached,
  formItemStyle,
  formItemStyleDisabled,
  formItemStyleDropdown,
  formItemStyleAttached,
  subFormItemStyle,
  selectedSubFormItemStyle,
  formHeaderStyle,
  contextItemStyle,
  sidebarFooterBtn,
} from './css/sidebarForms.js';

const SideBarForms = props => {
  const [state, setState] = useSetState({
    expandedHeaders: [],
    modalOpen: false,
    availableForms: [],
    contextMenuAnchor: null,
    contextMenuSelected: null,
    stateListOpen: false,
    collapsibleStateList: false,
    onMobile: false,
    sideBarExpanded: true,
    tabFederalDefinition: null,
  });

  useEffect(() => {
    const initHeaders = [];
    props.formList.forEach(category => {
      const keyAndID = category.desc && category.desc !== '' ? category.desc : category.Name;
      initHeaders.push(keyAndID);
    });
    setState({
      expandedHeaders: initHeaders,
    });

    // Check screensize
    checkScreenSize();

    props.tabListDefinitions.forEach((idr, index) => {
      if (idr.label === 'Federal') {
        setState({
          tabFederalDefinition: {
            tabObject: idr,
            index,
          },
        });
      }
    });
  }, []);

  // Check screensize and set variables
  const checkScreenSize = () => {
    // We assume onMobile for any screens 1024 or smaller in width
    if (window.innerWidth < 1025) {
      setState({ onMobile: true, sideBarExpanded: false });
    }
  };

  const handleClickOnCollapser = key => {
    const keyIndex = state.expandedHeaders.indexOf(key);
    const newexpandedHeaders = [...state.expandedHeaders];

    if (keyIndex === -1) {
      newexpandedHeaders.push(key);
    } else {
      newexpandedHeaders.splice(keyIndex, 1);
    }

    setState({
      expandedHeaders: newexpandedHeaders,
    });
  };

  const handleContextMenuToggle = (event, selected) => {
    setState({
      contextMenuAnchor: event.currentTarget,
      contextMenuSelected: selected,
    });
  };

  const handleContextMenuClose = () => {
    setState({
      contextMenuAnchor: null,
      contextMenuSelected: null,
    });
  };

  const handleSubItemClick = (event, form, guid) => {
    if (form.slice(0, 3) !== 'DOX') {
      form = form.slice(0, 6) + form.slice(8, 9);
      if (form.slice(0, 1) === 'Z') {
        props.handleSidebarFormClick(event, form, '', '');
      } else {
        props.handleSidebarFormClick(event, form, 'wks', '');
      }
    } else {
      props.handleSidebarFormClick(event, form, '', guid); // Pass guid as formFile for binary attachment items
    }
  };

  const handleDetachDocument = docvar => {
    const docid = props.getDocumentIDByVar(docvar);
    props.detachDocument(docid, docvar);
  };

  const formSubRows = (items, grandParent) => {
    if (typeof items === 'undefined' || items == null) {
      return;
    }
    const subFormList = items.map((sub, index) => {
      let removeItem;
      if (sub.var.slice(0, 3) === 'DOX') {
        removeItem = (
          <MenuItem
            className="contextMenuItem"
            onClick={() => {
              handleContextMenuClose();
              handleDetachDocument(sub.var);
            }}
            style={contextItemStyle}
            key={'sidemenuitem' + index}
          >
            Detach Document
          </MenuItem>
        );
      } else {
        removeItem = (
          <MenuItem
            className="contextMenuItem"
            onClick={() => {
              handleContextMenuClose();
              alert('remove ' + sub.var + index);
            }}
            style={contextItemStyle}
            key={'sidemenuitem' + index}
          >
            Remove Worksheet
          </MenuItem>
        );
      }
      let contextItem = <Fragment />;
      if (grandParent !== '00' && grandParent !== 'PF' && grandParent !== 'retn37') {
        contextItem = (
          <ListItemSecondaryAction className="secondaryMenuItem">
            <Button
              className="contextMenuButton"
              aria-owns={state.contextMenuAnchor ? sub.var + index + '-context' : null}
              aria-haspopup="true"
              onClick={e => handleContextMenuToggle(e, sub.var + index + '-context')}
              style={{
                minHeight: '1.5rem',
                maxHeight: '1.5rem',
                minWidth: '0.5rem',
                maxWidth: '0.5rem',
                padding: '0px 8px 0px 8px',
                border: '1px solid #0077FF',
              }}
              disabled={props.assetMode}
            >
              <img src={menuVertical} style={{ height: `1em` }} />
            </Button>
            <Menu
              id={sub.var + index + '-context'}
              anchorEl={state.contextMenuAnchor}
              open={Boolean(state.contextMenuSelected === sub.var + index + '-context')}
              onClose={handleContextMenuClose}
            >
              <MenuItem
                className="contextMenuItem"
                onClick={() => {
                  handleContextMenuClose();
                  props.onClickAddForm(
                    state.tabFederalDefinition?.tabObject,
                    state.tabFederalDefinition?.index,
                  );
                }}
                style={contextItemStyle}
              >
                Add Form
              </MenuItem>
              {removeItem}
              <MenuItem
                className="contextMenuItem"
                onClick={() => {
                  handleContextMenuClose();
                  alert('help');
                }}
                style={{ display: 'none' }}
              >
                Help
              </MenuItem>
            </Menu>
          </ListItemSecondaryAction>
        );
      }
      return (
        <MenuItem
          button
          className="menuItem"
          key={sub.var + index}
          selected={handleMenuItemHighlight(
            props.activeForm,
            sub.var.slice(0, 6) + sub.var.slice(8, 9),
          )}
          id={'sidebar' + sub.var + index}
          onClick={event => handleSubItemClick(event, sub.var, sub.guid)}
          disabled={props.assetMode}
        >
          <ListItemText
            style={{ paddingLeft: '25px' }}
            className="sidebarMenuItem"
            disableTypography
            inset
            primary={
              <Typography
                type="body2"
                style={
                  handleMenuItemHighlight(
                    props.activeForm,
                    sub.var.slice(0, 6) + sub.var.slice(8, 9),
                  )
                    ? selectedSubFormItemStyle
                    : subFormItemStyle
                }
              >
                {sub.desc}
              </Typography>
            }
          />
          {contextItem}
        </MenuItem>
      );
    });
    return subFormList;
  };

  /**
   * Check if form is currently being viewed, then
   * we don't need to reload the form.
   */
  const checkFormNav = (event, form, props) => {
    if (form.var !== props.activeForm) {
      confirmAddInterviewForms(form);
      props.handleSidebarFormClick(event, form.var, '', form.frmFile);
    }
  };

  const confirmAddInterviewForms = (form, addForm = false) => {
    if (form.desc === 'Start' && (form.var === 'Z00501' || form.var === 'Z03001')) {
      props.showAddInterviewModal();

      if (!props.addInterviewFlag) {
        return;
      }
    }

    if (addForm) {
      handleContextMenuClose();
      props.onClickAddForm(
        state.tabFederalDefinition?.tabObject,
        state.tabFederalDefinition?.index,
      );
    }
  };

  const formRows = (items, parent) => {
    if (typeof items === 'undefined' || items == null) {
      return;
    }
    const formList = items.map((form, index) => {
      const attachedIcon = <img src={Checkmark} style={{ height: '1rem', width: '1rem' }} />;
      let remDisabled = false;
      let isLower = false;
      let isNum = false;
      if (form.var && (!isNaN(form.var.slice(0, 1)) || !isNaN(form.var.slice(1, 2)))) {
        isNum = true;
      }
      if (
        form.var &&
        isNaN(form.var.slice(0, 1)) &&
        form.var.slice(0, 1) === form.var.slice(0, 1).toLowerCase() &&
        isNaN(form.var.slice(1, 2)) &&
        form.var.slice(1, 2) === form.var.slice(1, 2).toLowerCase()
      ) {
        isLower = true;
      }

      if (
        isLower ||
        isNum ||
        (form.var && (form.var.slice(4) === '' || form.var.slice(6) !== '')) ||
        (form.var === 'DOXUS' && form.desc === 'BinaryAttachments')
      ) {
        remDisabled = true;
      }
      let contextItem = <Fragment />;
      contextItem = (
        <ListItemSecondaryAction className="secondaryMenuItem">
          <Button
            className="contextMenuButton"
            aria-owns={state.contextMenuAnchor ? form.var + '-context' : null}
            aria-haspopup="true"
            onClick={e => {
              if (!props.locked) {
                handleContextMenuToggle(e, form.var + '-context');
              }
            }}
            style={{
              minHeight: '1.5rem',
              maxHeight: '1.5rem',
              minWidth: '0.5rem',
              maxWidth: '0.5rem',
              padding: '0px 8px 0px 8px',
              border: '1px solid #0077FF',
            }}
            disabled={props.assetMode}
          >
            <img src={menuVertical} style={{ height: `1em` }} />
          </Button>
          <Menu
            id={form.var + '-context'}
            anchorEl={state.contextMenuAnchor}
            open={Boolean(state.contextMenuSelected === form.var + '-context')}
            onClose={handleContextMenuClose}
          >
            <MenuItem
              className="contextMenuItem"
              onClick={() => {
                confirmAddInterviewForms(form, true);
              }}
              style={contextItemStyle}
            >
              Add Form
            </MenuItem>
            <MenuItem
              className="contextMenuItem"
              onClick={() => {
                handleContextMenuClose();
                props.handleDeleteForm(form, form.desc);
              }}
              disabled={remDisabled}
              style={contextItemStyle}
            >
              Remove Form
            </MenuItem>
            <MenuItem
              className="contextMenuItem"
              onClick={() => {
                handleContextMenuClose();
              }}
              style={{ display: 'none' }}
              disabled={true}
            >
              {' '}
              {/* TODO: Select for Print */}
              Select for Print
            </MenuItem>
            <MenuItem
              className="contextMenuItem"
              onClick={() => {
                handleContextMenuClose();
              }}
              style={{ display: 'none' }}
            >
              {' '}
              {/* TODO: Help */}
              Help
            </MenuItem>
          </Menu>
        </ListItemSecondaryAction>
      );
      let itemStyle = formItemStyleDropdown;
      if (typeof form.GChildren !== 'undefined') {
        if (form.icon !== '1') {
          // form is not attached
          itemStyle = formItemStyleDropdown;
        }
      } else {
        // form item has no dropdown
        itemStyle = formItemStyleAttached;
        if (form.icon !== '1') {
          itemStyle = formItemStyle;
        }
      }

      return (
        <div key={'list-' + form.var + index}>
          <MenuItem
            button
            className="menuItem"
            // key={form.var}
            selected={handleMenuItemHighlight(props.activeForm, form.var)}
            id={'form' + form.var}
            onClick={event => checkFormNav(event, form, props)}
            disabled={props.assetMode || shouldDisableSideBarItem(props.isDemoAccount, form.var)}
            key={'sidemenuitemctx' + index}
          >
            {form.icon === '1' ? attachedIcon : ''}
            {typeof form.GChildren !== 'undefined' ? (
              <IconButton
                id={'itemIcon' + form.var}
                style={form.icon !== '1' ? IconStyleNotAttached : IconStyle}
                onClick={() => handleClickOnCollapser('list-' + form.var + index)}
              >
                {state.expandedHeaders.indexOf('list-' + form.var + index) !== -1 ? (
                  <ExpandLess
                    id={'lessIcon' + form.var}
                    style={{ fontSize: '16px', paddingRight: '0px' }}
                  />
                ) : (
                  <ExpandMore
                    id={'moreIcon' + form.var}
                    style={{ fontSize: '16px', paddingRight: '0px' }}
                  />
                )}
              </IconButton>
            ) : (
              ''
            )}
            <ListItemText
              className="sidebarMenuItem"
              disableTypography
              inset
              primary={
                <Typography
                  type="body2"
                  style={
                    handleMenuItemHighlight(props.activeForm, form.var)
                      ? Object.assign({}, itemStyle, { color: '#1A173B' })
                      : props.assetMode
                      ? formItemStyleDisabled
                      : itemStyle
                  }
                >
                  {form.desc && form.desc.length > 29
                    ? form.desc.slice(0, 26) + '...'
                    : form.desc.slice(0, 29)}
                </Typography>
              }
              style={{ paddingLeft: '10px' }}
            />
            {contextItem}
          </MenuItem>
          <Collapse
            in={state.expandedHeaders.indexOf('list-' + form.var + index) !== -1}
            timeout="auto"
            unmountOnExit
          >
            {formSubRows(form.GChildren, parent)}
          </Collapse>
        </div>
      );
    });

    const result = [];
    for (let i = 0; i < formList.length; i++) {
      result.push(formList[i]);
    }
    return result;
  };

  const generateSidebar = () => {
    const sidebar = props.formList.map((category, index) => {
      const keyAndID = category.desc && category.desc !== '' ? category.desc : category.Name;
      let contextItem = <Fragment />;
      if (
        category.var !== '00' &&
        category.var !== 'US' &&
        category.var !== 'PF' &&
        category.var !== 'retn37'
      ) {
        contextItem = (
          <ListItemSecondaryAction className="secondaryMenuItem">
            <Button
              className="contextMenuButton"
              aria-owns={state.contextMenuAnchor ? category.desc + '-context' : null}
              aria-haspopup="true"
              onClick={e => {
                if (!props.locked) {
                  handleContextMenuToggle(e, category.desc + '-context');
                }
              }}
              style={{
                minHeight: '1.5rem',
                maxHeight: '1.5rem',
                minWidth: '0.5rem',
                maxWidth: '0.5rem',
                padding: '0px 8px 0px 8px',
                border: '1px solid #0077FF',
              }}
              disabled={props.assetMode}
            >
              <img src={menuVertical} style={{ height: `1em` }} />
            </Button>
            <Menu
              id={category.var + '-context'}
              anchorEl={state.contextMenuAnchor}
              open={Boolean(state.contextMenuSelected === category.desc + '-context')}
              onClose={handleContextMenuClose}
            >
              <MenuItem
                className="contextMenuItem"
                onClick={() => {
                  handleContextMenuClose();
                  props.handleDeletePkg(category.var, category.desc);
                }}
                style={contextItemStyle}
              >
                Remove State
              </MenuItem>
            </Menu>
          </ListItemSecondaryAction>
        );
      }
      return (
        <div key={keyAndID + index} id={keyAndID}>
          <MenuItem
            id={keyAndID.trim() + 'button'}
            button
            className="menuItem"
            onClick={() => handleClickOnCollapser(keyAndID)}
            selected={handleMenuItemHighlight()}
            disabled={props.assetMode}
            key={'sidemenuitemctx2' + index}
          >
            {state.expandedHeaders.indexOf(keyAndID) !== -1 ? (
              <ExpandLess style={{ fontSize: '16px' }} />
            ) : (
              <ExpandMore style={{ fontSize: '16px' }} />
            )}
            <ListItemText
              style={{ padding: 0 }}
              disableTypography
              inset
              primary={
                <Typography type="body2" style={formHeaderStyle}>
                  {category.desc}
                </Typography>
              }
            />
            {contextItem}
          </MenuItem>
          <Collapse
            in={state.expandedHeaders.indexOf(keyAndID) !== -1}
            timeout="auto"
            unmountOnExit
          >
            {formRows(category.Children, category.var)}
          </Collapse>
        </div>
      );
    });
    return sidebar;
  };

  const getIncomeInfo = () => {
    let incomeAmount = '0';
    const fedForm = getFedFormFromProps(props.formList);
    if (fedForm && (fedForm.agi || fedForm.ninc)) {
      incomeAmount = `${props.isBusiness ? fedForm.ninc : fedForm.agi}`;
    }

    return (
      <IncomeInformation
        incomeAmount={incomeAmount}
        incomeInfo={props.isBusiness ? 'Net Income' : 'Federal AGI'}
      />
    );
  };

  const getFedRefund = () => {
    let fedRefund = 0;

    const fedForm = getFedFormFromProps(props.formList);
    if (fedForm) {
      if (fedForm.rfnd && fedForm.rfnd !== '') {
        fedRefund = fedForm.rfnd.substring(1);
      } else if (fedForm.bdue && fedForm.bdue !== '') {
        fedRefund = fedForm.bdue.replace('$', '-');
      }
    }

    return <RefundInformation refundAmount={fedRefund} refundInfo={'Federal Refund'} />;
  };

  const handleExpandStatelist = async () => {
    await setState(prevState => ({
      stateListOpen: !prevState.stateListOpen,
    }));
  };

  const getStateRefunds = () => {
    const states = [];
    for (let i = 0; i < props.formList.length; i++) {
      if (
        props.formList[i].desc &&
        props.formList[i].desc.trim() !== 'FEDERAL' &&
        props.formList[i].desc !== 'GENERAL' &&
        props.formList[i].desc !== 'Refund Calculator' &&
        props.formList[i].var !== 'retn37' &&
        props.formList[i].var !== 'PF'
      ) {
        let refOrBal;
        if (props.formList[i].rfnd && props.formList[i].rfnd !== '') {
          refOrBal = props.formList[i].rfnd.substring(1);
          states.push(
            <RefundInformation
              refundAmount={refOrBal}
              refundInfo={props.formList[i].var + ' State Refund'}
              key={props.formList[i].var + i}
            />,
          );
        } else if (props.formList[i].bdue && props.formList[i].bdue !== '') {
          refOrBal = props.formList[i].bdue.replace('$', '-');
          states.push(
            <RefundInformation
              refundAmount={refOrBal}
              refundInfo={props.formList[i].var + ' State Refund'}
              key={props.formList[i].var + i}
            />,
          );
        } else {
          states.push(
            <RefundInformation
              refundAmount={0}
              refundInfo={props.formList[i].var + ' State Refund'}
              key={props.formList[i].var + i}
            />,
          );
        }

        let incomeAmount;
        const incomeFld = props.isBusiness ? 'ninc' : 'agi';
        const info = props.isBusiness ? ' Net Income' : ' AGI';

        if (props.formList[i].rfnd && props.formList[i].rfnd !== '') {
          incomeAmount = props.formList[i]?.[incomeFld]?.substring(1);
          states.push(
            <IncomeInformation
              incomeAmount={incomeAmount}
              incomeInfo={props.formList[i].var + info}
            />,
          );
        } else if (props.formList[i].bdue && props.formList[i].bdue !== '') {
          incomeAmount = props.formList[i].bdue.replace('$', '-');
          states.push(
            <IncomeInformation
              incomeAmount={incomeAmount}
              incomeInfo={props.formList[i].var + info}
            />,
          );
        } else {
          states.push(
            <IncomeInformation incomeAmount={0} incomeInfo={props.formList[i].var + info} />,
          );
        }
      }
    }
    // If there is more than one state wrap the other states in a collapse element
    if (states.length > 1) {
      return (
        <div id="sibebar-additional-states-refund">
          {states.shift()}
          <Collapse
            in={state.stateListOpen}
            style={{ maxHeight: '20vh', overflowY: 'auto' }}
            timeout="auto"
            unmountOnExit
          >
            {states}
          </Collapse>
        </div>
      );
    }
    return states;
  };

  const getStateIncome = () => {
    const states = [];
    for (let i = 0; i < props.formList.length; i++) {
      if (
        props.formList[i].desc &&
        props.formList[i].desc.trim() !== 'FEDERAL' &&
        props.formList[i].desc !== 'GENERAL' &&
        props.formList[i].desc !== 'Refund Calculator' &&
        props.formList[i].var !== 'retn37' &&
        props.formList[i].var !== 'PF'
      ) {
        let incomeAmount;
        const incomeFld = props.isBusiness ? 'ninc' : 'agi';
        const info = props.isBusiness ? ' Net Income' : ' AGI';

        if (props.formList[i].rfnd && props.formList[i].rfnd !== '') {
          incomeAmount = props.formList[i]?.[incomeFld]?.substring(1);
          states.push(
            <IncomeInformation
              incomeAmount={incomeAmount}
              incomeInfo={props.formList[i].var + info}
            />,
          );
        } else if (props.formList[i].bdue && props.formList[i].bdue !== '') {
          incomeAmount = props.formList[i].bdue.replace('$', '-');
          states.push(
            <IncomeInformation
              incomeAmount={incomeAmount}
              incomeInfo={props.formList[i].var + info}
            />,
          );
        } else {
          states.push(
            <IncomeInformation incomeAmount={0} incomeInfo={props.formList[i].var + info} />,
          );
        }
      }
    }
    // If there is more than one state wrap the other states in a collapse element
    if (states.length > 1) {
      return (
        <div id="sibebar-additional-states-refund">
          {states.shift()}
          <Collapse
            in={state.stateListOpen}
            style={{ maxHeight: '20vh', overflowY: 'auto' }}
            timeout="auto"
            unmountOnExit
          >
            {states}
          </Collapse>
        </div>
      );
    }
    return states;
  };

  // Only display this toggle when viewing on iPad
  const mobileDisplay = () => {
    if (state.onMobile) {
      return (
        <div style={{ position: 'relative' }}>
          <IconButton
            id="btnExpandSideBar"
            color="primary"
            size="small"
            variant="text"
            style={{ width: '35px' }}
            className="form-cellItem"
            onClick={() => {
              setState({
                sideBarExpanded: !state.sideBarExpanded,
              });
            }}
          >
            {state.sideBarExpanded ? (
              <ChevronLeftIcon
                className="sidebarCollapseIcon"
                style={{ color: '#1880e7', height: '35px', width: '35px' }}
              />
            ) : (
              <ChevronRightIcon
                className="sidebarCollapseIcon"
                style={{ color: '#1880e7', height: '35px', width: '35px' }}
              />
            )}
          </IconButton>
        </div>
      );
    }
  };

  const states = getStateRefunds();

  return (
    <div className="side-bar-forms-content">
      {mobileDisplay()}
      {state.sideBarExpanded ? (
        <div className="form-nav-container">
          <div id="sidebar-return-refunds-list">
            {getFedRefund()}
            {states}
            {getIncomeInfo()}
            {states.length === undefined ? (
              <div
                onClick={handleExpandStatelist}
                style={{
                  paddingLeft: '24px',
                  marginLeft: '1em',
                  color: '#0077FF',
                }}
                cursor="pointer"
              >
                {state.stateListOpen ? 'Collapse' : 'Expand All'}
                <img
                  src={expandArrow}
                  className={state.stateListOpen ? 'tableExpandArrowDown' : 'tableExpandArrowUp'}
                />
              </div>
            ) : (
              <Fragment />
            )}
          </div>
          <div id="sidebar-form-list" className="sidebar-form-nav">
            <div className="formList">
              <MenuList component="nav">{generateSidebar()}</MenuList>
            </div>
          </div>
          <div className="add-a-form-btn-container">
            <Button
              id="btnAddFormSidebar"
              color="primary"
              size="small"
              onClick={() => {
                props.onClickAddForm(
                  state.tabFederalDefinition?.tabObject,
                  state.tabFederalDefinition?.index,
                );
              }}
              style={sidebarFooterBtn}
              disabled={
                props.assetMode ||
                shouldDisableAddReturn(props.activeAccessLevels, props.isBusiness)
              }
            >
              Add a Form
            </Button>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SideBarForms;
