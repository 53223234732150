// External Imports
import React, { useEffect } from 'react';
import { Button, Toolbar, Typography, Divider, Grid, withStyles } from '@material-ui/core';
// Internal Imports
import XlinkAPI from '~/app/api/xlinkAPI';
import ErrorHelpers from '~/app/errorHelpers.js';
import { useSetState } from '~/app/Utility/customHooks';
import WebHelpers, { statusOK } from '~/app/webHelpers.js';
// Styling Imports
import '~/app/Components/Auth/css/versionModal.css';
import { styles } from '~/app/Components/Auth/css/versionModal.js';

const VersionsModal = props => {
  const { classes } = props;
  const payload = WebHelpers.getJWTPayload();

  const [state, setState] = useSetState({
    indVersionNames: [],
    corpVersionNames: [],
  });

  useEffect(() => {
    getAvailableVersion();
  }, []);

  const getAvailableVersion = async () => {
    // get available packages for individual and business
    const availableIndPackages = await getAvailablePackages(false);
    const availableCorpPackages = await getAvailablePackages(true);

    fetchCrosslinkVersions(availableIndPackages, availableCorpPackages);
  };

  const fetchCrosslinkVersions = async (indPackages, corpPackages) => {
    try {
      const res = await XlinkAPI.fetchCrosslinkVersions();

      if (statusOK(res)) {
        const indVersions = Object.entries(res.data.arg['1040Versions']);

        // corp does not exist prior to season 2024
        let corpVersions = [];
        if (payload.season >= 2024) {
          corpVersions = Object.entries(res.data.arg?.CORPVersions || []);
        }

        setState({
          indVersionNames: removeUnavailableVersions(indVersions, indPackages),
          corpVersionNames: removeUnavailableVersions(corpVersions, corpPackages),
        });
      }
    } catch (error) {
      ErrorHelpers.handleError('Error Fetching verions', error);
    }
  };

  const getAvailablePackages = async isBusiness => {
    try {
      const res = await XlinkAPI.getStatePackages(isBusiness);
      // data array will be null if there are no state packages
      if (statusOK(res, false)) {
        const statePackages = res.data || [];
        statePackages.push('US', 'PF', 'TP');
        return statePackages.sort();
      }
    } catch (error) {
      ErrorHelpers.handleError('Fetch Error', error);
    }
  };

  const removeUnavailableVersions = (versionList, availablePackages) => {
    // versionList is an array of arrays:
    // ["state", "version number"]

    // always display the federal packages and TP print version
    const availableVersionList = versionList?.filter(state =>
      availablePackages?.includes(state[0]),
    );

    return availableVersionList || [];
  };

  const displayVersionNumbers = is1040 => {
    const versionList = is1040 ? state.indVersionNames : state.corpVersionNames;
    return versionList.map(val => {
      const version = val[0] + '\n' + val[1];
      return (
        <Grid item xs={2} zeroMinWidth key={val[0]}>
          {version}
        </Grid>
      );
    });
  };

  return (
    <div className="version-modal-container">
      <Toolbar className="version-modal-toolbar">
        <Typography classes={{ root: classes.typography }}>
          Versions
          <Button classes={{ root: classes.btn }} onClick={() => props.onClose()}>
            X
          </Button>
        </Typography>
      </Toolbar>
      <div className="version-modal-body">
        <span className="version-modal-body-title">Tax Year {payload.season - 1}</span>

        <span className="version-modal-body-program-version">
          Program Version: {sessionStorage.getItem('appVersion') || ''}
        </span>
        <Grid
          classes={{
            item: classes.styledFlexGridDivider,
          }}
          item
          xs={12}
        >
          <Divider
            classes={{
              root: classes.documentDivider,
            }}
            variant="fullWidth"
            light={true}
          />
        </Grid>
        <span className="version-modal-body-program-version">1040</span>
        <Grid className="version-modal-grid" container spacing={0}>
          {displayVersionNumbers(true)}
        </Grid>
        {payload.season >= 2024 && (
          <span className="version-modal-body-program-version">Business</span>
        )}
        {payload.season >= 2024 && (
          <Grid className="version-modal-grid" container spacing={0}>
            {displayVersionNumbers(false)}
          </Grid>
        )}
      </div>
    </div>
  );
};

export default withStyles(styles)(VersionsModal);
