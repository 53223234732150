const SET_IS_MAINTENANCE_MODE_ON = 'xlinkonline/app/SETISMAINTENANCEMODEON';
const SET_MAINTENANCE_MODE_DATES = 'xlinkonline/app/SET_MAINTENANCE_MODE_DATES';
const TOGGLE_MAINTENANCE_MODE_MESSAGE_MODAL = 'xlinkonline/app/TOGGLEMAINTENANCEMODEMESSAGEMODAL';
const SET_MAINTENANCE_MODE_MESSAGE = 'xlinkonline/app/SETMAINTENANCEMODEMESSAGE';
const SET_IS_MAINTENANCE_MODE_TIME = 'xlinkonline/app/SETISMAINTENANCEMODETIME';

export const initialState = {
  isMaintenanceModeMessageOpen: false,
  isMaintenanceModeOn: false,
  isMaintenanceModeTime: false,
  maintenanceModeStartDate: '',
  maintenanceModeEndDate: '',
  maintenanceModeTitle: '',
  maintenanceModeBody: '',
};

export const actions = {
  setIsMaintenanceModeOn: isMaintenanceModeOn => ({
    type: SET_IS_MAINTENANCE_MODE_ON,
    isMaintenanceModeOn,
  }),
  setMaintenanceModeDates: (maintenanceModeStartDate, maintenanceModeEndDate) => ({
    type: SET_MAINTENANCE_MODE_DATES,
    maintenanceModeStartDate,
    maintenanceModeEndDate,
  }),
  toggleMaintenanceModeMessageModal: isMaintenanceModeMessageOpen => ({
    type: TOGGLE_MAINTENANCE_MODE_MESSAGE_MODAL,
    isMaintenanceModeMessageOpen,
  }),
  setMaintenanceModeMessage: (maintenanceModeTitle, maintenanceModeBody) => ({
    type: SET_MAINTENANCE_MODE_MESSAGE,
    maintenanceModeTitle,
    maintenanceModeBody,
  }),
  setIsMaintenanceModeTime: isMaintenanceModeTime => ({
    type: SET_IS_MAINTENANCE_MODE_TIME,
    isMaintenanceModeTime,
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_IS_MAINTENANCE_MODE_ON:
      return {
        ...state,
        isMaintenanceModeOn: action.isMaintenanceModeOn,
      };
    case SET_MAINTENANCE_MODE_DATES:
      return {
        ...state,
        maintenanceModeStartDate: action.maintenanceModeStartDate,
        maintenanceModeEndDate: action.maintenanceModeEndDate,
      };
    case TOGGLE_MAINTENANCE_MODE_MESSAGE_MODAL:
      return {
        ...state,
        isMaintenanceModeMessageOpen: action.isMaintenanceModeMessageOpen,
      };
    case SET_MAINTENANCE_MODE_MESSAGE:
      return {
        ...state,
        maintenanceModeTitle: action.maintenanceModeTitle,
        maintenanceModeBody: action.maintenanceModeBody,
      };
    case SET_IS_MAINTENANCE_MODE_TIME:
      return {
        ...state,
        isMaintenanceModeTime: action.isMaintenanceModeTime,
      };
  }
  return state;
}
