// External imports
import React, { useCallback, useEffect, useState } from 'react';
import {
  Paper,
  withStyles,
  Grid,
  FormControl,
  TextField,
  Button,
  Typography,
  CircularProgress,
} from '@material-ui/core';
// Internal imports
import { useSetState } from '~/app/Utility/customHooks';
import ErrorHelpers from '~/app/errorHelpers.js';
import VerifiedRequestDocs from '~/app/Components/RemoteRequestDocs/VerifiedRequestDocs.jsx';
import AuthAPI from '~/app/api/authAPI.js';
import { isPartiallyEmpty } from '~/app/Utility/general';
import { REMOTE_DOCUMENT_REQUESTEE_TYPE, REMOTE_DOCUMENT_REQUESTEE_NAME } from '~/app/constants.js';
import { buildExpireInDate } from '~/app/Pages/Returns/components/Document/ArchivedDocuments/archivedDocumentsHelpers.js';
import { statusOK } from '~/app/webHelpers';
import { formHasErrors } from '~/app/Components/RemoteSign/helpers/remoteSignAuthHelpers.js';
// Redux imports
import { useDispatch, useSelector } from 'react-redux';
import { actions as appActions } from '~/app/redux/modules/app.js';
// Style imports
import { styles } from '~/app/Components/RemoteRequestDocs/css/remoteRequestDocs.js';
import '~/app/Components/RemoteRequestDocs/css/remoteRequestDocs.css';

/**
 * Handles verifying the user, and letting them upload files to our db. The Preparer should then be able to view the results in Archive Documents
 * Three UI Steps: 0 - Verify Info, 1 - Upload Doc, 2 - Results page
 *
 * @component RemoteRequestDocs
 * @category ArchivedDocuments
 **/
const RemoteRequestDocs = props => {
  const { classes } = props;
  const dispatch = useDispatch();
  const { isMaintenanceModeTime } = useSelector(state => ({
    isMaintenanceModeTime: state.maintenance.isMaintenanceModeTime,
  }));
  const [state, setState] = useSetState({
    form: {
      name: '',
      lastFourPrimary: '',
      dob: '',
      zipCode: '',
    },
    authorizationCode: '',
    requesteeType: REMOTE_DOCUMENT_REQUESTEE_TYPE.TAXPAYER, // by default TP / 1 is sent
    remoteDocumentID: 0,
    currentStep: 0,
    initialVerify: true,
    isVerifying: false,
    isSubmitDisabled: true,
    isBusiness: false,
    inputErrors: {},
    remainingDays: 0,
    nameField: 'Last Name',
    namePlaceholder: 'Smith',
    labels: ['Please verify using your Taxpayer information', 'Attach Requested File', 'All Done'],
  });
  const [debouncedState, setDebouncedState] = useState({
    form: {
      name: '',
      lastFourPrimary: '',
      dob: '',
      zipCode: '',
    },
  });

  useEffect(() => {
    const paramCode = props.match.params?.code;
    const requesteeType = parseInt(paramCode.substring(0, 1));
    const isBusiness = requesteeType === REMOTE_DOCUMENT_REQUESTEE_TYPE.BUSINESS;
    const nameField = isBusiness ? 'Business Name' : 'Last Name';
    const namePlaceholder = isBusiness ? 'Smith & Co' : 'Smith';
    const requesteeName =
      requesteeType > 0
        ? requesteeType === 1
          ? 'TaxPayer'
          : requesteeType === 2
          ? 'Spouse'
          : REMOTE_DOCUMENT_REQUESTEE_NAME[requesteeType - 1]
        : 'Taxpayer';

    setState({
      authorizationCode: paramCode.substring(1),
      requesteeType,
      nameField,
      namePlaceholder,
      isBusiness,
      labels: [
        `Please verify using your ${requesteeName} information`,
        state.labels[1],
        state.labels[2],
      ],
    });
  }, []);

  // Handles setting timeout on form change
  const debounce = useCallback(
    _.debounce(_formVal => {
      setDebouncedState(_formVal);
      // send the server request here
    }, 3000),
    [],
  );

  // When debounce state changes after user stops typing for a set period of time, then verify return.
  useEffect(() => {
    if (state.initialVerify) {
      setState({ initialVerify: false });
    } else {
      setState({
        isSubmitDisabled: formHasErrors(state.form, handleSetErrors, !state.isBusiness),
        isVerifying: false,
      });
    }
  }, [debouncedState]);

  const handleSetErrors = errors => {
    setState({ inputErrors: errors }); // set any errors to the local state
  };

  /** Handles input change */
  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;

    // inputProps does not work with date pickers
    if (name === 'dob' && value.length > 10) return;

    setState({
      form: {
        ...state.form,
        [name]: value.toUpperCase(),
      },
      isSubmitDisabled: true, // require verify if form was completed then modified.
      isVerifying: true,
    });
    debounce({
      form: {
        ...state.form,
        [name]: value.toUpperCase(),
      },
    });

    // If an input does have an error, we want to reset the error and give the user a chance to fix it
    const tempErrors = state.inputErrors;
    if (tempErrors[name]) {
      delete tempErrors[name];
      setState({
        inputErrors: tempErrors,
      });
    }
  };

  /** Handles submitting user info to be verified */
  const handleSubmitVerify = async () => {
    try {
      const res = await AuthAPI.verifyRemoteDocRequestAuth(
        state.form.name,
        state.form.lastFourPrimary,
        state.form.dob,
        state.requesteeType,
        state.authorizationCode,
        state.form.zipCode,
      );
      const snackbarObj = {
        msg: '',
        alert: '',
      };

      // documentStatus -> 0 - Expired, 1 - Pending, 2 - Completed
      if (statusOK(res) && !isPartiallyEmpty(res.data)) {
        const data = res.data;
        // the DB handles determining whether or not the email is expired and includes in the response
        if (data.isExpired) {
          snackbarObj.msg = 'Email has Expired.';
          snackbarObj.alert = 'error';
          setState({ isExpired: data.isExpired, currentStep: 2 });
          // If document status is Pending or Completed
        } else if ([1, 2].includes(data?.documentStatus)) {
          snackbarObj.msg = `Information Verified${
            data?.remoteDocumentID === 2
              ? ' - Information Verified - This email has already been used, and documents uploaded.'
              : ''
          }`;
          snackbarObj.alert = 'success';

          const getRemainingDays = buildExpireInDate(data.updateDate);

          setState({
            currentStep: 1,
            remoteDocumentID: data.remoteDocumentID,
            remainingDays: getRemainingDays,
          });
        }
      } else {
        snackbarObj.msg =
          'The Information provided does not match our records. Please try again or contact your tax preparer.';
        snackbarObj.alert = 'error';
      }
      dispatch(
        appActions.showSnackbarMessage(snackbarObj.msg, snackbarObj.alert, 3500, {
          vertical: 'top',
          horizontal: 'center',
        }),
      );
    } catch (err) {
      dispatch(
        appActions.showSnackbarMessage('Error Verifying Information', 'error', 3500, {
          vertical: 'top',
          horizontal: 'center',
        }),
      );
      ErrorHelpers.handleError('Error Verifying information: ', err);
    }
  };

  /**
   * Handles sending a calc server request to save attach the document to the return.
   *
   * @param {object} fileData the data from the selected file
   * @param {string} description the description of the document that is previewed on the Archived Documents Table
   */
  const handleSaveFile = async (fileData, description) => {
    let res;
    try {
      // Adding a new document.
      if (fileData.file !== undefined || fileData.file != null) {
        fileData.fileType = `data:${fileData.fileType};base64`;
        res = await AuthAPI.postRemoteDocumentData(
          state.remoteDocumentID,
          fileData,
          description,
          state.isBusiness,
        );
      }

      if (statusOK(res, false)) {
        dispatch(
          appActions.showSnackbarMessage('File Uploaded', 'success', 3500, {
            vertical: 'top',
            horizontal: 'center',
          }),
        );

        setState({ currentStep: 2 });
      }
    } catch (err) {
      dispatch(
        appActions.showSnackbarMessage('Error Uploading File', 'error', 3500, {
          vertical: 'top',
          horizontal: 'center',
        }),
      );
      ErrorHelpers.handleError('Error Uploading File: ', err);
    }
  };

  /** Handles displaying the last step that displays the result message */
  const renderResultsMessage = () => {
    return !state.isExpired ? (
      <>
        <Grid item xs={12}>
          <Typography variant="body1" align="center">
            Thank you for submitting your requested file.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" align="center">
            The requested file process has been completed. This Email Authentication expires in{' '}
            {state.remainingDays} Day(s)
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" align="center">
            Please close this window.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" align="center">
            Otherwise, click below to add another document.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Button
              id="btnAddAnotherDoc"
              onClick={() => setState({ currentStep: 1 })}
              variant="contained"
              type="submit"
              color="primary"
            >
              Add Document
            </Button>
          </FormControl>
        </Grid>
      </>
    ) : (
      <>
        <Grid item xs={12}>
          <Typography variant="body1" align="center">
            This Email Authentication request has Expired.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" align="center">
            Please contact your Tax Preparer to have them re-send the email.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" align="center">
            Please close this window.
          </Typography>
        </Grid>
      </>
    );
  };

  return (
    <div className="remoteAuthenticationPage">
      <Paper className="request-docs-container">
        <div className="remote-sign-log-in">
          {!state.isExpired ? state.labels[state.currentStep] : 'Expired'}
        </div>
        <Grid container spacing={32}>
          <Grid item xs={12}>
            <hr className="remote-sign-horizontal-line" />
          </Grid>
          {state.currentStep === 0 ? (
            <>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    id="txtNameSignLogin"
                    name="name"
                    value={state.form.name}
                    label={state.nameField}
                    placeholder={state.namePlaceholder}
                    onChange={handleChange}
                    inputProps={{ maxLength: 100 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    variant="outlined"
                    autoComplete="off"
                    helperText={state.inputErrors?.name}
                    error={state.inputErrors?.name !== undefined}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl>
                  <TextField
                    id="nflastFourPrimarySignLogin"
                    name="lastFourPrimary"
                    value={state.form.lastFourPrimary}
                    label={`Last 4 Digits of ${state.isBusiness ? 'EIN' : 'SSN'}`}
                    onChange={handleChange}
                    variant="outlined"
                    autoComplete="off"
                    placeholder="####"
                    inputProps={{ maxLength: 4 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.numberFormat}
                    helperText={state.inputErrors?.lastFourPrimary}
                    error={state.inputErrors?.lastFourPrimary !== undefined}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl>
                  <TextField
                    id="nfZipCodeSignLogin"
                    name="zipCode"
                    value={state.form.zipCode}
                    label="Zip Code"
                    placeholder="#####"
                    variant="outlined"
                    autoComplete="off"
                    inputProps={{ maxLength: 14 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={handleChange}
                    helperText={state.inputErrors?.zipCode}
                    error={state.inputErrors?.zipCode !== undefined}
                  />
                </FormControl>
              </Grid>
              {!state.isBusiness && (
                <Grid item xs={12}>
                  <FormControl>
                    <TextField
                      id="nfDOBSignLogin"
                      name="dob"
                      value={state.form.dob || ''}
                      label="DOB"
                      onChange={handleChange}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      variant="outlined"
                      autoComplete="off"
                      type="date"
                      helperText={state.inputErrors?.dob}
                      error={state.inputErrors?.dob !== undefined}
                    />
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Button
                    id="btnNextSignLogin"
                    onClick={handleSubmitVerify}
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={state.isSubmitDisabled || isMaintenanceModeTime}
                  >
                    {state.isVerifying ? (
                      <>
                        <CircularProgress
                          size="1rem"
                          color="inherit"
                          className={classes.smallSpinner}
                        />
                        Verifying...
                      </>
                    ) : (
                      'Verify'
                    )}
                  </Button>
                </FormControl>
              </Grid>
            </>
          ) : state.currentStep === 1 ? (
            <VerifiedRequestDocs handleSaveFile={handleSaveFile} />
          ) : state.currentStep === 2 ? (
            renderResultsMessage()
          ) : null}
        </Grid>
      </Paper>
    </div>
  );
};

export default withStyles(styles)(RemoteRequestDocs);
