import { PREPARER_LIST_FILTERS, DASHBOARD_DATA } from '~/app/constants.js';
import { LOGOUT } from '~/app/redux/commonActions.js';

const SELECT_FILTER = 'xlinkonline/preparerList/SELECT';
const PAGE_CHANGE = 'xlinkonline/preparerList/PAGECHANGE';
const UPDATE_LIST_FILTER = 'xlinkonline/preparerList/UPDATELISTFILTER';
const RESET_PREPARER_FILTER = 'xlinkonline/preparerList/RESETPREPARERFILTER';
const UPDATE_PREPARER_LIST_SORT_ORDER = 'xlinkonline/preparerList/UPDATEPREPARERLISTSORTORDER';
const UPDATE_ACTIVE_COL_IDX = 'xlinkonline/preparerList/UPDATEACTIVECOLIDX';
const REDIRECT_FROM_OVERVIEW = 'xlinkonline/overview/REDIRECTFROMOVERVIEW';
const RESET_COLUMNS_TO_DEFAULT = 'xlinkonline/preparerList/RESETCOLUMNSTODEFAULT';
const RESET_COLUMNS = 'xlinkonline/preparerList/RESETCOLUMNS';
const CLEAR_PREPARER_LIST = 'xlinkonline/preparerList/CLEARPREPARERSLIST';

export const REQUESTED_PREPARER_LIST = 'xlinkonline/preparerList/REQUESTEDPREPARERLIST';
export const REQUESTED_PREPARER_LIST_FAILED = 'xlinkonline/preparerList/REQUESTEDPREPARERFAILED';
export const REQUESTED_PREPARER_LIST_SUCCEEDED = 'xlinkonlinepreparer/REQUESTEDPREPARERSUCCEEDED';
export const FETCHED_PREPARER_LIST = 'xlinkonline/preparerList/FETCHEDPREPARERLIST';

export const initialState = {
  filterValues: {
    first: '', // 1st filter text box value
    second: '', // 2nd filter text box value (for filtering by date range)
  },
  sortOrder: {
    column: 0, // default sort is by last name
    order: 'asc', // default sort is in ascending order
  },

  // all possible table columns. name is for column title, col is for query params, asc and desc are for sort dropdown options,
  // details is for customize column modal
  allColumns: [
    {
      name: 'Preparer Name',
      col: 'name',
      asc: 'Sort By A-Z',
      desc: 'Sort By Z-A',
      details: 'Preparer Name',
    },
    {
      name: '1040',
      col: 'returns',
      asc: 'Least to Most',
      desc: 'Most to Least',
      details: '1040 Count',
    },
    {
      name: 'Business',
      col: 'returnsCorp',
      asc: 'Least to Most',
      desc: 'Most to Least',
      details: 'Business Count',
    },
    {
      name: '1040 Rejects/Refusals',
      col: 'rejects',
      asc: 'Least to Most',
      desc: 'Most to Least',
      details: 'Rejects and Refusals Count',
    },
    {
      name: 'Business Rejects/Refusals',
      col: 'rejectsCorp',
      asc: 'Least to Most',
      desc: 'Most to Least',
      details: 'Business Rejects and Refusals Count',
    },
    { name: 'Action', col: 'action', asc: 'Sort By A-Z', desc: 'Sort By Z-A' }, // always display this column
  ],

  // active columns, array index is column position in table and value is an index into allColumns
  active1040ColIndexes: [0, 1, 3, 5], // default 1040 column titles
  activeBusinessColIndexes: [0, 1, 2, 3, 4, 5], // default business column titles

  // all filters, name is displayed in the filter dropdown, filter is for query params
  // the order of these must match the order of PREPARER_LIST_FILTERS in constants.js
  allFilters: [
    { name: 'All Preparers', filter: 'all' },
    { name: 'Name', filter: 'name' },
  ],

  loading: false,
  error: false,

  // current filter criteria, index into allFilters
  activeFilter: PREPARER_LIST_FILTERS.ALL,

  perPage: DASHBOARD_DATA.DASHBOARDLISTSIZE,
  currentPage: 1,
  preparers: [], // a single page of preparers matching the search criteria and pagination requirements
  totalPreparers: -1, // number of preparers meeting the search criteria (used by pagination component)
  totalUnfilteredPreparers: -1,
};

export const actions = {
  onSelectPreparerListFilter: choice => ({
    type: SELECT_FILTER,
    choice,
  }),
  onPageChange: page => ({
    type: PAGE_CHANGE,
    page,
  }),
  updatePreparerListFilterValues: (first, second, currentPage) => ({
    type: UPDATE_LIST_FILTER,
    first,
    second,
    currentPage,
  }),
  resetPreparerFilter: () => ({
    type: RESET_PREPARER_FILTER,
  }),
  updatePreparerListSortOrder: (col, order) => ({
    type: UPDATE_PREPARER_LIST_SORT_ORDER,
    col,
    order,
  }),
  updateActiveColIdx: (newVal, colIdx) => ({
    type: UPDATE_ACTIVE_COL_IDX,
    newVal,
    colIdx,
  }),
  redirectFromOverview: () => ({
    type: REDIRECT_FROM_OVERVIEW,
  }),
  resetColumnsToDefault: () => ({
    type: RESET_COLUMNS_TO_DEFAULT,
  }),
  resetColumns: cols => ({
    type: RESET_COLUMNS,
    cols,
  }),
  clearPreparersList: () => ({
    type: CLEAR_PREPARER_LIST,
  }),
  requestPreparerList: () => ({
    type: REQUESTED_PREPARER_LIST,
  }),
  requestPreparerListSuccess: preparerListData => ({
    type: REQUESTED_PREPARER_LIST_SUCCEEDED,
    preparerListData,
  }),
  requestPreparerListError: () => ({
    type: REQUESTED_PREPARER_LIST_FAILED,
  }),
  fetchPreparerList: (filterParams, ddh) => ({
    type: FETCHED_PREPARER_LIST,
    filterParams,
    ddh,
  }),
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SELECT_FILTER:
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          first: '',
          second: '',
        },
        activeFilter: action.choice,
      };
    case UPDATE_LIST_FILTER:
      return {
        ...state,
        filterValues: {
          ...state.filterValues,
          first: action.first,
          second: action.second,
        },
        currentPage: action.currentPage,
      };
    case PAGE_CHANGE:
      return {
        ...state,
        currentPage: action.page,
      };
    case RESET_PREPARER_FILTER:
      return {
        ...state,
        activeFilter: 0,
      };
    case UPDATE_PREPARER_LIST_SORT_ORDER:
      return {
        ...state,
        sortOrder: {
          ...state.sortOrder,
          column: action.col,
          order: action.order,
        },
      };
    case UPDATE_ACTIVE_COL_IDX:
      // eslint-disable-next-line no-case-declarations
      const cols = state.activeColIndexes.map((item, idx) =>
        idx === action.colIdx ? action.newVal : item,
      );
      return {
        ...state,
        activeColIndexes: cols,
      };
    case REDIRECT_FROM_OVERVIEW:
      return {
        ...state,
        activeFilter: PREPARER_LIST_FILTERS.ALL,
      };
    case RESET_COLUMNS_TO_DEFAULT:
      return {
        ...state,
        activeColIndexes: [0, 1, 2, 3],
      };
    case RESET_COLUMNS:
      return {
        ...state,
        activeColIndexes: action.cols,
      };
    case CLEAR_PREPARER_LIST:
      return {
        ...state,
        preparers: [],
        currentPage: 1,
        totalPreparers: -1,
        totalUnfilteredPreparers: -1,
      };
    case LOGOUT:
      return {
        ...state,
        preparers: [],
        currentPage: 1,
        totalPreparers: -1,
        totalUnfilteredPreparers: -1,
      };
    case REQUESTED_PREPARER_LIST:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case REQUESTED_PREPARER_LIST_SUCCEEDED:
      return {
        ...state,
        preparers: action.preparerListData.list,
        totalPreparers: action.preparerListData.total,
        totalUnfilteredPreparers: action.preparerListData.unfilteredTotal,
        loading: false,
        error: false,
      };
    case REQUESTED_PREPARER_LIST_FAILED:
      return {
        ...state,
        preparers: [],
        totalPreparers: -1,
        totalUnfilteredPreparers: -1,
        loading: false,
        error: true,
      };
  }
  return state;
}
