// External imports
import React, { useEffect } from 'react';
import {
  Paper,
  Typography,
  Grid,
  TextField,
  Button,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
// Internal imports
import ButtonSpinner from '#/Common/ButtonSpinner.jsx';
import XlinkAPI from '~/app/api/xlinkAPI';
import ErrorHelpers from '~/app/errorHelpers.js';
import { useSetState } from '~/app/Utility/customHooks';
// Styling imports
import { styles } from '~/app/Pages/Setup/ManageLicencing/css/manageLicencing.js';
import '~/app/Pages/Setup/ManageLicencing/css/manageLicencing.css';

const MultiOfficeManagement = props => {
  const [state, setState] = useSetState({
    licensesAbleToGenerate: 0,
    isSpinning: false,
  });

  useEffect(() => {
    setState({ licensesAbleToGenerate: props.multiOffice.licenses_able_to_generate });
  }, []);

  const onChange = e => {
    const value = e.target.value;

    // ran into bad UX relying on the TextField input props for handling number fields - not being able to pass in an empty string makes updating the value difficult
    if (value === '-' || value?.toLowerCase() === 'e' || (isNaN(value) && value !== '')) return;

    setState({ [e.target.name]: value });
  };

  const upsertlicensesAbleToGenerate = async () => {
    setState({ isSpinning: true });
    // The amount of licenses available to generate shouldn't be less than the
    // amount applied to offices under the multioffice
    if (state.licensesAbleToGenerate < props.multiOffice.licenses_consumed) {
      ErrorHelpers.handleError(
        `Unable to save number of licenses available to generate`,
        ErrorHelpers.createSimpleError(
          `The number of licenses available to generate can't be less than the number of 
          licenses applied to offices. Unassign licenses from offices under the 
          Multi-Office if you wish to decrease the number of generatable licenses`,
        ),
      );

      setState({ isSpinning: false });
      return;
    }

    try {
      const res = await XlinkAPI.applyMOGeneratableLicenses(
        props.multiOffice.user_id,
        state.licensesAbleToGenerate,
        props.isBusinessManagement,
        props.selectedSeason,
      );
      if (res) {
        // Reload the other page.
        props.reloadLicensing();
      }
    } catch (error) {
      ErrorHelpers.handleError('Unable to save number of licenses available to generate', error);
    }

    setState({ isSpinning: false });
    props.closeModal();
  };

  const shouldDisableInput =
    state.licensesAbleToGenerate === '' ||
    parseInt(state.licensesAbleToGenerate) < 0 ||
    parseInt(state.licensesAbleToGenerate) > 9999;

  return (
    <Paper elevation={5} style={styles.multiOfficeManagementModal}>
      <div className="modal-header">
        Managed Multi-Office Licenses
        <Typography color="inherit" onClick={props.closeModal}>
          X
        </Typography>
      </div>
      <Grid container style={styles.moContainer}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <TextField
              id="inputLicenseNumberGen"
              variant="outlined"
              label="Licenses Able to Generate"
              name="licensesAbleToGenerate"
              value={state.licensesAbleToGenerate}
              onChange={onChange}
              error={shouldDisableInput}
              helperText={shouldDisableInput ? 'Must be a number between 0-9999' : ''}
            />
            <FormHelperText id="licenseNumberGenHelpText">
              Maximum number of licenses the multi-office can generate
            </FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container style={styles.footerDividerMO} alignItems="flex-end" justify="flex-end">
        <Button onClick={props.closeModal} style={styles.buttonSpacing}>
          Cancel
        </Button>
        <ButtonSpinner
          buttonText={state.isSpinning ? 'Saving' : 'Save'}
          isLoading={state.isSpinning}
          onClick={upsertlicensesAbleToGenerate}
          disabled={
            state.isSpinning ||
            state.licensesAbleToGenerate === props.multiOffice.licenses_able_to_generate ||
            shouldDisableInput
          }
          spinnerColor="#ffffff"
          color="primary"
          variant="contained"
        />
      </Grid>
    </Paper>
  );
};

export default MultiOfficeManagement;
