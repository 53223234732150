export const styles = () => ({
  gridBlock: {
    marginTop: '11.25rem',
  },
  input: {
    width: 'calc(100%)',
    height: '2.5rem',
    paddingLeft: '15px',
  },
  checkbox: {
    width: 17,
    height: 16,
    marginRight: '0.3vw',
  },
  checkboxOutlineBlankIcon: {
    fontSize: 24,
    color: '#8FC3FF',
  },
  checkboxIcon: {
    fontSize: 24,
  },
  checkboxInputProps: {
    height: '1.25rem',
    width: '1.25rem',
  },
  button: {
    padding: '0 1.875rem',
  },
  maintenanceContainer: { padding: '1rem', width: '30rem' },
});
