// External imports
import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
// Internal imports
import { publicRoutes, privateRoutes } from '~/app/Router/Routes.js';
import PrivateRoute from '~/app/Components/Auth/PrivateRoute/PrivateRoute.jsx';
import GlobalSnackbar from '#/Common/GlobalSnackbar.jsx';
import GenericErrorModal from '#/Common/GenericErrorModal.jsx';
import CLOIdleTimer from '#/Common/CLOIdleTimer.jsx';
import HeaderBar from '~/app/Components/HeaderBar/HeaderBar.jsx';
import Sidebar from '~/app/Components/Common/Sidebar/Sidebar.jsx';
import AppVersion from './AppVersion';
import { EXCLUDE_FLEX } from '~/app/constants.js';
import Footer from '~/app/Components/Footer/Footer.jsx';
import MaintenanceMode from '#/Common/MaintenanceRoutineMsg/MaintenanceMode.jsx';
import $ from 'jquery';
// Styling imports
import { MuiThemeProvider } from '@material-ui/core/styles';
import appTheme from '~/themes/GenericTheme.jsx';
import '~/app/styles/App.css'; // defines paths to all external CSS

/**
 * This is the main entry component which acts as a container for all other components.
 *
 * @component
 */
const App = () => {
  const location = useLocation();

  useEffect(() => {
    handleTitle();
    document.addEventListener('keydown', stopKeyZoom);

    return () => {
      // Cleanup event listener.
      document.removeEventListener('keydown', stopKeyZoom);
    };
  }, []);

  /**
   * Change browser tab title to My Online Tax Office or Crosslink Online
   * base on cobrand URL myonlinetaxoffice.com.
   */
  const handleTitle = () => {
    // disable zoom
    document.firstElementChild.style.zoom = 'reset';

    let title = 'Crosslink Online';
    if (window.location.href.includes('myonlinetaxoffice')) {
      title = 'My Online Tax Office';
    }

    $(document).prop('title', title);
  };

  /**
   * Handle disabling zoom with control keys.
   */
  const stopKeyZoom = event => {
    if (event.ctrlKey && [48, 61, 96, 107, 109, 187, 189].indexOf(event.keyCode) > -1) {
      event.preventDefault();
    }
  };

  return (
    <div className="App">
      <MuiThemeProvider theme={appTheme}>
        <HeaderBar />
        <CLOIdleTimer />
        <GlobalSnackbar />
        <AppVersion />
        <GenericErrorModal />
        <MaintenanceMode />
        <div className={`${!EXCLUDE_FLEX.some(v => location.pathname === v) && 'app-flex'}`}>
          <Sidebar />
          <Switch>
            {publicRoutes.map(({ path, exact, Component }, key) => (
              <Route
                path={path}
                key={key}
                exact={exact}
                render={props => <Component {...props} />}
              />
            ))}
            {privateRoutes.map(({ path, exact, Component, navigation }, key) => (
              <PrivateRoute
                path={path}
                key={key}
                exact={exact}
                render={props => <Component {...props} navigation={navigation} />}
              />
            ))}
          </Switch>
          <Footer />
        </div>
      </MuiThemeProvider>
    </div>
  );
};

export default React.memo(App);
