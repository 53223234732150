import React, { useCallback } from 'react';
// Internal imports
import { SNACKBAR_SEVERITY } from '~/app/constants.js';
// External imports
import { Snackbar, IconButton, withStyles, SnackbarContent } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
// Redux imports
import { useSelector, useDispatch } from 'react-redux';
import { actions as appActions } from '~/app/redux/modules/app';
// Styling imports
import { styles } from '#/Common/css/snackbar.js';

/**
 * Handles displaying snackbar alert messages
 *
 * @component GlobalSnackbar
 * @category Common
 **/
const GlobalSnackbar = props => {
  const { classes } = props;
  const dispatch = useDispatch();
  const {
    show,
    message,
    severity,
    anchorOrigin,
    autoHideDuration,
    isMaintenanceModeTime,
  } = useSelector(state => ({
    show: state.app.snackbarMessage.show,
    message: state.app.snackbarMessage.message,
    severity: state.app.snackbarMessage.severity,
    anchorOrigin: state.app.snackbarMessage.anchorOrigin,
    autoHideDuration: state.app.snackbarMessage.autoHideDuration,
    isMaintenanceModeTime: state.maintenance.isMaintenanceModeTime,
  }));

  const hideSnackbarMessage = useCallback(() => dispatch(appActions.hideSnackbarMessage()));

  /**
   * Handles determining which stylign to apply based on the severity from the message
   *
   * @param {string} severity is used to determine the color of the ui
   * @returns {string} css style name
   */
  const snackbarClassNameBySeverity = severity => {
    let snackbarMessageSeverityClass = 'snackbar-prompt'; // Default value will be snackbar-prompt
    switch (severity) {
      case SNACKBAR_SEVERITY.ERROR:
        snackbarMessageSeverityClass = 'snackbar-error';
        break;
      case SNACKBAR_SEVERITY.WARNING:
        snackbarMessageSeverityClass = 'snackbar-warning';
        break;
      case SNACKBAR_SEVERITY.INFO:
        snackbarMessageSeverityClass = 'snackbar-prompt';
        break;
      case SNACKBAR_SEVERITY.SUCCESS:
        snackbarMessageSeverityClass = 'snackbar-info';
        break;
    }
    return snackbarMessageSeverityClass;
  };

  // login page has its own UI for maintenance mode
  const shouldShowSnackbar = isMaintenanceModeTime ? location.pathname !== '/' : show;
  const shouldShowSnackBarCancel = !isMaintenanceModeTime && location.pathname !== '/';

  return (
    <Snackbar
      open={shouldShowSnackbar}
      onClose={hideSnackbarMessage}
      className={classes.snackbarContainer}
      autoHideDuration={autoHideDuration === 0 ? null : autoHideDuration}
      anchorOrigin={anchorOrigin}
    >
      <SnackbarContent
        key="global-snackbar-content"
        classes={{ root: classes.root }}
        className={snackbarClassNameBySeverity(severity)}
        aria-describedby="snackbar-message-id"
        message={<span id="snackbar-message-id">{message}</span>}
        action={[
          shouldShowSnackBarCancel && (
            <span key="global-content-snackbar-action">
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={hideSnackbarMessage}
              >
                <CloseIcon />
              </IconButton>
            </span>
          ),
        ]}
      />
    </Snackbar>
  );
};

export default withStyles(styles)(GlobalSnackbar);
